import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { BranchAvatarModule } from '@portal-core/branches/components/branch-avatar/branch-avatar.module';
import { BuildProgressBarModule } from '@portal-core/builds/components/build-progress-bar/build-progress-bar.module';
import { CommitAvatarModule } from '@portal-core/commits/components/commit-avatar/commit-avatar.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { SiteAvatarModule } from '@portal-core/sites/components/site-avatar/site-avatar.module';
import { SiteAsyncModule } from '@portal-core/sites/pipes/site-async/site-async.module';
import { TargetAvatarModule } from '@portal-core/targets/components/target-avatar/target-avatar.module';
import { FlareAvatarModule } from '@portal-core/users/components/flare-avatar/flare-avatar.module';
import { FlareOnlineAvatarModule } from '@portal-core/users/components/flare-online-avatar/flare-online-avatar.module';
import { BuildOverviewComponent } from './build-overview.component';

@NgModule({
  declarations: [BuildOverviewComponent],
  imports: [
    BranchAvatarModule,
    BuildProgressBarModule,
    CommitAvatarModule,
    CommonModule,
    FlareAvatarModule,
    FlareOnlineAvatarModule,
    MatDividerModule,
    MatIconModule,
    ProjectAvatarModule,
    SiteAvatarModule,
    TargetAvatarModule,
    SiteAsyncModule,
    LicenseUserAvatarModule
  ],
  exports: [BuildOverviewComponent]
})
export class BuildOverviewModule { }
