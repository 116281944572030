<form class="mc-spring-board" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-form-body">
    <p class="mc-form-text">
      Deleting <b>{{ licenseUser?.User?.FullName}}</b> will permanently remove them from this license. All access will be denied to the user on this license.
    </p>
    <p class="mc-form-text">
      Instead of deleting the user you might consider deactivating them. All access will be denied to the user on this license. This action will free up a seat without removing data. A deactivated user can be reactivated later if necessary.
    </p>
    <p class="mc-form-title">
      Type "delete" in the text box below to delete the user.
    </p>

    <mat-form-field class="mc-form-field" color="secondary">
      <input type="text" matInput name="confirm" #confirmInput placeholder="Confirm Delete">
    </mat-form-field>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button *ngIf="showCancelButton" type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="button" mat-raised-button color="warn" [disabled]="confirmInput.value !== 'delete' || (savingState.loading$ | async)" (click)="onDeleteClicked()">Delete</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="savingState.loading$ | async">Deactivate</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>