import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { IncludedInModule } from '@portal-core/general/pipes/included-in/included-in.module';
import { SiteAvatarModule } from '@portal-core/sites/components/site-avatar/site-avatar.module';
import { ListModule } from '@portal-core/ui/list/list.module';
import { TeamSitesListComponent } from './team-sites-list.component';


@NgModule({
  declarations: [
    TeamSitesListComponent
  ],
  imports: [
    CommonModule,
    ListModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    SiteAvatarModule,
    MatSnackBarModule,
    IncludedInModule
  ],
  exports: [
    TeamSitesListComponent
  ]
})
export class TeamSitesListModule { }
