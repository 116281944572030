<div class="mc-project-files-tree-container" #filesTreeContainer>
  <!--Data loader -->
  <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>
  <!--Data error -->
  <div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane" mcStopPropagation="click">
    <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async" (retry)="onRetryLoadNodes()">
    </mc-errors-expansion-panel>
  </div>

  <div *ngIf="!(loadingState.loading$ | async) && emptyTree" class="mc-project-files-tree-empty">
    {{includeFiles ? 'No files found.' : 'No folders found.'}}
  </div>

  <cdk-virtual-scroll-viewport itemSize="48" *ngIf="(loadingState.loading$ | async) || !emptyTree" class="mat-tree mc-tree mc-flat-tree mc-project-file-tree-virtual-scroll-container mc-list-selectable" [ngClass]="treeClasses$ | async" #viewport>
    <div *cdkVirtualFor="let node of dataSource" (contextmenu)="onContextMenu($event, node)">
      <mc-project-files-tree-folder *ngIf="node.expandable" [node]="node" [branchName]="branchName" [showIcons]="showIcons" [pathFilters]="value" [projectFilesTreeType]="projectFilesTreeType" [ignoreInitialFilters]="ignoreInitialFilters" [isFolderExpanded]="treeControl.isExpanded(node)" [disableSelectionTree]="disableSelectionTree" [disableFolderCheckboxes]="disableFolderCheckboxes" [checked]="node.selected === TriSelectedState.Selected" (pathFiltersChanged)="onPathFiltersChanged($event)" (selectedDirectoryPathChanged)="selectedDirectoryPathChanged($event)" (nodeToggled)="nodeToggled($event)">
        <ng-template *ngIf="treeItemFolderDirective" [mcProjectFilesTreeItem]="treeItemFolderDirective.templateName" let-item>
          <ng-container [ngTemplateOutlet]="treeItemFolderDirective.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </ng-template>
      </mc-project-files-tree-folder>
      <mc-project-files-tree-file *ngIf="!node.expandable" [node]="node" [filePath]="filePath" [showIcons]="showIcons" [pathFilters]="value" [projectFilesTreeType]="projectFilesTreeType" [branchName]="branchName" [disableSelectionTree]="disableSelectionTree" [disableFolderCheckboxes]="disableFolderCheckboxes" [checked]="node.selected === TriSelectedState.Selected" (pathFiltersChanged)="onPathFiltersChanged($event)" (fileSelected)="onFileSelected($event)">
        <ng-template *ngIf="treeItemFileDirective" [mcProjectFilesTreeItem]="treeItemFileDirective.templateName" let-item>
          <ng-container [ngTemplateOutlet]="treeItemFileDirective.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </ng-template>
      </mc-project-files-tree-file>
    </div>
  </cdk-virtual-scroll-viewport>
</div>

<!-- This only exists so that Angular includes the mat-tree styles when this module is built -->
<mat-tree [treeControl]="fakeTreeControl" hidden></mat-tree>
<!-- needs a tree control or throws an error. -->
<div [style.position]="'fixed'" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu"></div>
<!-- menu -->
<mat-menu #contextMenu="matMenu" class="mc-menu">
  <ng-template matMenuContent let-node="node">
    <ng-container [ngTemplateOutlet]="contextMenuItemsDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: node }"></ng-container>
  </ng-template>
</mat-menu>