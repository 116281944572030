<mat-selection-list #matSelectionList [multiple]="false" (selectionChange)="onVariableSetSelected($event)" class="mc-list-text-weight-normal mc-list-text-size-normal mc-list-bg-light mat-accent-vivid">
  <mat-list-option *ngFor="let variableSet of variableSets" [matTooltip]="variableSet.Name" matTooltipClass="mc-tooltip-multiline" [value]="variableSet" [selected]="selectedVariableSet?.Name === variableSet.Name">
    <span class="mc-truncate">
      <mat-checkbox *ngIf="multiple" color="accent-vivid" [disableRipple]="true" [checked]="variableSet.TotalSelected === variableSet.Definitions.length" [indeterminate]="variableSet.TotalSelected !== 0 && variableSet.TotalSelected !== variableSet.Definitions.length" (change)="variableSetChecked($event, variableSet)">
        <span (click)="$event.preventDefault()">
          {{ variableSet.Name }}
        </span>
      </mat-checkbox>
      <ng-container *ngIf="!multiple">
        {{ variableSet.Name }}
      </ng-container>
    </span>
  </mat-list-option>
</mat-selection-list>