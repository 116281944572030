import { Injectable } from '@angular/core';
import { AsyncValidatorFn, FormControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PackagedError } from '@common/errors/types/packaged-error.type';
import { ErrorCode } from '@common/http/enums/error-code.enum';
import { firstOrNull, mergeArraysByKey } from '@common/util/array';
import { normalizePath } from '@common/util/path';
import { MaxAttachmentSizeBytes } from '@portal-core/data/common/constants/max-attachment-size.constant';
import { MaxAvatarImageFileSizeBytes } from '@portal-core/data/common/constants/max-avatar-image-file-size.constant';
import { MadCapImage } from '@portal-core/data/common/models/madcap-image.model';
import { MaxStorageSizeBytes } from '@portal-core/data/constants/max-storage-size.constant';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ImagePickerValue } from '@portal-core/forms/models/image-picker-value.model';
import { Validation } from '@portal-core/forms/models/validation.model';
import { FormValidationConfig } from '@portal-core/forms/util/form-validation-config';
import { FormValidationContext } from '@portal-core/forms/util/form-validation-context';
import { FileSizeService } from '@portal-core/general/services/file-size.service';
import { FileService } from '@portal-core/general/services/file.service';
import { ImageService } from '@portal-core/general/services/image.service';
import { StringService } from '@portal-core/general/services/string.service';
import { MaxFilePathLength } from '@portal-core/project-files/constants/file-path-length.constants';
import { ProjectFileSystemType } from '@portal-core/project-files/enums/project-file-system-type.enum';
import { ProjectFileType } from '@portal-core/project-files/enums/project-file-type.enum';
import { ProjectSkinType } from '@portal-core/project-files/enums/project-skin-type.enum';
import { ProjectStylesheetType } from '@portal-core/project-files/enums/project-stylesheet-type.enum';
import { ProjectFilesService } from '@portal-core/project-files/services/project-files.service';
import { ProjectStylesheetService } from '@portal-core/project-files/services/project-stylesheet.service';
import { MaxSiteLogoImageFileSizeBytes } from '@portal-core/sites/constants/max-site-logo-image-file-size.constant';
import { Base64 } from 'js-base64';
import { mapValues } from 'lodash';
import { Observable, Subscription, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormsService {
  /**
   * Used by mc-form-control-error-message and mc-form-control-error-code to display form validation errors with reactive forms.
   * Remove the Message property when mc-form-control-error-message has been replaced by mc-form-control-error-code
   */
  genericValidations: Validation[] = [
    { Type: 'unknown', ErrorCode: ErrorCode.ValidationUnknownError, Message: 'Validation failed: an unknown error has occurred.' }
  ];

  validationTypes: Dictionary<Validation[]> = {
    'mc_phone': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationPhoneNumberRequired, Message: 'Phone number is required.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationPhoneNumberInvalid, Message: 'Please enter a valid phone number.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationPhoneNumberTooLong, Message: 'Must be 30 characters or less.' },
      { Type: 'minlength', ErrorCode: ErrorCode.ValidationPhoneNumberTooShort, Message: 'Must be 7 characters or more.' }
    ],
    'mc_email': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationEmailRequired, Message: 'Email is required.' },
      { Type: 'email', ErrorCode: ErrorCode.ValidationEmailInvalid, Message: 'Please enter a valid email.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationEmailTooLong, Message: 'Must be 128 characters or less.' },
      { Type: 'exists', ErrorCode: ErrorCode.LicenseUserAlreadyExistsError, Message: 'A user with this email already exists.' }
    ],
    'mc_confirm_email': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationConfirmEmailRequired, Message: 'Confirm your email.' },
      { Type: 'match', ErrorCode: ErrorCode.ValidationConfirmEmailMismatch, Message: 'Emails do not match. Try again.' }
    ],
    'mc_password': [
      { Type: 'incorrect', ErrorCode: ErrorCode.UserIncorrectCredentialsError, Message: 'The password is incorrect.' },
      { Type: 'required', ErrorCode: ErrorCode.ValidationPasswordRequired, Message: 'Password is required.' },
      { Type: 'minlength', ErrorCode: ErrorCode.ValidationPasswordTooShort, Message: 'Password is too short.' },
      { Type: 'mismatch', ErrorCode: ErrorCode.ValidationNewPasswordMustBeDifferent, Message: 'The new password must be different than the old password.' },
      { Type: 'oldPassword', ErrorCode: ErrorCode.UserOldPasswordHistoryError, Message: 'The new password cannot be a previously used password.' }
    ],
    'mc_confirm_password': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationConfirmPasswordRequired, Message: 'Confirm your password.' },
      { Type: 'match', ErrorCode: ErrorCode.ValidationConfirmPasswordMismatch, Message: 'Passwords do not match. Try again.' }
    ],
    'mc_terms': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationTermsOfUseRequired, Message: 'Please accept the terms and conditions.' }
    ],
    'mc_time': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationTimeRequired, Message: 'Time is required.' }
    ],
    'mc_time_zone': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationTimeZoneRequired, Message: 'Time zone is required.' }
    ],
    'mc_license_key_label': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationKeyLicenseLabelRequired, Message: 'Key label is required.' }
    ],
    'mc_license_name': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationLicenseNameRequired, Message: 'License name is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationLicenseNameTooLong, Message: 'Must be 100 characters or less.' },
      { Type: 'whitespace', ErrorCode: ErrorCode.ValidationLicenseNameBlank, Message: 'License name cannot be blank.' }
    ],
    'mc_license_seats': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationSeatsRequired, Message: 'Seats is required.' },
      { Type: 'min', ErrorCode: ErrorCode.ValidationSeatsTooLow, Message: 'Must be more than filled seat count.' },
      { Type: 'max', ErrorCode: ErrorCode.ValidationSeatsNumberTooLarge, Message: 'Must be 10,000 seats or fewer.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationSeatsNumberInvalid, Message: 'Please enter a valid number.' }
    ],
    'mc_license_storage_space': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationStorageRequired, Message: 'Storage is required.' },
      { Type: 'max_storage_size', ErrorCode: ErrorCode.ValidationStorageTooLarge, Message: `Must be smaller than ${this.fileSizeService.format(MaxStorageSizeBytes, 0)}.` },
      { Type: 'min_storage_size', ErrorCode: ErrorCode.ValidationStorageTooLow, Message: 'Must be more than used storage space.' }
    ],
    'mc_license_teams': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationLicenseDefaultTeamRequired, Message: 'Default teams are required when creating users.' },
    ],
    'mc_credit_card_number': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationCreditCardNumberRequired, Message: 'Card number is required.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationCreditCardNumberInvalid, Message: 'Please enter a valid card number.' }
    ],
    'mc_credit_card_expiration_date': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationCreditCardExpirationRequired, Message: 'Expiration date is required.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationCreditCardExpirationInvalid, Message: 'Please enter a valid expiration date.' }
    ],
    'mc_credit_card_code': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationCreditCardCodeRequired, Message: 'Card code is required.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationCreditCardCodeInvalid, Message: 'Please enter a valid card code.' }
    ],
    'mc_user_first_name': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationUserFirstNameRequired, Message: 'First name is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationUserFirstNameTooLong, Message: 'Must be 40 characters or less.' },
      { Type: 'whitespace', ErrorCode: ErrorCode.ValidationUserFirstNameBlank, Message: 'First name cannot be blank.' }
    ],
    'mc_user_last_name': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationUserLastNameRequired, Message: 'Last name is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationUserLastNameTooLong, Message: 'Must be 80 characters or less.' },
      { Type: 'whitespace', ErrorCode: ErrorCode.ValidationUserLastNameBlank, Message: 'Last name cannot be blank.' }
    ],
    'mc_user_initials': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationUserInitialsRequired, Message: 'Initials are required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationUserInitialsTooLong, Message: 'Enter only 2 characters.' }
    ],
    'mc_user_title': [
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationUserTitleTooLong, Message: 'Must be 128 characters or less.' }
    ],
    'mc_user_location': [
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationUserLocationTooLong, Message: 'Must be 128 characters or less.' }
    ],
    'mc_user_department': [
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationUserDepartmentTooLong, Message: 'Must be 128 characters or less.' }
    ],
    'mc_billing_company': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationBillingCompanyRequired, Message: 'Company is required.' }
    ],
    'mc_address': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationAddressRequired, Message: 'Address is required.' }
    ],
    'mc_city': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationCityRequired, Message: 'City is required.' }
    ],
    'mc_postal_code': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationPostalCodeRequired, Message: 'Postal code is required.' }
    ],
    'mc_vanity': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationVanityRequired, Message: 'Vanity is required.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationVanityInvalid, Message: 'Only alphanumeric characters and hyphens allowed.' },
      { Type: 'is_integer', ErrorCode: ErrorCode.VanityUrlIsIntegerError, Message: 'Vanity URL cannot be a number.' },
      { Type: 'is_guid', ErrorCode: ErrorCode.VanityUrlIsGuidError, Message: 'Vanity URL cannot be a unique identifier.' },
      { Type: 'api_pattern', ErrorCode: ErrorCode.VanityUrlPatternError, Message: 'Only alphanumeric characters and hyphens allowed.' },
      { Type: 'exists', ErrorCode: ErrorCode.VanityUrlExistsError, Message: 'Vanity URL already exists for this domain.' },
      { Type: 'is_reserved', ErrorCode: ErrorCode.VanityUrlIsReservedError, Message: 'Vanity URL cannot be a reserved word.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.VanityUrlIsTooLongError, Message: 'Must be 128 characters or less.' }
    ],
    'mc_site_vanity': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationVanityRequired, Message: 'Vanity is required.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationSiteVanityInvalid, Message: 'Only alphanumeric characters, periods, underscores and hyphens allowed.' },
      { Type: 'is_integer', ErrorCode: ErrorCode.VanityUrlIsIntegerError, Message: 'Vanity URL cannot be a number.' },
      { Type: 'is_guid', ErrorCode: ErrorCode.VanityUrlIsGuidError, Message: 'Vanity URL cannot be a unique identifier.' },
      { Type: 'api_pattern', ErrorCode: ErrorCode.VanityUrlPatternError, Message: 'Only alphanumeric characters, periods, underscores and hyphens allowed.' },
      { Type: 'exists', ErrorCode: ErrorCode.VanityUrlExistsError, Message: 'Vanity URL already exists for this domain.' },
      { Type: 'is_reserved', ErrorCode: ErrorCode.VanityUrlIsReservedError, Message: 'Vanity URL cannot be a reserved word.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.VanityUrlIsTooLongError, Message: 'Must be 128 characters or less.' }
    ],
    'mc_host_map': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationLicenseHostMapDomainRequired, Message: 'Domain is required.' },
      { Type: 'exists', ErrorCode: ErrorCode.LicenseHostMapDomainExistsError, Message: 'Domain is already mapped.' },
      { Type: 'not_found', ErrorCode: ErrorCode.LicenseHostMapDomainNotFoundError, Message: 'Domain not found.' },
      { Type: 'invalid_ip', ErrorCode: ErrorCode.LicenseHostMapDomainInvalidIPError, Message: 'Domain mapped to incorrect IP address.' },
      { Type: 'invalid_cname', ErrorCode: ErrorCode.LicenseHostMapDomainInvalidCNameError, Message: 'Domain mapped to incorrect CNAME record.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.LicenseHostMapDomainCNameTooLong, Message: 'Domain must be 64 characters or less.' }
    ],
    'mc_checklist_name': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationChecklistNameRequired, Message: 'Name is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationChecklistNameTooLong, Message: 'Must be 40 characters or less.' }
    ],
    'mc_output_analytics_name': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationOutputAnalyticsNameRequired, Message: 'Name is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationOutputAnalyticsNameTooLong, Message: 'Must be 40 characters or less.' }
    ],
    'mc_output_analytics_description': [
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationOutputAnalyticsDescriptionTooLong, Message: 'Must be 80 characters or less.' }
    ],
    'mc_site_name': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationSiteNameRequired, Message: 'Name is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationSiteNameTooLong, Message: 'Must be 128 characters or less.' }
    ],
    'mc_360_angle': [
      { Type: 'min', ErrorCode: ErrorCode.Validation360AngleTooLow, Message: 'Angle must be positive.' },
      { Type: 'max', ErrorCode: ErrorCode.Validation360AngleTooHigh, Message: 'Angle must be less than or equal to 360.' }
    ],
    'mc_image': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationImageRequired, Message: 'Image is required.' },
      { Type: 'file_type', ErrorCode: ErrorCode.ValidationImageFileTypeInvalid, Message: 'File must be an image.' },
      { Type: 'file_missing', ErrorCode: ErrorCode.ValidationImageFileMissing, Message: 'Image does not exist.' },
    ],
    'mc_pfx': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationPfxRequired, Message: 'PFX file is required.' },
      { Type: 'file_type', ErrorCode: ErrorCode.ValidationPfxFileTypeInvalid, Message: 'File must be PFX.' },
      { Type: 'invalid_content', ErrorCode: ErrorCode.ValidationPfxFileContentInvalid, Message: 'File content is invalid.' }
    ],
    'mc_csv': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationCsvRequired, Message: 'CSV file is required.' },
      { Type: 'file_type', ErrorCode: ErrorCode.ValidationCsvFileTypeInvalid, Message: 'File must be CSV.' },
      { Type: 'file_size', ErrorCode: ErrorCode.ValidationCsvTooLarge, Message: `File must be smaller than ${this.fileSizeService.format(MaxAttachmentSizeBytes, 0)}.` }
    ],
    'mc_csv_bulk_users': [
      { Type: 'not_empty_array', ErrorCode: ErrorCode.ValidationCsvEmpty, Message: 'CSV file cannot be empty.' },
      { Type: 'invalid_items', ErrorCode: ErrorCode.ValidationCsvItemsInvalid, Message: 'At least one entry must be a valid new user.' },
      { Type: 'user_bulk_csv_malformed_error', ErrorCode: ErrorCode.UserBulkCSVMalformedError, Message: 'Ensure that A1, B1, and C1 column headers are the following values in order: Email, FirstName, LastName. Rows with missing values will be skipped entirely.' },
      { Type: 'user_bulk_upload_error', ErrorCode: ErrorCode.UserBulkUploadError, Message: 'Must be 1,000 users or less.' }
    ],
    'mc_site': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationSiteRequired, Message: 'Site is required.' },
      // { Type: 'notEmptyArray', ErrorCode: ErrorCode.ValidationSiteDoesNotExist, Message: 'The selected teams have no access to any live private sites.' }
    ],
    'mc_site_styles_name': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationSiteThemeNameRequired, Message: 'Name is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationSiteThemeNameTooLong, Message: 'Must be 128 characters or less.' }
    ],
    'mc_content_security_policy_directive': [
      { Type: 'valid', ErrorCode: ErrorCode.ValidationContentSecurityPolicyDirectiveInvalid, Message: 'This directive is not valid.' },
    ],
    'mc_content_security_policy_name': [
      { Type: 'whitespace', ErrorCode: ErrorCode.ValidationContentSecurityPolicyNameBlank, Message: 'Name cannot be blank.' },
      { Type: 'required', ErrorCode: ErrorCode.ValidationContentSecurityPolicyNameRequired, Message: 'Name is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationContentSecurityPolicyNameTooLong, Message: 'Must be 128 characters or less.' }
    ],
    'mc_site_styles_logo': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationImageRequired, Message: 'Image is required.' },
      { Type: 'file_type', ErrorCode: ErrorCode.ValidationImageFileTypeInvalid, Message: 'File must be an image.' },
      { Type: 'file_size', ErrorCode: ErrorCode.ValidationSiteLogoImageTooLarge, Message: `File must be smaller than ${this.fileSizeService.format(MaxSiteLogoImageFileSizeBytes, 0)}.` },
    ],
    'mc_avatar_image': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationImageRequired, Message: 'Image is required.' },
      { Type: 'file_type', ErrorCode: ErrorCode.ValidationImageFileTypeInvalid, Message: 'File must be an image.' },
      { Type: 'file_size', ErrorCode: ErrorCode.ValidationAvatarImageTooLarge, Message: `File must be smaller than ${this.fileSizeService.format(MaxAvatarImageFileSizeBytes, 0)}.` },
    ],
    'mc_team_name': [
      { Type: 'exists', ErrorCode: ErrorCode.TeamNameExistsError, Message: 'The team name already exists.' },
      { Type: 'required', ErrorCode: ErrorCode.ValidationTeamNameRequired, Message: 'Team name is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationTeamNameTooLong, Message: 'Must be 256 characters or less.' }
    ],
    'mc_user_seat_type': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationUserSeatTypeRequired, Message: 'User seat type is required.' },
      { Type: 'max', ErrorCode: ErrorCode.ValidationUserSeatTypeTooMany, Message: 'Not enough seats available.' }
    ],
    'mc_viewer_teams': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationViewerTeamRequired, Message: 'At least one team is required.' },
      { Type: 'invalid_items', ErrorCode: ErrorCode.ValidationViewerTeamsMissingLivePrivateSite, Message: 'At least one team associated with a live private site is required.' }
    ],
    'mc_days': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationDaysRequired, Message: 'Day selection is required.' }
    ],
    'mc_branch_target': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationTargetPathRequired, Message: 'Target selection is required.' },
      { Type: 'supported', ErrorCode: ErrorCode.ValidationTargetNotSupported, Message: 'Building this target type is not supported on Flare Online.' }
    ],
    'mc_translation_package_name': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationTranslationPackagesNameRequired, Message: 'Name is required.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationTranslationPackagesNameNotValid, Message: 'Name contains illegal characters or spaces.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationTranslationPackagesNameMaxLength, Message: 'Must be 100 characters or less.' }
    ],
    'mc_languages': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationLanguagesRequired, Message: 'Language is required.' }
    ],
    'mc_open_ai_settings': [
      { Type: 'apiKey', ErrorCode: ErrorCode.ValidationOpenAIKey, Message: 'The API key cannot be validated.' }
    ],
    'mc_number': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationNumberRequired, Message: 'A number is required.' }
    ],
    'mc_date': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationDateRequired, Message: 'A date is required.' },
      { Type: 'matDatepickerParse', ErrorCode: ErrorCode.ValidationDateFormat, Message: 'Date has to be in the form of mm/dd/yyyy.' },
    ],
    'mc_task_board_title': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationTaskBoardTitleRequired, Message: 'Title is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationTaskBoardTitleTooLong, Message: 'Must be 128 characters or less.' },
      { Type: 'whitespace', ErrorCode: ErrorCode.ValidationTaskBoardTitleBlank, Message: 'Title cannot be blank.' }
    ],
    'mc_task_board_description': [
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationTaskBoardDescriptionTooLong, Message: 'Must be 256 characters or less.' }
    ],
    'mc_file_path': [
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationFilePathTooLong, Message: 'Must be 1024 characters or less.' },
      { Type: 'required', ErrorCode: ErrorCode.ValidationFilePathRequired, Message: 'File path is required.' },
      { Type: 'filePath', ErrorCode: ErrorCode.ValidationFileNameRequired, Message: 'File name is required.' },
      { Type: 'notChanged', ErrorCode: ErrorCode.ValidationFilePathNotChanged, Message: 'File path has not been changed.' },
      { Type: 'whitespaceFileName', ErrorCode: ErrorCode.ValidationFileNameWhitespace, Message: 'File name cannot be just whitespace.' },
      { Type: 'whitespace', ErrorCode: ErrorCode.ValidationFilePathWhitespace, Message: 'File path cannot be just whitespace.' },
      { Type: 'file_extension', ErrorCode: ErrorCode.ValidationFilePathExtension, Message: 'This extension is not supported. Please specify a different extension.' },
      { Type: 'file_extension_period', ErrorCode: ErrorCode.ValidationFilePathExtensionPeriod, Message: 'File names cannot end with a \' . \'' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationFilePathInvalid, Message: 'Please enter a valid file path. The following characters are not allowed: \\ : " < > | ? % # ' }
    ],
    'mc_multimedia': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationMultimediaLinkRequired, Message: 'Link to video required.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationSupportedExternalMultimediaLink, Message: 'Only YouTube or Vimeo video links are supported.' },
    ],
    'mc_folder_path': [
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationFolderPathTooLong, Message: 'Must be 1024 characters or less.' },
      { Type: 'required', ErrorCode: ErrorCode.ValidationFolderPathRequired, Message: 'Folder path is required.' },
      { Type: 'filePath', ErrorCode: ErrorCode.ValidationFolderNameRequired, Message: 'Folder name is required.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationFolderPathInvalid, Message: 'Please enter a valid folder path. The following characters are not allowed: \\ : " < > | ? % # ' }
    ],
    'mc_file_type': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationFileTypeRequired, Message: 'File type is required.' }
    ],
    'mc_file_template': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationFileTemplateRequired, Message: 'File template is required.' }
    ],
    'mc_files': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationFilesRequired, Message: 'At least one file is required.' },
      { Type: 'special_chars', ErrorCode: ErrorCode.ValidationFileNameSpecialChars, Message: 'One or more files contain invalid characters # %' },
      { Type: 'file_size', ErrorCode: ErrorCode.ValidationFilesTooLarge, Message: `Combined files size must be smaller than ${this.fileSizeService.format(MaxAttachmentSizeBytes, 0)}.` },
      { Type: 'invalid_items', ErrorCode: ErrorCode.ValidationFilesRequired, Message: 'At least one file is required.' }
    ],
    'mc_commit_message': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationCommitMessageRequired, Message: 'Commit message is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationCommitMessageTooLong, Message: 'Must be 2048 characters or less.' }
    ],
    'saml_endpoint': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationSamlEndpointRequired, Message: 'Endpoint is required.' }
    ],
    'saml_idp_issuer': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationSamlIdpIssuerRequired, Message: 'Identity provider issuer is required.' }
    ],
    'saml_public_cert': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationSamlPublicCertRequired, Message: 'Public certificate is required.' }
    ],
    'mc_review_package_name': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationReviewPackageNameRequired, Message: 'Name is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationReviewPackageNameTooLong, Message: 'Must be 128 characters or less.' }
    ],
    'mc_review_package_description': [
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationReviewPackageDescriptionTooLong, Message: 'Must be 256 characters or less.' }
    ],
    'mc_project': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationProjectRequired, Message: 'Project is required.' }
    ],
    'mc_branch': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationBranchRequired, Message: 'Branch is required.' }
    ],
    'mc_condition_tag_name': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationConditionTagNameRequired, Message: 'Condition tag name is required.' },
      { Type: 'whitespace', ErrorCode: ErrorCode.ValidationConditionTagNameBlank, Message: 'Condition tag name cannot be blank.' },
      { Type: 'exists', ErrorCode: ErrorCode.ValidationConditionTagNameExists, Message: 'A condition tag with this name already exists.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationFilePathInvalid, Message: 'Please enter a valid name. The following characters are not allowed: , . ? : * > | % [ ] " ' }
    ],
    'mc_color_picker': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationColorPickerValueRequired, Message: 'Must be hex in format.' },
      { Type: 'invalid', ErrorCode: ErrorCode.ValidationColorPickerValueRequired, Message: 'Must be hex in format.' },
    ],
    'mc_variables': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationVariableNameRequired, Message: 'Variables are required.' },
    ],
    'mc_variable_name': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationVariableNameRequired, Message: 'Variable name is required.' },
      { Type: 'whitespace', ErrorCode: ErrorCode.ValidationVariableNameBlank, Message: 'Variable name cannot be blank.' },
      { Type: 'exists', ErrorCode: ErrorCode.ValidationVariableNameExists, Message: 'A variable with this name already exists.' }
    ],
    'mc_variable_definition': [
      { Type: 'recursiveVariable', ErrorCode: ErrorCode.ValidationVariableDefinitionRecursive, Message: 'Cannot insert the same variable into its own definition.' },
      { Type: 'incorrectFormat', ErrorCode: ErrorCode.ValidationVariableDefinitionIncorrectFormat, Message: 'Definition is not in a correct format.' },
      { Type: 'exists', ErrorCode: ErrorCode.ValidationVariableDefinitionExists, Message: 'The variable already has the same definition.' }
    ],
    'mc_property': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationPropertyRequired, Message: 'Property value is required.' },
      { Type: 'invalid', ErrorCode: ErrorCode.ValidationPropertyInvalidFormat, Message: 'Invalid property value.' },
      { Type: 'incorrect_stylesheet_type', ErrorCode: ErrorCode.ValidationIncorrectStylesheetType, Message: 'Incorrect stylesheet type.' },
      { Type: 'incorrect_target_skin_type', ErrorCode: ErrorCode.ValidationInvalidTargetSkin, Message: 'Incorrect target skin type.' },
    ],
    'mc_branding_property': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationBrandingPropertyRequired, Message: 'Property value is required.' },
      { Type: 'invalid', ErrorCode: ErrorCode.ValidationBrandingPropertyInvalidFormat, Message: 'Invalid property value.' },
      { Type: 'file_missing', ErrorCode: ErrorCode.ValidationBrandingPropertyFileMissing, Message: 'File does not exist.' },
      { Type: 'font_missing', ErrorCode: ErrorCode.ValidationBrandingPropertyFontMissing, Message: 'Fonts not found on the server, though it can be valid in browsers. It is recommended to add fallback font sets.' },
      { Type: 'file_size', ErrorCode: ErrorCode.ValidationBrandingPropertyImageTooLarge, Message: `File must be smaller than ${this.fileSizeService.format(MaxAttachmentSizeBytes, 0)}.` },
    ],
    'mc_branding_property_name': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationBrandingPropertyNameRequired, Message: 'Property name cannot be blank.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationBrandingPropertyNamePattern, Message: 'Property name must contain only alphanumeric characters, underscores and dashes.' },
      { Type: 'exists', ErrorCode: ErrorCode.ValidationBrandingPropertyNameExists, Message: 'A property with this name already exists.' },
      { Type: 'type', ErrorCode: ErrorCode.ValidationBrandingPropertyNameType, Message: 'Property name is reserved for another type.' },
    ],
    'mc_proxy': [
      { Type: 'css_identifier', ErrorCode: ErrorCode.ValidationCssIdentifier, Message: 'Name must contain only alphanumeric characters, underscores, and dashes, as well as start with a letter, underscore or dash.' }
    ],
    'mc_project_name': [
      { Type: 'exists', ErrorCode: ErrorCode.ProjectNameExistsError, Message: 'The project name already exists.' },
      { Type: 'required', ErrorCode: ErrorCode.ProjectNameRequiredError, Message: 'Project name is required.' },
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationFilePathTooLong, Message: 'Project name must be 100 characters or less.' },
      { Type: 'pattern', ErrorCode: ErrorCode.ValidationFilePathInvalid, Message: `The project name can contain only letters, digits, '_', '-', '.' and space. It must start with letter, digit or '_'. The project name can't end with a dot.` }
    ],
    'mc_project_template': [
      { Type: 'required', ErrorCode: ErrorCode.ValidationProjectTemplateRequired, Message: 'Template is required.' }
    ],
    'mc_project_description': [
      { Type: 'maxlength', ErrorCode: ErrorCode.ValidationFilePathTooLong, Message: 'Project description must be 500 characters or less.' },
    ],
  };

  constructor(
    private imageService: ImageService,
    private fileService: FileService,
    private fileSizeService: FileSizeService,
    private errorService: ErrorService,
    private projectFilesService: ProjectFilesService,
    private stringService: StringService,
    private stylesheetService: ProjectStylesheetService
  ) { }

  getValidationTypes(validationType: string): Validation[] {
    return mergeArraysByKey('Type', this.genericValidations, this.validationTypes[validationType]);
  }

  createMatchValueValidator(otherControlName: string): ValidatorFn {
    return this.createMatchValidator(otherControlName, true);
  }

  createMismatchValueValidator(otherControlName: string): ValidatorFn {
    return this.createMatchValidator(otherControlName, false);
  }


  /*
   * From https://github.com/moebius-mlm/ng-validators/blob/master/src/validators/match-other.validator.ts
   */
  private createMatchValidator(otherControlName: string, valuesMustBeEqual: boolean): ValidatorFn {
    let thisControl: UntypedFormControl;
    let otherControl: UntypedFormControl;

    return function matchValueValidate(control: UntypedFormControl) {
      if (!control.parent) {
        return null;
      }

      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as UntypedFormControl;
        if (!otherControl) {
          throw new Error('createMatchValidator(): other control was not found in parent group');
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }

      if (!otherControl) {
        return null;
      }

      if (valuesMustBeEqual && otherControl.value !== thisControl.value) {
        return {
          match: true
        };
      } else if (!valuesMustBeEqual && otherControl.value === thisControl.value) {
        return {
          mismatch: true
        };
      }

      return null;
    };
  }

  createRequiredIfValueValidator(otherControlName: string, otherControlValue: any): ValidatorFn {
    return this.createdRequiredIfValidator(otherControlName, otherControlValue, true);
  }
  createRequiredIfNotValueValidator(otherControlName: string, otherControlValue: any): ValidatorFn {
    return this.createdRequiredIfValidator(otherControlName, otherControlValue, false);
  }

  createNotChangedValidator(oldValue: string): ValidatorFn {
    return function ifNotChangedValidate(control: UntypedFormControl) {
      return oldValue === control.value ? { notChanged: true } : null;
    };
  }

  createValidFileExtensionValidator(fileExtensionCheck: boolean = false): ValidatorFn {
    return (control: UntypedFormControl) => {
      const fileType = this.projectFilesService.getFileType(control.value);
      const validFileExtEndsWithoutPeriod = control.value.length && control.value.split('.').pop().trim().length === 0 ? { 'file_extension_period': true } : null;
      const validFileExt = !fileExtensionCheck || (fileType === ProjectFileType.Code || fileType === ProjectFileType.Flare || fileType == ProjectFileType.Stylesheet || this.projectFilesService.isProjectFlareDataFileType(fileType)) ? null : { 'file_extension': true };
      return Object.assign({}, validFileExtEndsWithoutPeriod, validFileExt);
    };
  }

  private createdRequiredIfValidator(otherControlName: string, otherControlValue: any, valuesMustBeEqual: boolean): ValidatorFn {
    let thisControl: UntypedFormControl;
    let otherControl: UntypedFormControl;

    return function ifValueValidate(control: UntypedFormControl) {
      if (!control.parent) {
        return null;
      }

      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as UntypedFormControl;
        if (!otherControl) {
          throw new Error('createdRequiredIfValidator(): other control was not found in parent group');
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }

      if (!otherControl) {
        return null;
      }

      if (valuesMustBeEqual && otherControl.value === otherControlValue && (thisControl.value === '' || thisControl.value === null || thisControl.value === undefined)) {
        return {
          required: true
        };
      } else if (!valuesMustBeEqual && otherControl.value !== otherControlValue && (thisControl.value === '' || thisControl.value === null || thisControl.value === undefined)) {
        return {
          required: true
        };
      }

      return null;
    };
  }

  createFileTypeValidator(fileTypes: string | string[], fileExtensions?: string | string[]): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const values = Array.isArray(control.value) ? control.value as File[] : [control.value as File];

      for (let i = 0; i < values.length; i++) {
        if (values[i]) {
          if (!this.fileService.isOfType(values[i], fileTypes)) {
            return { file_type: true };
          }

          if (fileExtensions && !this.fileService.hasExtension(values[i], fileExtensions)) {
            return { file_type: true };
          }
        }
      }

      return null;
    };
  }

  createFileSizeValidator(fileSizeBytes: number): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const values = Array.isArray(control.value) ? control.value as File[] : [control.value as File];

      for (let i = 0; i < values.length; i++) {
        if (values[i]?.size && !this.fileService.isSmallerOrEqualTo(values[i], fileSizeBytes)) {
          return { file_size: true };
        }
      }

      return null;
    };
  }

  createFileExtensionValidator(fileExtensions: string | string[]): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const values = Array.isArray(control.value) ? control.value as File[] : [control.value as File];

      for (let i = 0; i < values.length; i++) {
        if (values[i] && !this.fileService.hasExtension(values[i], fileExtensions)) {
          return { file_extension: true };
        }
      }

      return null;
    };
  }

  createFolderPathLengthValidator(otherControlName: string) {
    return (control: UntypedFormControl): ValidationErrors => {
      if (!control.parent) {
        return null;
      }

      const otherControl: UntypedFormControl = control.parent.get(otherControlName) as UntypedFormControl;

      if (otherControl.value) {
        return otherControl.value.find(file => file.name.length + control.value.length > MaxFilePathLength) ? { 'maxlength': true } : null;
      } else {
        return control.value.length > MaxFilePathLength ? { 'maxlength': true } : null;
      }
    };
  }

  createStorageSizeValidator(bytesControlName: string, usedStorageSpace: number): ValidatorFn {
    let maxStorageControl: UntypedFormControl;
    let bytesControl: UntypedFormControl;

    return function storageSizeValidator(control: UntypedFormControl): ValidationErrors {
      if (!control.parent) {
        return null;
      }

      if (!maxStorageControl) {
        maxStorageControl = control as UntypedFormControl;
        bytesControl = control.parent.get(bytesControlName) as UntypedFormControl;

        if (!bytesControl) {
          throw new Error('createStorageSizeValidator(): ' + bytesControlName + ' control is not found in parent group');
        }

        // Recalculate validation status if 'Measurement Unit' selector was changed
        bytesControl.valueChanges.subscribe(() => {
          maxStorageControl.markAsTouched();
          maxStorageControl.updateValueAndValidity();
        });
      }

      if (!bytesControl) {
        return null;
      }

      const totalBytes = (maxStorageControl.value * bytesControl.value);

      if (typeof totalBytes !== 'number') {
        return null;
      }

      if (totalBytes > MaxStorageSizeBytes) {
        return {
          max_storage_size: true
        };
      } else if (totalBytes < usedStorageSpace) {
        return {
          min_storage_size: true
        };
      }

      return null;
    };
  }

  createNotEmptyArrayValidator(): ValidatorFn {
    return this.createArrayValidator(false);
  }

  createEmptyArrayValidator(): ValidatorFn {
    return this.createArrayValidator(true);
  }

  private createArrayValidator(arrayMustBeEmpty: boolean): ValidatorFn {
    return function arrayEmptyValidator(control: UntypedFormControl): ValidationErrors {
      const items = control.value;

      if (Array.isArray(items)) {
        if (arrayMustBeEmpty && items.length !== 0) {
          return { empty_array: true };
        } else if (!arrayMustBeEmpty && items.length === 0) {
          return { not_empty_array: true };
        }
      }

      return null;
    }.bind(this);
  }

  createValidItemsValidator<T>(itemsValidator: (items: T[]) => boolean): ValidatorFn {
    return function validItemsValidator(control: UntypedFormControl): ValidationErrors {
      const items = control.value;

      if (Array.isArray(items) && items.length > 0 && !itemsValidator(items)) {
        return { invalid_items: true };
      }

      return null;
    };
  }

  buildImagePickerValue(image: MadCapImage): ImagePickerValue {
    return {
      height: image.Height,
      name: image.FileName,
      path: this.imageService.getMadCapImageUrl(image.StorageId, image.Extension),
      width: image.Width,
      type: 'image'
    };
  }

  buildValidationErrors(error: PackagedError, keys: Dictionary<ErrorCode | { code: ErrorCode, useServerMessage: boolean }>, defaultErrors?: ValidationErrors): ValidationErrors {
    const errors: ValidationErrors = mapValues(keys, errorConfig => {
      if (typeof errorConfig === 'number') {
        return this.errorService.hasErrorCode(error, errorConfig);
      } else if (this.errorService.hasErrorCode(error, errorConfig.code)) {
        return errorConfig.useServerMessage ? this.errorService.getErrorMessage(error, errorConfig.code) : true;
      } else {
        return false;
      }
    });

    const hasValidationError = this.hasValidationError(errors);
    if (defaultErrors && !hasValidationError) {
      return defaultErrors;
    } else if (hasValidationError) {
      return errors;
    } else {
      return null;
    }
  }

  hasValidationError(errors: ValidationErrors): boolean {
    return errors ? Object.values(errors).some(inError => inError) : false;
  }

  /**
   * Creates a validator function that checks if an error code exists in the provided validation context.
   * @param validationContext The validation context.
   * @param errorCode The error code to check for.
   * @returns A validator function that checks if an error code exists in the provided validation context.
   */
  createErrorCodeValidator(validationContext: FormValidationContext, errorCode: string): ValidatorFn {
    return (): ValidationErrors => {
      if (validationContext?.errors?.[errorCode]) {
        return { [errorCode]: validationContext.errors[errorCode] };
      }

      return null;
    };
  }

  /**
   * Creates a list of validator functions for a given form control name.
   * @param validationConfig The configuration defining which error codes are applicable to which form controls.
   * @param validationContext The validation context.
   * @param controlName The form control name.
   * @returns A list of validator functions for a given form control name.
   */
  createErrorCodeValidators(validationConfig: FormValidationConfig, validationContext: FormValidationContext, controlName: string): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    const errorCodes: string[] = validationConfig?.formControlErrors?.[controlName];
    if (errorCodes) {
      for (let errorCode of errorCodes) {
        validators.push(this.createErrorCodeValidator(validationContext, errorCode));
      }
    }
    return validators;
  }

  /**
   * Initializes error code validation for a given form using the provided configuration and validation context.
   * @param formGroup The form group to validate.
   * @param validationConfig The configuration defining which error codes are applicable to which form controls.
   * @param validationContext The validation context.
   * @returns A composite subscription containing all subscriptions created for validation.
   */
  initErrorCodeValidation(formGroup: UntypedFormGroup, validationConfig: FormValidationConfig, validationContext: FormValidationContext): Subscription {
    const formControlErrors = validationConfig?.formControlErrors;
    let subscription: Subscription = null;

    if (formGroup && formControlErrors) {
      for (let [controlName, errorCodes] of Object.entries(formControlErrors)) {
        const controlSubscription = formGroup.controls[controlName]?.valueChanges?.subscribe(value => {
          let dirty = false;
          for (let errorCode of errorCodes) {
            if (validationContext.errors?.[errorCode] && validationContext.values?.[controlName] !== value) {
              validationContext.errors[errorCode] = false;
              dirty = true;
            }
          }
          if (dirty) {
            formGroup.controls[controlName].updateValueAndValidity();
          }
        });

        if (subscription === null) {
          subscription = controlSubscription;
        }
        else {
          subscription.add(controlSubscription);
        }
      }
    }

    return subscription;
  }

  /**
   * Updates a validation context for a given form using the provided error.
   * @param formGroup The form group to validate.
   * @param validationConfig The configuration defining which error codes are applicable to which form controls.
   * @param validationContext The validation context.
   * @param error The error to process.
   */
  updateValidationContext(formGroup: UntypedFormGroup, validationConfig: FormValidationConfig, validationContext: FormValidationContext, error: PackagedError) {
    validationContext.errors = this.buildValidationErrors(error, validationConfig.errorCodes);
    validationContext.values = {};

    for (const controlName of Object.keys(validationConfig.formControlErrors)) {
      validationContext.values[controlName] = formGroup.value[controlName];
    }

    for (const controlName of Object.keys(validationConfig.formControlErrors)) {
      formGroup.controls[controlName].updateValueAndValidity();
    }
  }

  validationErrorsToString(validationType: string, errors: ValidationErrors): string {
    // If there are no errors then the error string is empty
    if (!errors) {
      return '';
    }

    const validations = this.getValidationTypes(validationType);

    // If there are no validations then we can't build the error string so return undefined
    if (!validations) {
      return;
    }

    // Filter out the valid keys and then map the invalid keys to their message
    return Object.keys(errors).filter(key => errors[key]).map(type => {
      return validations.find(validation => validation.Type === type)?.Message ?? '';
    }).join(' ');
  }

  daysValidator(daysFormGroup: UntypedFormGroup): ValidationErrors {
    const daysControl = daysFormGroup.controls;
    const valid = daysControl.Mon.value || daysControl.Tue.value || daysControl.Wed.value || daysControl.Thu.value || daysControl.Fri.value || daysControl.Sat.value || daysControl.Sun.value;
    return valid ? null : { required: true };
  }

  createTargetValidator(): ValidatorFn {
    return (buildScheduleForm: UntypedFormGroup): ValidationErrors => {
      return buildScheduleForm.controls.branchTarget.value.targetPath ? null : { required: true };
    }
  }

  minPasswordLengthValidator(minPasswordLength: number): ValidatorFn {
    return function minPasswordLengthValidation(control: UntypedFormControl): ValidationErrors {
      if (!control) {
        return null;
      }

      if (minPasswordLength && (!control.value || control.value.length < minPasswordLength)) {
        return { invalidLength: `Password must be at least ${minPasswordLength} characters long.` };
      }

      return null;
    };
  }

  /**
   * Creates async validator to check whether project file exists.
   */
  createProjectFileExistsValidator(projectId: number, branchName: string): AsyncValidatorFn {
    return (control: FormControl): Observable<ValidationErrors> => {
      const controlValue = Array.isArray(control.value) ? firstOrNull(control.value) : control.value;
      let value = controlValue?.toString().trim();
      if (!value) {
        return of(null);
      }
      value = normalizePath(value);
      return this.projectFilesService.isProjectFileExists$(projectId, value, branchName)
        .pipe(map(file => file?.Type === ProjectFileSystemType.File ? null : { file_missing: true }),
          // we use setErrors for the first execution, otherwise the control status is changed but no error message is shown
          tap(errors => {
            if (errors)
              control.setErrors({ ...(control.errors ?? {}), ...errors })
          }));
    }
  }

  /**
   * Creates async validator to validate the project stylesheet selected in control.
   *
   * Note: This validator should be used with caution as it is expensive and
   * may create a load on the server by requesting the contents of the validating files.
   */
  createProjectStylesheetValidator(projectId: number, branchName: string, stylesheetType: ProjectStylesheetType): AsyncValidatorFn {
    return (control: FormControl): Observable<ValidationErrors> => {
      const controlValue = Array.isArray(control.value) ? firstOrNull(control.value) : control.value;
      let value = controlValue?.toString().trim();
      if (!value) {
        return of(null);
      }
      value = normalizePath(value);
      return this.projectFilesService.getProjectFile$(projectId, value, branchName)
        .pipe(
          map(file => this.stringService.stripBOM(Base64.decode(file.Content))),
          map(content => this.stylesheetService.stylesheetType(content) !== stylesheetType ? { incorrect_stylesheet_type: true } : null),
          // we use setErrors for the first execution, otherwise the control status is changed but no error message is shown
          tap(errors => {
            if (errors)
              control.setErrors({ ...(control.errors ?? {}), ...errors })
          }));
    }
  }

  /**
   * Creates async validator to validate the project skin selected in control.
   *
   * Note: This validator should be used with caution as it is expensive and
   * may create a load on the server by requesting the contents of the validating files.
   */
  createProjectSkinValidator(projectId: number, branchName: string, skinType: ProjectSkinType): AsyncValidatorFn {
    return (control: FormControl): Observable<ValidationErrors> => {
      const controlValue = Array.isArray(control.value) ? firstOrNull(control.value) : control.value;
      let value = controlValue?.toString().trim();
      if (!value) {
        return of(null);
      }
      value = normalizePath(value);
      return this.projectFilesService.getProjectFile$(projectId, value, branchName)
        .pipe(
          map(file => {
            const content = this.stringService.stripBOM(Base64.decode(file.Content));
            const xml = new DOMParser().parseFromString(content, 'text/xml');
            const root = xml.querySelector('CatapultSkin');
            const val = root?.getAttribute('SkinType');
            let type: ProjectSkinType = ProjectSkinType[val];
            if (typeof type === 'undefined')
              type = ProjectSkinType.Unknown;
            if (type !== skinType)
              switch (skinType) {
                case ProjectSkinType.WebHelp2: return { incorrect_target_skin_type: true };
              }
            return null;
          }),
          // we use setErrors for the first execution, otherwise the control status is changed but no error message is shown
          tap(errors => {
            if (errors)
              control.setErrors({ ...(control.errors ?? {}), ...errors })
          }));
    }
  }
}
