import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { LegacyCanColor as CanColor, legacyMixinColor as mixinColor, LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacySlideToggle as MatSlideToggle } from '@angular/material/legacy-slide-toggle';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageSort } from '@common/paged-data/types/page-sort.type';
import { SortDirection } from '@common/paged-data/types/sort-direction.type';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { AutocompleteInputComponent } from '@portal-core/ui/autocomplete/components/autocomplete-input/autocomplete-input.component';
import { FilterPickerFilterName } from '@portal-core/ui/filter-picker/enums/filter-picker-filter-name.enum';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { GridFilterSelectOption } from '@portal-core/ui/grid/models/grid-filter-select-option.model';
import { ColumnAutocomplete } from '@portal-core/ui/grid/types/column-autocomplete.type';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { Filterable } from '@portal-core/ui/page-filters/types/filterable.type';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { combineLatest, debounceTime, map, Observable, Subscription } from 'rxjs';

// Boilerplate for applying the color mixin to FilterPickerComponent
class FilterPickerComponentBase {
  constructor(public _elementRef: ElementRef) { }
}
const FilterPickerComponentMixinBase: Constructor<CanColor> & typeof FilterPickerComponentBase = mixinColor(FilterPickerComponentBase);

/**
 * The values of a bool form group. Internally used to help with type checking.
 */
interface BoolFormValue {
  name: string;
  title: string;
  toggled: boolean;
}

/**
 * The values of a date form group. Internally used to help with type checking.
 */
interface DateFormValue {
  condition: PageFilterType;
  date: ISO8601DateString;
  name: string;
  title: string;
}

/**
 * The values of a select form group. Internally used to help with type checking.
 */
interface SelectFormValue {
  name: string;
  options: (number | string)[];
  title: string;
  autocomplete: ColumnAutocomplete;
  selectOptions: GridFilterSelectOption;
}

/**
 * mc-filter-picker
 * A filter picker form which is dynamically built from the page filter config provided by the picker's Filterable.
 * Automatically updates the Filterable's filters as the form is changed.
 * Can be hooked up to anything that implements Filterable such as a paged data list.
 */
@Component({
  selector: 'mc-filter-picker',
  templateUrl: './filter-picker.component.html',
  styleUrls: ['./filter-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class FilterPickerComponent extends FilterPickerComponentMixinBase implements OnInit, CanColor {
  /** The theme color of the picker. */
  @Input() color: ThemePalette;
  /** A dictionary of column names with Dictionary values. Where those dictionary values map to input names used by components in the grid headers.  */
  @Input() columnInputs?: Dictionary<Dictionary<any>>;
  /** The filterable that the filter-picker gets and applies the filters. */
  @Input() filterable: Filterable;

  /** Applies the mc-filter-picker class to the host element. */
  @HostBinding('class.mc-filter-picker') hostClass: boolean = true;

  /** A reference to the clear button. */
  @ViewChild('clearButton') clearButton: MatButton;
  /** A reference to the search field input element. */
  @ViewChild('searchField') searchFieldInputRef: ElementRef<HTMLInputElement>;
  /** A reference to the first dynamic component for the autocomplete control. */
  @ViewChild('selectAutocomplete', { read: AutocompleteInputComponent }) selectAutocomplete: AutocompleteInputComponent;
  /** A reference to the first select control. */
  @ViewChild('selectSelect', { read: MatSelect }) selectSelect: MatSelect;
  /** A reference to the sort select control. */
  @ViewChild('sortSelect', { read: MatSelect }) sortSelect: MatSelect;
  /** A reference to the bool toggle controls. */
  @ViewChildren('boolToggles', { read: MatSlideToggle }) boolToggles: QueryList<MatSlideToggle>;
  /** A reference to the date select controls. */
  @ViewChildren('dateSelect', { read: MatSelect }) dateSelects: QueryList<MatSelect>;

  /** An observable of the filterable property. */
  @PropertyObservable('filterable') filterable$: Observable<Filterable>;

  /** Returns the bools FormArray from the form group. */
  get boolsFormArray(): UntypedFormArray {
    return this.filterPickerForm.get('bools') as UntypedFormArray;
  }

  /** Returns the dates FormArray from the form group. */
  get datesFormArray(): UntypedFormArray {
    return this.filterPickerForm.get('dates') as UntypedFormArray;
  }

  /** Returns the selects FormArray from the form group. */
  get selectsFormArray(): UntypedFormArray {
    return this.filterPickerForm.get('selects') as UntypedFormArray;
  }

  /** Returns the order FormGroup from the form. */
  get orderGroup(): UntypedFormGroup {
    return this.filterPickerForm.get('order') as UntypedFormGroup;
  }

  PageDataType: typeof PageDataType = PageDataType;
  PageFilterType: typeof PageFilterType = PageFilterType;

  private boolsChangeSubscription: Subscription;
  private boolsFilterSubscription: Subscription;
  private datesChangeSubscription: Subscription;
  private datesFilterSubscription: Subscription;
  private filterableSubscription: Subscription;
  filterPickerForm: UntypedFormGroup;
  private searchChangeSubscription: Subscription;
  searchFilterConfig$: Observable<{ placeholder: string, columns: GridColumn[] }>;
  private searchFilterSubscription: Subscription;
  private selectsChangeSubscription: Subscription;
  private selectsFilterSubscription: Subscription;
  showFilterSection$: Observable<boolean>;
  sortColumns$: Observable<GridColumn[]>;
  private sortChangeSubscription: Subscription;
  private sortFilterSubscription: Subscription;

  constructor(private elementRef: ElementRef<HTMLElement>, private formBuilder: UntypedFormBuilder, private pageFilterService: PageFilterService, private cdr: ChangeDetectorRef) {
    super(elementRef);
    this.buildForm();
  }

  /** Subscribes to filter and form change events to sync up the filters across the filterable and the form. */
  ngOnInit() {
    // Listen for changes to the filterable's config for string columns to build the placeholder for the search field
    this.searchFilterConfig$ = this.filterable$.pipe(
      map(filterable => filterable?.filterConfig?.stringFilters),
      map(columns => {
        if (columns?.length > 0) {
          return {
            placeholder: columns.map(gridColumn => gridColumn.title).join(', '),
            columns: columns
          };
        } else {
          return null;
        }
      })
    );

    // Listen for changes to the filterable's config for bool, date, and select columns to determine if the filter section should be shown
    this.showFilterSection$ = combineLatest([
      this.filterable$.pipe(
        map(filterable => filterable?.filterConfig?.booleanFilters)
      ),
      this.filterable$.pipe(
        map(filterable => filterable?.filterConfig?.dateFilters)
      ),
      this.filterable$.pipe(
        map(filterable => filterable?.filterConfig?.selectFilters)
      )
    ]).pipe(
      map(([boolFilters, dateFilters, selectFilters]) => {
        return boolFilters?.length > 0 || dateFilters?.length > 0 || selectFilters?.length > 0;
      })
    );

    // Listen for changes to the filterable's config for sort columns
    this.sortColumns$ = this.filterable$.pipe(
      map(filterable => filterable?.filterConfig?.sortColumns)
    );

    // Listen for changes to the bool filters
    this.boolsFilterSubscription = this.filterable.getFilter$(FilterPickerFilterName.Bool).subscribe(pageFilter => {
      this.updateBoolsFormArray(pageFilter);
    });

    // Listen for changes to the date filters
    this.datesFilterSubscription = this.filterable.getFilter$(FilterPickerFilterName.Date).subscribe(pageFilter => {
      this.updateDatesFormArray(pageFilter);
    });

    // Listen for changes to the select filters
    this.selectsFilterSubscription = this.filterable.getFilter$(FilterPickerFilterName.Select).subscribe(pageFilter => {
      this.updateSelectsFormArray(pageFilter);
    });

    // Listen for changes to the search filter
    this.searchFilterSubscription = this.filterable.getFilter$(FilterPickerFilterName.Search).subscribe(pageFilter => {
      const searchValue = this.pageFilterService.getValueFromSearch(pageFilter);
      if (this.filterPickerForm.get('search').value !== searchValue) {
        this.filterPickerForm.get('search').setValue(searchValue);
      }
    });

    // Listen for changes to the sort order
    this.sortFilterSubscription = this.filterable.getOrder$().subscribe(order => {
      order = order ?? { by: null, dir: null };

      if (this.orderGroup.value.by !== order.by || this.orderGroup.value.dir !== order.dir) {
        this.updateOrderGroup({
          by: order.by,
          dir: order.dir
        });
      }
    });

    // Listen for changes to the bool control values and apply them as a filter
    this.boolsChangeSubscription = this.filterPickerForm.get('bools').valueChanges.subscribe((boolValues: BoolFormValue[]) => {
      const fb = this.pageFilterService.create(this.filterable.getFilter(FilterPickerFilterName.Bool) ?? {
        Id: FilterPickerFilterName.Bool,
        Type: PageFilterGroupType.Bool
      });

      boolValues?.forEach(boolValue => {
        fb.bool(boolValue.name, boolValue.toggled);
      });

      this.filterable.applyFilter$(FilterPickerFilterName.Bool, fb.value);
    });

    // Listen for changes to the date control values and apply them as a filter
    this.datesChangeSubscription = this.filterPickerForm.get('dates').valueChanges.subscribe((dateValues: DateFormValue[]) => {
      const fb = this.pageFilterService.create(this.filterable.getFilter(FilterPickerFilterName.Date) ?? {
        Id: FilterPickerFilterName.Date,
        Type: PageFilterGroupType.Date
      });

      dateValues?.forEach(dateValue => {
        fb.date(dateValue.name, dateValue.condition ? dateValue.date : null, null, { FilterType: dateValue.condition });
      });

      this.filterable.applyFilter$(FilterPickerFilterName.Date, fb.value);
    });

    // Listen for changes to the search control's value and apply it as a filter
    this.searchChangeSubscription = this.filterPickerForm.get('search').valueChanges.pipe(
      debounceTime(400)
    ).subscribe((searchValue: string) => {
      const filterBuilder = this.pageFilterService.create({
        Id: FilterPickerFilterName.Search,
        Type: PageFilterGroupType.Search
      });

      filterBuilder.search(this.filterable.filterConfig.stringFilterNames, searchValue, this.filterable.filterConfig.stringFilters.map(column => {
        return {
          PropertyName: column.filterName,
          PropertyType: column.filterDataType ?? column.type
        };
      }));

      this.filterable.applyFilter$(FilterPickerFilterName.Search, filterBuilder.value);
    });

    // Listen for changes to the select control values and apply them as a filter
    this.selectsChangeSubscription = this.filterPickerForm.get('selects').valueChanges.subscribe((selectsValues: SelectFormValue[]) => {
      const fb = this.pageFilterService.create(this.filterable.getFilter(FilterPickerFilterName.Select) ?? {
        Id: FilterPickerFilterName.Select,
        Type: PageFilterGroupType.Select
      });

      selectsValues?.forEach(selectValue => {
        fb.select(selectValue.name, selectValue.options);
      });

      this.filterable.applyFilter$(FilterPickerFilterName.Select, fb.value);
    });

    // Listen for changes to the sort order value and apply it as a filter
    this.sortChangeSubscription = this.filterPickerForm.get('order').valueChanges.subscribe((order: PageSort) => {
      const dir: SortDirection = order?.dir ? 'asc' : 'desc';
      this.filterable.setOrder$(order?.by, dir);
    });

    // Reset the form whenever the filterable is set
    this.filterableSubscription = this.filterable$.subscribe(filterable => {
      this.resetForm(filterable);
      this.cdr.markForCheck();
    });
  }

  /** Handler for the clear filter button that removes all the filters applied by the filter picker. */
  onClearFilterClicked() {
    this.filterable.applyFilter$(FilterPickerFilterName.Bool, null);
    this.filterable.applyFilter$(FilterPickerFilterName.Date, null);
    this.filterable.applyFilter$(FilterPickerFilterName.Select, null);
    this.filterable.applyFilter$(FilterPickerFilterName.Search, null);
    this.filterable.setOrder$(null, null);
  }

  getColumnInputValue(columnName: string, inputName: string): any {
    return this.columnInputs?.[columnName]?.[inputName];
  }

  /** Gives focus to the first control in the filter picker form. */
  focus() {
    // Focus the first field in the form. We only know which is first by checking each type in the order they appear to see if it exists
    if (this.searchFieldInputRef?.nativeElement) {
      this.searchFieldInputRef.nativeElement.focus();
    } else if (this.selectAutocomplete) {
      this.selectAutocomplete.focus();
    } else if (this.selectSelect) {
      this.selectSelect.focus();
    } else if (this.dateSelects?.first) {
      this.dateSelects.first.focus();
    } else if (this.boolToggles?.first) {
      this.boolToggles.first.focus();
    } else if (this.sortSelect) {
      this.sortSelect.focus();
    } else if (this.clearButton) {
      this.clearButton.focus();
    }
  }

  /** Builds the FormGroup for the form. */
  private buildForm() {
    this.filterPickerForm = this.formBuilder.group({
      bools: this.formBuilder.array([]),
      dates: this.formBuilder.array([]),
      order: this.formBuilder.group({
        by: new UntypedFormControl(),
        dir: new UntypedFormControl()
      }),
      search: new UntypedFormControl(),
      selects: this.formBuilder.array([])
    });
  }

  /** Resets the form values to match the given Filterable. */
  private resetForm(filterable: Filterable) {
    const boolsFormArray = this.boolsFormArray;
    const datesFormArray = this.datesFormArray;
    const selectsFormArray = this.selectsFormArray;

    // Clear out the FormArray controls since they will get re-populated
    boolsFormArray.clear();
    datesFormArray.clear();
    selectsFormArray.clear();

    if (filterable?.filterConfig?.stringFilters?.length > 0) {
      const searchValue = this.pageFilterService.getValueFromSearch(filterable.getFilter(FilterPickerFilterName.Search));
      this.filterPickerForm.get('search').setValue(searchValue);
    } else {
      this.filterPickerForm.get('search').setValue(null);
    }

    if (filterable?.filterConfig?.booleanFilters?.length > 0) {
      const boolsFilter = filterable.getFilter(FilterPickerFilterName.Bool);

      filterable.filterConfig.booleanFilters.forEach(column => {
        const toggled = this.pageFilterService.getValueFromBools(boolsFilter, column.name);

        boolsFormArray.push(this.formBuilder.group({
          toggled: new UntypedFormControl(toggled),
          // Add the name and title as controls so that they are available in the template and value change events
          name: new UntypedFormControl(column.name),
          title: new UntypedFormControl(column.title)
        }));
      });
    }

    if (filterable?.filterConfig?.dateFilters?.length > 0) {
      const datesFilter = filterable.getFilter(FilterPickerFilterName.Date);

      filterable.filterConfig.dateFilters.forEach(column => {
        const filterOptions = this.pageFilterService.getFilterFromDates(datesFilter, column.name);

        datesFormArray.push(this.formBuilder.group({
          condition: new UntypedFormControl(filterOptions?.FilterType),
          date: new UntypedFormControl(filterOptions?.PropertyValue),
          // Add the name and title as controls so that they are available in the template and value change events
          name: new UntypedFormControl(column.name),
          title: new UntypedFormControl(column.title)
        }));
      });
    }

    if (filterable?.filterConfig?.selectFilters?.length > 0) {
      const selectsFilter = filterable.getFilter(FilterPickerFilterName.Select);

      filterable.filterConfig.selectFilters.forEach(column => {
        const options = this.pageFilterService.getValueFromSelects(selectsFilter, column.name);

        let group: UntypedFormGroup;

        group = this.formBuilder.group({
          options: new UntypedFormControl(options),
          // Add the name, title, autocomplete, and selectOptions as controls so that they are available in the template and value change events
          name: new UntypedFormControl(column.name),
          title: new UntypedFormControl(column.title),
          autocomplete: new UntypedFormControl(column.autocomplete),
          selectOptions: new UntypedFormControl(column.selectOptions)
        });

        selectsFormArray.push(group);
      });
    }

    this.updateOrderGroup(filterable?.getOrder(), true);
  }

  /** Updates the order group form values. */
  private updateOrderGroup(order: PageSort, emitEvent: boolean = false) {
    const by = order?.by ?? null;
    const dir = order?.dir === 'asc' ? true : null;

    this.orderGroup.setValue({ by, dir }, { emitEvent });
  }

  /** Updates the bools form array values. */
  private updateBoolsFormArray(boolsFilter: PageFilterGroup) {
    const boolsFormArray = this.boolsFormArray;

    boolsFormArray.controls.forEach(formGroup => {
      const toggled = this.pageFilterService.getValueFromBools(boolsFilter, formGroup.get('name').value);
      formGroup.patchValue({ toggled }, { emitEvent: false });
    });
  }

  /** Updates the dates form array values. */
  private updateDatesFormArray(datesFilter: PageFilterGroup) {
    const datesFormArray = this.datesFormArray;

    datesFormArray.controls.forEach(formGroup => {
      const filterOptions = this.pageFilterService.getFilterFromDates(datesFilter, formGroup.get('name').value);

      formGroup.patchValue({
        condition: filterOptions?.FilterType,
        date: filterOptions?.PropertyValue
      }, {
        emitEvent: false
      });
    });
  }

  /** Updates the selects form array values. */
  private updateSelectsFormArray(selectsFilter: PageFilterGroup) {
    const selectsFormArray = this.selectsFormArray;

    selectsFormArray.controls.forEach(formGroup => {
      const options = this.pageFilterService.getValueFromSelects(selectsFilter, formGroup.get('name').value);
      formGroup.patchValue({ options }, { emitEvent: false });
    });
  }
}
