<button type="button" mat-icon-button class="mc-dialog-floating-close-button" tabindex="-1" (click)="closeDialog()">
  <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
</button>

<mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

<mc-switch-board color="secondary">
  <mc-switch-board-bar class="mc-spring-board">
    <ng-container *ngIf="license$ | async as license">
      <div class="mc-spring-board-header">
        <mc-license-avatar [license]="license" [showName]="false" [disabled]="true" size="large"></mc-license-avatar>
      </div>

      <div class="mc-spring-board-body">
        <h3 class="text-x-large mc-license-profile-dialog-title">{{ license.KeyLabel || 'License' }}</h3>
        <ul class="mc-property-list">
          <li>
            <span class="mc-property-name">Flare Online Key: </span>
            <span class="mc-property-value">{{ license.ShortKey }} {{ license.LicenseType === LicenseType.Trial ? ' - Trial' : '' }}</span>
          </li>
          <li>
            <span class="mc-property-name">{{ license.LicenseType === LicenseType.Trial ? 'Expiration' : 'Renewal' }} Date: </span>
            <span class="mc-property-value">{{ expirationDate$ | async | date: 'shortDate' }}</span>
          </li>
          <li>
            <span class="mc-property-name">Renewal Type: </span>
            <span class="mc-property-value">
              <ng-container *ngIf="license.AutoRenewMonths >= 12">Annual</ng-container>
              <ng-container *ngIf="license.AutoRenewMonths === 1">Monthly</ng-container>
              <ng-container *ngIf="!license.AutoRenewMonths">None</ng-container>
            </span>
          </li>
          <li>
            <span class="mc-property-name">Auto Renew: </span>
            <span class="mc-property-value">
              {{ license.IsAutoRenew ? 'Yes': 'No' }}
            </span>
          </li>
          <li>
            <span class="mc-property-name">Single Sign-On: </span>
            <span class="mc-property-value">
              {{ (ssoSettings$ | async)?.Enabled ? 'Enabled': 'Disabled' }}
            </span>
          </li>
        </ul>
      </div>

      <mat-nav-list class="mc-spring-board-footer mc-list-text-size-normal mc-list-text-weight-normal mc-list-bg-dark mc-list-compact">
        <a href="#" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': licenseProfileTab === LicenseProfileTab.Overview }" (click)="licenseProfileTab = LicenseProfileTab.Overview">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-overview" color="primary"></mat-icon> Overview
        </a>
        <a *ngIf="showSettingsTab$ | async" href="#" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': licenseProfileTab === LicenseProfileTab.Settings }" (click)="licenseProfileTab = LicenseProfileTab.Settings">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-pen" color="primary"></mat-icon> Settings
        </a>
        <a *ngIf="showPurchasingAndBillingTabs$ | async" href="#" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': licenseProfileTab === LicenseProfileTab.Purchasing }" (click)="licenseProfileTab = LicenseProfileTab.Purchasing">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-credit-card" color="primary"></mat-icon> Purchasing
        </a>
        <a *ngIf="showPurchasingAndBillingTabs$ | async" href="#" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': licenseProfileTab === LicenseProfileTab.Billing }" (click)="licenseProfileTab = LicenseProfileTab.Billing">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-bill" color="primary"></mat-icon> Billing
        </a>
        <a *ngIf="showSlackTab$ | async" href="#" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': licenseProfileTab === LicenseProfileTab.Slack }" (click)="licenseProfileTab = LicenseProfileTab.Slack">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-plug" color="primary"></mat-icon> Slack
        </a>
        <a href="#" *ngIf="showAssistAITab$ | async" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': licenseProfileTab === LicenseProfileTab.OpenAI }" (click)="licenseProfileTab = LicenseProfileTab.OpenAI">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-chat-gpt" color="primary"></mat-icon> AI Assist
        </a>
        <a *ngIf="showSecurityTab$ | async" href="#" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': licenseProfileTab === LicenseProfileTab.Security }" (click)="licenseProfileTab = LicenseProfileTab.Security">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-privacy" color="primary"></mat-icon> Security
        </a>
        <a *ngIf="showSsoTab$ | async" href="#" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': licenseProfileTab === LicenseProfileTab.SSO }" (click)="licenseProfileTab = LicenseProfileTab.SSO">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-sso" color="primary"></mat-icon> Single Sign-On
        </a>
      </mat-nav-list>
    </ng-container>
  </mc-switch-board-bar>

  <mc-switch-board-content [ngSwitch]="licenseProfileTab" class="mc-spring-board">
    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="LicenseProfileTab.Overview">
      <mat-tab label="Overview">
        <mc-license-overview [license]="license$ | async"></mc-license-overview>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="LicenseProfileTab.Settings">
      <mat-tab label="Settings">
        <mc-license-settings-form [license]="license$ | async" (cancel)="closeDialog()" (saved)="onSettingsSaved()"></mc-license-settings-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="LicenseProfileTab.Purchasing">
      <mat-tab label="Purchasing">
        <mc-license-purchasing-form [license]="license$ | async" [licenseUser]="licenseUser$ | async" (closeDialog)="closeDialog()" (selectLicenseProfileTab)="licenseProfileTab=$event"></mc-license-purchasing-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="LicenseProfileTab.Billing">
      <mat-tab label="Billing">
        <mc-license-billing-form *ngIf="userCanPurchase$ | async" [license]="license$ | async" (closeDialog)="closeDialog()"></mc-license-billing-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="LicenseProfileTab.Slack">
      <mat-tab label="Slack">
        <mc-license-slack-form *ngIf="subscriptionNotExpired && (userCanManageSlack$ | async)" [license]="license$ | async" [licenseUser]="licenseUser$ | async" [subscribers]="subscribers$ | async" [channels]="channels$ | async" [auditCategoryTree]="(auditCategoryTree$ | async)" (closeDialog)="closeDialog()"></mc-license-slack-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="LicenseProfileTab.OpenAI">
      <mat-tab label="ChatGPT">
        <mc-license-open-ai-form *ngIf="userCanManageServer$ | async" [license]="license$ | async" (cancel)="closeDialog()"></mc-license-open-ai-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="LicenseProfileTab.Security">
      <mat-tab label="Security">
        <mc-license-security-form [license]="license$ | async" [readOnly]="!(userCanManageServer$ | async)" (closeDialog)="closeDialog()" (saved)="onSecuritySaved()"></mc-license-security-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="LicenseProfileTab.SSO">
      <mat-tab label="Single Sign-On">
        <ng-template mat-tab-label>
          Single Sign-On
        </ng-template>
        <mc-license-sso-form [license]="license$ | async" (saved)="onSSoSettingsSaved()"></mc-license-sso-form>
      </mat-tab>
    </mat-tab-group>
  </mc-switch-board-content>
</mc-switch-board>

<div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>