import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { FilterPickerComponent } from '@portal-core/ui/filter-picker/components/filter-picker/filter-picker.component';
import { Filterable } from '@portal-core/ui/page-filters/types/filterable.type';

/**
 * mc-filter-picker-popup-content
 * The content of an mc-filter-popup which contains the mc-filter-picker.
 */
@Component({
  selector: 'mc-filter-picker-popup-content',
  templateUrl: './filter-picker-popup-content.component.html',
  styleUrls: ['./filter-picker-popup-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterPickerPopupContentComponent implements AfterViewInit {
  /** The theme color of the picker. */
  @Input() color: ThemePalette;

  /** A dictionary of column names with Dictionary values. Where those dictionary values map to input names used by components in the grid headers.  */
  @Input() columnInputs?: Dictionary<Dictionary<any>>;

  /** The column config of the picker. */
  @Input() filterable: Filterable;

  /** The instance of the FilterPickerComponent. */
  @ViewChild(FilterPickerComponent, { static: true }) filterPicker: FilterPickerComponent;

  /** Adds the mc-popup-content class to the host element. */
  @HostBinding('class.mc-popup-content') popupContentHostClass: boolean = true;

  /** Gives focus to the picker once the view is ready. */
  ngAfterViewInit() {
    this.filterPicker.focus();
  }
}
