import { ComponentType, FlexibleConnectedPositionStrategyOrigin, Overlay } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, NgZone, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FilterPickerPopupContentComponent } from '@portal-core/ui/filter-picker/components/filter-picker-popup-content/filter-picker-popup-content.component';
import { FilterPickerToggleComponent } from '@portal-core/ui/filter-picker/components/filter-picker-toggle/filter-picker-toggle.component';
import { ElementOverlayService } from '@portal-core/ui/overlay/services/element.overlay.service';
import { Filterable } from '@portal-core/ui/page-filters/types/filterable.type';
import { PopupBase } from '@portal-core/ui/popup/util/popup-base';

/**
 * mc-filter-picker-popup
 * A popup that contains an mc-filter-picker.
 */
@Component({
  selector: 'mc-filter-picker-popup',
  template: '',
  styleUrls: ['./filter-picker-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterPickerPopupComponent extends PopupBase<FilterPickerPopupContentComponent> {
  /** A dictionary of column names with Dictionary values. Where those dictionary values map to input names used by components in the grid headers.  */
  @Input() columnInputs?: Dictionary<Dictionary<any>>;

  /** The filterable that the filter picker is bound to. */
  @Input() filterable: Filterable;

  /** The mc-filter-picker-toggle instance registered with this filter picker popup. */
  private toggle: FilterPickerToggleComponent;

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected ngZone: NgZone,
    protected overlay: Overlay,
    protected elementOverlayService: ElementOverlayService,
    protected viewContainerRef: ViewContainerRef
  ) {
    super(document, ngZone, overlay, elementOverlayService, viewContainerRef);
  }

  /**
   * Registers the mc-filter-picker-toggle with the filter picker popup.
   * The toggle is used to get configuration for the popup such as the location of the popup.
   * @param input The toggle to register with this filter picker popup.
   */
  registerToggle(toggle: FilterPickerToggleComponent) {
    if (this.toggle) {
      throw Error('A FilterPickerPopupComponent can only be associated with a single toggle.');
    }

    this.toggle = toggle;
  }

  /** Returns the mc-filter-picker-popup-content component class for the popup content. */
  protected getPopupContent(): ComponentType<FilterPickerPopupContentComponent> {
    return FilterPickerPopupContentComponent;
  }

  /** Returns the input element as the origin. */
  protected getPopupOrigin(): FlexibleConnectedPositionStrategyOrigin {
    return this.toggle.getConnectedOverlayOrigin();
  }

  /** Returns the selector for the popup transform origin. */
  protected getPopupTransformOrigin(): string {
    return 'mc-filter-picker-popup-content';
  }

  /** Returns a square popup size that is equal to the width of the input field. */
  protected getPopupSize(): { width: number, height: number } {
    return {
      width: 300,
      height: undefined // Let the height be variable
    };
  }

  /** Initializes the mc-filter-picker-popup-content instance. */
  protected initPopup() {
    this.popupComponentRef.instance.color = this.color;
    this.popupComponentRef.instance.filterable = this.filterable;
    this.popupComponentRef.instance.columnInputs = this.columnInputs;
  }
}
