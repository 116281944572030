import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { LicenseUsersPageFilterConfig } from '@portal-core/license-users/util/license-users-page-filter-config';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { ListControl } from '@portal-core/ui/list/util/list-control';
import { ListBase } from '@portal-core/ui/list/util/list.base';
import { of } from 'rxjs';

@Component({
  selector: 'mc-team-license-users-list',
  templateUrl: './team-license-users-list.component.html',
  styleUrls: ['./team-license-users-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamLicenseUsersListComponent extends ListBase<LicenseUser> implements OnChanges {
  @Input() teamId: number;
  @Input() canEdit: boolean = false;

  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  removing: number[] = [];

  filterConfig: LicenseUsersPageFilterConfig = new LicenseUsersPageFilterConfig([
    'User.FullName',
    'SeatType',
    'User.Email'
  ]);

  listControl: ListControl<LicenseUser> = new ListControl<LicenseUser>(this.licenseUsersService, filter => {
    if (typeof this.teamId === 'number') {
      return this.teamsService.getTeamLicenseUsers$(this.teamId, filter);
    } else {
      return of(null);
    }
  });

  constructor(
    private licenseUsersService: LicenseUsersService,
    private teamsService: TeamsService,
    private snackBar: MatSnackBar,
  ) {
    super(licenseUsersService);
    const fullNameColumn = this.filterConfig.getColumn('User.FullName');
    fullNameColumn.filterEnabled = false;
    fullNameColumn.sortEnabled = true;
    const emailColumn = this.filterConfig.getColumn('User.Email');
    emailColumn.filterEnabled = false;
    emailColumn.sortEnabled = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.teamId) {
      this.hardReload();
    }
  }

  onRemoveLicenseUser(licenseUser: LicenseUser) {
    this.removing = [
      ...this.removing,
      licenseUser.Id
    ];
    this.teamsService.removeUserFromTeam$(licenseUser.User.Id, this.teamId).subscribe(() => {
      this.licenseUsersService.removePagedDataListItems$(this.dataListId, [licenseUser.Id]);
      this.removing = this.removing.filter(id => id !== licenseUser.Id);
      this.saved.emit();
    }, error => {
      this.removing = this.removing.filter(id => id !== licenseUser.Id);
      this.snackBar.open('Unable to remove the user from the team.', 'OK');
    });
  }
}
