<form class="mc-spring-board" (ngSubmit)="onSubmit(translationPackageSettingsForm)" [formGroup]="translationPackageSettingsForm">
  <div class="mc-spring-board-form-body">
    <p class="mc-form-text" *ngIf="translationPackage.UseMadTranslations">
      Settings cannot be edited for packages sent to MadTranslations. To update project settings, please contact your MadTranslations project manager.
    </p>
    <mat-form-field appearance="standard" color="secondary" class="mc-form-field">
      <textarea matInput type="text" formControlName="PackageDescription" placeholder="Description"></textarea>
    </mat-form-field>
    <mat-form-field appearance="standard" class="mc-form-field" color="secondary">
      <mat-label>Due Date</mat-label>
      <input matInput formControlName="DueDate" [matDatepicker]="datePicker" placeholder="Due Date">
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker color="secondary" #datePicker></mat-datepicker>
      <mat-error>
        <mc-form-control-error-message [form]="translationPackageSettingsForm" controlName="DueDate" type="mc_date"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="standard" color="secondary" class="mc-form-field">
      <mat-label>Branch</mat-label>
      <mc-autocomplete-input [multiple]="false" [triggerPopupOnFocus]="false" formControlName="Branch" [required]="true" [disabled]="true">
        <mc-branches-select-list mcAutocompleteList [projectId]="translationPackage.ProjectId"></mc-branches-select-list>
        <ng-template mcAutocompleteItem let-branchId="item">
          <mc-branch-avatar compact [showAvatar]="!translationPackage.UseMadTranslations" [disabledTooltip]="translationPackage.UseMadTranslations" [disabled]="true" [showName]="true" [branchName]="(branchId | mcBranchAsync | async)?.Name" size="small" [truncate]="true"></mc-branch-avatar>
        </ng-template>
      </mc-autocomplete-input>
      <mc-dropdown-arrow matSuffix></mc-dropdown-arrow>
      <mat-error>
        <mc-form-control-error-message [form]="translationPackageSettingsForm" controlName="Branch" type="mc_branch"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" color="secondary" class="mc-form-field">
      <mat-label>Targets</mat-label>
      <mc-project-file-picker-input [branchName]="translationPackage.SourceBranchName" [fileFilter]="NonBatchTargetFileFilter" [projectId]="translationPackage.ProjectId" rootFolder="Project/Targets" treeClass="mc-tree-avatars" formControlName="Targets" placeholder="Select targets or leave blank for whole project">
        <ng-template mcProjectFilesTreeItem="file" let-item>
          <mc-target-avatar [disabled]="true" [disabledTooltip]="translationPackage.UseMadTranslations" size="small" [targetPath]="item.name" [truncate]="true"></mc-target-avatar>
        </ng-template>
        <ng-template mcProjectFilePickerInputItem let-path>
          <mc-target-avatar [disabled]="true" [disabledTooltip]="translationPackage.UseMadTranslations" [showAvatar]="false" [showPath]="true" size="small" [targetPath]="path" [truncate]="true"></mc-target-avatar>
        </ng-template>
      </mc-project-file-picker-input>
      <mat-error>
        <mc-form-control-error-message [form]="translationPackageSettingsForm" controlName="Targets" type="mc_branch_target"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="translationPackageSettingsForm.pristine || translationPackageSettingsForm.invalid || (savingState.loading$ | async)">Save</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>