import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { CurrentService } from '@portal-core/current/services/current.service';
import { PermissionsFormComponent } from '@portal-core/permissions/components/permissions-form/permissions-form.component';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { Permissions } from '@portal-core/permissions/models/permissions.model';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { PermissionsFormControl } from '@portal-core/permissions/util/permissions-form-control';
import { ProfileBase } from '@portal-core/profiles/util/profile.base';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { TeamAccessFormComponent, TeamAccessFormTab } from '@portal-core/teams/components/team-access-form/team-access-form.component';
import { TeamSettingsFormComponent } from '@portal-core/teams/components/team-settings-form/team-settings-form.component';
import { TeamProfileTab } from '@portal-core/teams/enums/team-profile-tab.enum';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { MC_FOCUSABLE } from '@portal-core/ui/focus/util/focusable.token';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { combineLatest, map, Observable, switchMap } from 'rxjs';

export enum TeamProfileForm {
  Settings,
  Access,
  Activity,
  Delete
}

@Component({
  selector: 'mc-team-profile',
  templateUrl: './team-profile.component.html',
  styleUrls: ['./team-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MC_FOCUSABLE, useExisting: TeamProfileComponent }
  ]
})
export class TeamProfileComponent extends ProfileBase<TeamProfileTab> implements OnInit {
  @Input() team: Team;
  @Input() showAccess?: boolean = true;
  @Input() showActivity?: boolean = true;
  @Input() showDelete?: boolean = true;
  @Input() showPermissions?: boolean = true;
  @Input() showSettings?: boolean = true;
  @Input() teamAccessFormTab?: TeamAccessFormTab;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<TeamProfileForm> = new EventEmitter<TeamProfileForm>();

  @InputObservable('team') team$: Observable<Team>;
  @InputObservable('showAccess') showAccess$: Observable<boolean>;
  @InputObservable('showActivity') showActivity$: Observable<boolean>;
  @InputObservable('showDelete') showDelete$: Observable<boolean>;
  @InputObservable('showPermissions') showPermissions$: Observable<boolean>;
  @InputObservable('showSettings') showSettings$: Observable<boolean>;

  @ViewChild(PermissionsFormComponent) permissionsFormComponent: PermissionsFormComponent;
  @ViewChild(TeamSettingsFormComponent) teamSettingsForm: TeamSettingsFormComponent;
  @ViewChild(TeamAccessFormComponent) teamAccessForm: TeamAccessFormComponent;

  public get requirePromptOnClose(): boolean {
    return this.dirty;
  }

  get dirty(): boolean {
    switch (this.profileTab) {
      case TeamProfileTab.Settings:
        return this.teamSettingsForm?.dirty;
      case TeamProfileTab.Permissions:
        return this.permissionsFormComponent?.dirty;
      default:
        return false;
    }
  }

  TeamProfileTab: typeof TeamProfileTab = TeamProfileTab;

  showAccessTab$: Observable<boolean>;
  showActivityTab$: Observable<boolean>;
  showDeleteTab$: Observable<boolean>;
  showPermissionsTab$: Observable<boolean>;
  showSettingsTab$: Observable<boolean>;
  teamProjectIdsNotArchived$: Observable<number[]>;
  userCanDeleteTeams$: Observable<boolean>;
  userCanManageTeams$: Observable<boolean>;

  permissionsFormControl: PermissionsFormControl = new PermissionsFormControl(
    (teamId: number, licenseId: number) => this.permissionsService.getTeamPermissionsOnLicenseLevel$(teamId),
    (teamId: number, projectId: number) => this.permissionsService.getTeamPermissionsOnProjectLevel$(teamId, projectId),
    (teamId: number, licenseId: number, permissions: Permissions[]) => this.permissionsService.saveTeamPermissionsOnLicenseLevel$(teamId, licenseId, permissions),
    (teamId: number, projectId: number, permissions: Permissions[]) => this.permissionsService.saveTeamPermissionsOnProjectLevel$(teamId, projectId, permissions)
  );

  constructor(
    dialog: MatDialog,
    cdr: ChangeDetectorRef,
    private permissionsService: PermissionsService,
    private currentService: CurrentService,
    private teamsService: TeamsService,
    private pageFilterService: PageFilterService
  ) {
    super(dialog, cdr);
  }

  ngOnInit() {
    super.ngOnInit();

    this.userCanDeleteTeams$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.DeleteTeams);
    this.userCanManageTeams$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageTeamsProjects);

    // Create an observable for whether the current user is an author
    const currentUserIsAuthor$ = this.currentService.getCurrentLicenseUserIsAuthor$();

    const filter: PageFilter = {
      ...this.pageFilterService.create({
        Id: 'team-projects-not-archived',
        Type: PageFilterGroupType.Custom
      }).select('Status', [ProjectStatus.Active, ProjectStatus.Locked]).value,
      PageNumber: 0,
      PerPage: -1
    };
    this.teamProjectIdsNotArchived$ = this.team$.pipe(
      switchMap(team => this.teamsService.getTeamProjects$(team.Id, filter)),
      map(page => page.Items.map(project => project.Id))
    );

    this.showAccessTab$ = this.showAccess$;
    this.showActivityTab$ = this.showActivity$;
    this.showDeleteTab$ = combineLatest([this.showDelete$, this.userCanDeleteTeams$]).pipe(
      map(([showDelete, userCanDeleteTeams]) => showDelete && userCanDeleteTeams)
    );
    this.showPermissionsTab$ = combineLatest([this.showPermissions$, currentUserIsAuthor$]).pipe(
      map(([showPermissions, currentUserIsAuthor]) => showPermissions && currentUserIsAuthor)
    );
    this.showSettingsTab$ = combineLatest([this.showDelete$, this.userCanManageTeams$]).pipe(
      map(([showDelete, userCanManageTeams]) => showDelete && userCanManageTeams)
    );
  }

  onSettingsSaved() {
    this.saved.emit(TeamProfileForm.Settings);
  }

  onTeamAccessSaved() {
    this.saved.emit(TeamProfileForm.Access);
  }

  onTeamDeleted() {
    this.saved.emit(TeamProfileForm.Delete);
  }

  onCancel() {
    this.cancel.emit();
  }
}
