import { Injectable } from '@angular/core';
import { Build } from '@portal-core/builds/models/build.model';
import { BuildsService } from '@portal-core/builds/services/builds.service';
import { CurrentService } from '@portal-core/current/services/current.service';
import { LicenseHostMapsService } from '@portal-core/license-host-maps/services/license-host-maps.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { LicenseHostMapProfile } from '@portal-core/profiles/models/license-host-map-profile.model';
import { LicenseProfile } from '@portal-core/profiles/models/license-profile.model';
import { ProjectProfile } from '@portal-core/profiles/models/project-profile.model';
import { ReviewFileProfile } from '@portal-core/profiles/models/review-file-profile.model';
import { ReviewPackageProfile } from '@portal-core/profiles/models/review-package-profile.model';
import { UserProfile } from '@portal-core/profiles/models/user-profile.model';
import { ProfilesApiService } from '@portal-core/profiles/services/profiles-api.service';
import { ProjectInfoService } from '@portal-core/project-info/services/project-info.service';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { ReviewFilesService } from '@portal-core/reviews/review-files/services/review-files.service';
import { ReviewPackageUsersService } from '@portal-core/reviews/review-package-users/services/review-package-users.service';
import { ReviewPackagesService } from '@portal-core/reviews/review-packages/services/review-packages.service';
import { SiteGridItem } from '@portal-core/sites/models/site-grid-item.model';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { TranslationPackageLanguageBranchProfile } from '@portal-core/translation-package-language-branches/models/translation-package-language-branch-profile.model';
import { TranslationPackageLanguageBranchesService } from '@portal-core/translation-package-language-branches/services/translation-package-language-branches.service';
import { TranslationPackageProfile } from '@portal-core/translation-packages/models/translation-package-profile.model';
import { TranslationPackagesService } from '@portal-core/translation-packages/services/translation-packages.service';
import { UserProfileTab } from '@portal-core/users/enums/user-profile-tab.enum';
import { UsersService } from '@portal-core/users/services/users.service';
import { forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {
  constructor(
    private buildsService: BuildsService,
    private currentService: CurrentService,
    private licensesService: LicensesService,
    private licenseHostMapsService: LicenseHostMapsService,
    private licenseUsersService: LicenseUsersService,
    private profilesApiService: ProfilesApiService,
    private projectInfoService: ProjectInfoService,
    private projectsService: ProjectsService,
    private reviewFilesService: ReviewFilesService,
    private reviewPackagesService: ReviewPackagesService,
    private reviewPackageUsersService: ReviewPackageUsersService,
    private sitesService: SitesService,
    private teamsService: TeamsService,
    private translationPackagesService: TranslationPackagesService,
    private translationPackageLanguageBranchesService: TranslationPackageLanguageBranchesService,
    private usersService: UsersService
  ) { }

  getProjectProfile$(projectId: number): Observable<ProjectProfile> {
    return this.profilesApiService.getProjectProfile$(projectId).pipe(
      tap(profile => {
        profile.Project.ProjectTeams = profile.Teams.sort((teamA, teamB) => teamA.Name.localeCompare(teamB.Name)).map(team => {
          return {
            ProjectId: profile.Project.Id,
            TeamId: team.Id
          };
        });

        profile.Project.ProjectLicenseUsers = profile.LicenseUsers.sort((userA, userB) => userA.User.FullName.localeCompare(userB.User.FullName))
          .map(licenseUser => {
            return {
              LicenseUserId: licenseUser.Id,
              ProjectId: profile.Project.Id,
              Status: licenseUser.Status,
            };
          });

        this.licenseUsersService.addItems$(profile.LicenseUsers);
        this.projectsService.addItems$({ [profile.Project.Id]: profile.Project });
        this.projectInfoService.addItems$([profile.ProjectInfo]);
        this.teamsService.addItems$(profile.Teams);
      })
    );
  }

  getReviewFileProfile$(reviewId: number): Observable<ReviewFileProfile> {
    return this.profilesApiService.getReviewFileProfile$(reviewId).pipe(
      switchMap(profile => {
        return forkJoin([
          this.reviewFilesService.addItems$([profile.ReviewFile]),
          profile.Owner ? this.licenseUsersService.addItems$([profile.Owner]) : of(null),
          profile.Project ? this.projectsService.addItems$([profile.Project]) : of(null)
        ]).pipe(
          map(() => profile)
        );
      })
    );
  }

  getReviewPackageProfile$(reviewPackageId: number): Observable<ReviewPackageProfile> {
    return this.profilesApiService.getReviewPackageProfile$(reviewPackageId).pipe(
      switchMap(profile => {
        return forkJoin([
          this.reviewPackagesService.addItems$([profile.ReviewPackage]),
          profile.Owner ? this.licenseUsersService.addItems$([profile.Owner]) : of(null),
          profile.Project ? this.projectsService.addItems$([profile.Project]) : of(null),
          profile.ReviewPackageUser ? this.reviewPackageUsersService.addItems$([profile.ReviewPackageUser]) : of(null)
        ]).pipe(
          map(() => profile)
        );
      })
    );
  }

  getUserProfile$(userId: string, licenseId: number): Observable<UserProfile> {
    return this.profilesApiService.getUserProfile$(userId, licenseId).pipe(
      tap(profile => {
        profile.LicenseUser.User = profile.User;
        this.licenseUsersService.addItems$({ [profile.LicenseUser.Id]: profile.LicenseUser });
        this.projectsService.addItems$(Array.isArray(profile.Projects) ? profile.Projects.sort((projectA, projectB) => projectA.Name.localeCompare(projectB.Name)) : null);
        this.teamsService.addItems$(Array.isArray(profile.Teams) ? profile.Teams.sort((teamA, teamB) => teamA.Name.localeCompare(teamB.Name)) : null);
      })
    );
  }

  getLicenseHostMapProfile$(licenseHostMapId: number): Observable<LicenseHostMapProfile> {
    return this.profilesApiService.getLicenseHostMapProfile$(licenseHostMapId).pipe(
      switchMap(profile => {
        let sites = Array.isArray(profile.Sites) ? profile.Sites : [];
        if (profile.DefaultSite) {
          // Do not modify the original sites array
          sites = [profile.DefaultSite, ...sites];
        }

        return forkJoin([
          this.licenseHostMapsService.addItems$([profile.LicenseHostMap]),
          this.sitesService.setListById$('LicenseHostMaps', licenseHostMapId, profile?.Sites.sort((siteA, siteB) => siteA.Name.localeCompare(siteB.Name))),
          sites.length > 0 ? this.sitesService.addItems$(sites) : of(null)
        ]).pipe(
          map(() => profile)
        );
      })
    );
  }

  getBuildProfile$(buildId: number): Observable<Build> {
    return this.profilesApiService.getBuildProfile$(buildId).pipe(
      map(build => this.buildsService.processBuild(build)),
      tap(build => {
        this.buildsService.addItems$([build]);
        this.licenseUsersService.addItems$([build.CreatedByLicenseUser]);
        this.projectsService.addItems$([build.Project]);
        this.sitesService.addItems$(build.SitesList);
      })
    );
  }

  getSiteProfile$(siteId: number): Observable<SiteGridItem> {
    return this.profilesApiService.getSiteProfile$(siteId).pipe(
      tap(site => {
        this.sitesService.addItems$([site]);
        if (site.Build) {
          this.buildsService.addItems$([this.buildsService.processBuild(site.Build)]);
        }

        if (Array.isArray(site.SiteTeams) && site.SiteTeams.length > 0) {
          this.teamsService.addItems$(site.SiteTeams.filter(siteTeam => !!siteTeam.Team).map(siteTeam => siteTeam.Team));
        }
      })
    );
  }

  getTranslationPackageProfile$(translationPackageId: number): Observable<TranslationPackageProfile> {
    return this.profilesApiService.getTranslationPackageProfile$(translationPackageId).pipe(
      tap(profile => {
        this.translationPackagesService.addItems$([profile.TranslationPackage]);
        this.projectsService.addItems$([profile.Project]);
      })
    );
  }

  getTranslationPackageLanguageBranchProfile$(languagePackageId: number): Observable<TranslationPackageLanguageBranchProfile> {
    return this.profilesApiService.getTranslationPackageLanguageBranchProfile$(languagePackageId).pipe(
      tap(profile => {
        this.translationPackageLanguageBranchesService.addItems$([profile.TranslationPackageLanguageBranch]);
        this.projectsService.addItems$([profile.Project]);
      })
    );
  }

  getLicenseProfile$(licenseId: number): Observable<LicenseProfile> {
    return this.profilesApiService.getLicenseProfile$(licenseId).pipe(
      tap(profile => {
        this.licensesService.addItems$([profile.License]);
      })
    );
  }

  defaultUserProfileTabForLicenseUser(licenseUser: LicenseUser, currentLicenseUser: LicenseUser): UserProfileTab {
    if (this.usersService.hasSomeRoles(currentLicenseUser.User, ['SystemAdmin', 'SupportAdmin', 'QAAdmin'])) {
      return UserProfileTab.Access;
    } else if (this.currentService.isCurrentLicenseUser(licenseUser.Id)) {
      return UserProfileTab.Settings;
    } else if (currentLicenseUser.SeatType === LicenseUserSeatType.Author) {
      return UserProfileTab.Access;
    } else {
      return UserProfileTab.Activity;
    }
  }
}
