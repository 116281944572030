<div class="mc-spring-board">
  <div class="mc-spring-board-form-body">
    <mc-review-file-list #filesList [licenseId]="reviewPackage?.LicenseId" listClass="mc-list-no-outer-spacing" [reviewPackageId]="reviewPackage?.Id" [showDeleteButton]="userCanManageReviews$ | async"></mc-review-file-list>
  </div>

  <div *ngIf="userCanManageReviews$ | async" class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <button type="button" class="mc-review-package-files-form-add-files-button" color="primary" [disabled]="savingState.loading$ | async" mat-raised-button [mcPopupTriggerFor]="addFilesPopup" [mcPopupHeight]="400" [mcPopupWidth]="506">
      <div class="mc-d-flex mc-align-items-center">
        <ng-container *ngIf="savingState.loading$ | async; else addFiles">Adding Files</ng-container>
        <ng-template #addFiles>Add Files</ng-template>
        <span class="mc-fill-remaining-flex-space"></span>
        <mc-dropdown-arrow class="mc-review-package-files-form-dropdown-arrow"></mc-dropdown-arrow>
      </div>
    </button>
  </div>
</div>

<mc-popup #addFilesPopup (afterOpen)="onPopupOpened()" (closed)="onPopupClosed()">
  <div class="mc-spring-board">
    <div class="mc-spring-board-form-body">
      <mc-project-files-tree #filesTree [treeData]="{projectId: reviewPackage?.ProjectId, branchName: reviewPackage?.BranchName}" [fileFilter]="FlareFileFilter" projectFilesTreeType="selection" rootFolder="Content" [show404ErrorAsEmptyTree]="true" [(value)]="filesTreeValue"></mc-project-files-tree>
    </div>

    <div class="mc-spring-board-form-footer">
      <div class="mc-form-buttons">
        <button type="button" mat-stroked-button color="warn" (click)="onSaveFilesCancelClicked()">Cancel</button>
        <button type="button" mat-raised-button color="primary" (click)="onSaveFilesClicked()">Add and Save Files</button>
      </div>
    </div>
  </div>
</mc-popup>