import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { IncludedInModule } from '@portal-core/general/pipes/included-in/included-in.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { ListModule } from '@portal-core/ui/list/list.module';
import { TeamLicenseUsersListComponent } from './team-license-users-list.component';


@NgModule({
  declarations: [
    TeamLicenseUsersListComponent
  ],
  imports: [
    CommonModule,
    ListModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    LicenseUserAvatarModule,
    IncludedInModule,
    MatSnackBarModule,
  ],
  exports: [
    TeamLicenseUsersListComponent
  ]
})
export class TeamLicenseUsersListModule { }
