import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

export class SitePageFilterConfig extends PageFilterConfigBase {
  static readonly Columns: GridColumn[] = [
    {
      name: 'IsLive',
      title: 'Live',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Select,
      filterDataType: PageDataType.Boolean,
      selectOptions: [{ id: 'false', text: 'None' }, { id: 'true', text: 'Live' }]
    },
    {
      name: 'Id',
      title: 'ID',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Int,
    },
    {
      name: 'Name',
      title: 'Name',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.String,
    },
    {
      name: 'LicenseId',
      title: 'License Id',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Int,
    },
    {
      name: 'ProjectId',
      title: 'Project Id',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Int,
    },
    {
      name: 'Project.Name',
      title: 'Project',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.String
    },
    {
      name: 'TargetPath',
      title: 'Target',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.String
    },
    {
      name: 'VanityUrl.Url',
      title: 'Vanity',
      sortEnabled: true,
      type: PageDataType.String
    },
    {
      name: 'CreatedOn',
      title: 'Created On',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Date
    },
    {
      name: 'CreatedByUser.FullName',
      title: 'Created By',
      filterEnabled: true,
      type: PageDataType.FullName
    },
    {
      name: 'IsPrivate',
      title: 'Private',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Select,
      filterDataType: PageDataType.Boolean,
      selectOptions: [{ id: 'false', text: 'Public' }, { id: 'true', text: 'Private' }]
    },
    // {
    //   name: 'IsReviewable',
    //   title: 'Reviewable',
    //   filterEnabled: true,
    //   sortEnabled: true,
    //   type: PageDataType.Select,
    //   filterDataType: PageDataType.Boolean,
    //   selectOptions: [{ id: 'false', text: 'None' }, { id: 'true', text: 'Reviewable' }]
    {
      name: 'SiteTeams',
      title: 'Access',
      type: PageDataType.String
    },
    {
      name: 'ContentSecurityPolicy.Name',
      title: 'Content Security Policy',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.String,
      filterDataType: PageDataType.ContentSecurityPolicy
    },
    {
      name: 'SiteStyles.Name',
      title: 'Themes',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.String,
      filterDataType: PageDataType.SiteStyles
    },
    {
      name: 'VanityUrlId',
      title: 'Vanity Url Id',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Int,
    },
    {
      name: 'PrivateOutputUrl',
      title: 'Private Output Url',
      type: PageDataType.String,
    },
    {
      name: 'LiveOutputUrl',
      title: 'Live Output Url',
      type: PageDataType.String,
    },
    {
      name: 'LicenseHostMap.MappedDomain',
      title: 'License Host Map',
      type: PageDataType.String,
    }
  ];

  constructor(enabledColumnNames?: string[]) {
    super(enabledColumnNames, SitePageFilterConfig.Columns);
  }
}
