<mc-list [dataListId]="dataListId" [itemHeight]="48" [listClass]="listClass" [listControl]="listControl" [loaderBackgroundColor]="loaderBackgroundColor">
  <ng-template mcListItem let-reviewPackageUser>
    <mat-list-item class="mc-list-item">
      <mc-license-user-avatar compact [licenseUser]="reviewPackageUser.UserId | mcLicenseUserForLicenseAsync : licenseId | async" [showSeatType]="true" size="small" [truncate]="true"></mc-license-user-avatar>
    </mat-list-item>
  </ng-template>

  <ng-template mcEmptyList>
    <p>There are no reviewers assigned to this package.</p>
  </ng-template>

  <ng-template mcAllItemsLoadedList>
    <p>All reviewers loaded.</p>
  </ng-template>
</mc-list>