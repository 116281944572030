import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, ViewEncapsulation } from '@angular/core';
import { LayoutService } from '@portal-core/layout/services/layout.service';
import { Breakpoints } from '@portal-core/util/breakpoints';
import { Observable } from 'rxjs';

@Component({
  selector: 'mc-sub-page-fill',
  templateUrl: './sub-page-fill.component.html',
  styleUrls: ['./sub-page-fill.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubPageFillComponent {
  showAppNav: Observable<BreakpointState>;
  showPageGutter: Observable<BreakpointState>;

  constructor(private layoutService: LayoutService, private breakpointObserver: BreakpointObserver) {
    this.showAppNav = this.breakpointObserver.observe(Breakpoints.appNavBreakpoint);
    this.showPageGutter = this.breakpointObserver.observe(Breakpoints.pageGutterBreakpoint);
  }

  onLogoClicked() {
    this.layoutService.emitOpenAboutDialog();
  }
}
