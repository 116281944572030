<ng-container [ngSwitch]="errorCode">
  <span *ngSwitchCase="ErrorCode.GenericLoginError" i18n="The error message for generic login failure">Unable to log you in.</span>
  <span *ngSwitchCase="ErrorCode.LoginLoadError" i18n="The error message for when the login configuration fails to load">Unable to load the log in configuration.</span>
  <span *ngSwitchCase="ErrorCode.UnknownLoginError" i18n="The error message for an unknown login failure">An unexpected error occurred while logging you in.</span>
  <span *ngSwitchCase="ErrorCode.GenericCompleteInviteError" i18n="The error message for generic complete invite failure">Unable to complete your invitation.</span>
  <span *ngSwitchCase="ErrorCode.GenericUpdatePasswordError" i18n="The error message for generic password update failure">Unable to update your password.</span>
  <span *ngSwitchCase="ErrorCode.GenericForgotPasswordError" i18n="The error message for generic password reset request failure">Unable to reset your password. Please try again later. If the problem persists, please contact Technical Support.</span>
  <span *ngSwitchCase="ErrorCode.GenericResetPasswordError" i18n="The error message for generic password reset failure">Unable to change your password.</span>
  <span *ngSwitchCase="ErrorCode.GenericRequestSiteAccessError" i18n="The generic error message for requests for site access">Unable to send your request for access to this site.</span>
  <span *ngSwitchCase="ErrorCode.GenericRequestLicenseAccessError" i18n="The generic error message for requests for license access">Unable to send your request for license access.</span>
  <span *ngSwitchCase="ErrorCode.GenericLoadUserSitesError" i18n="The generic error message for loading the sites a user has access to">Unable to load your sites.</span>
  <span *ngSwitchCase="ErrorCode.GenericLoadSiteStylesError" i18n="The generic error message for loading site styles failure">Unable to load the site theme.</span>
  <span *ngSwitchCase="ErrorCode.GenericSaveSiteStylesError" i18n="The generic error message for saving site styles failure">Unable to save the site theme.</span>
  <span *ngSwitchCase="ErrorCode.GenericSaveContentSecurityPolicyError" i18n="The generic error message for saving content security policy failure">Unable to save the content security policy.</span>
  <span *ngSwitchCase="ErrorCode.GenericLoadContentSecurityPolicyError" i18n="The generic error message for loading content security policy failure">Unable to load the content security policy.</span>
  <span *ngSwitchCase="ErrorCode.GenericLoadSiteStylesListError" i18n="The generic error message for loading a license's site styles failure">Unable to load the site themes.</span>
  <span *ngSwitchCase="ErrorCode.GenericLicenseVanityVerificationError" i18n="The generic error message for verifying a license vanity exists">Unable to verify the license vanity.</span>
  <span *ngSwitchCase="ErrorCode.GenericLicenseListError" i18n="The generic error message for for loading the licenses a user belongs to">Unable to load your licenses.</span>
  <span *ngSwitchCase="ErrorCode.GenericLoadCentralInstancesError" i18n="The generic error message for for loading the central instances">Unable to load the server locations.</span>
  <span *ngSwitchCase="ErrorCode.IdleLogoutError" i18n="The auto logout message due to inactivity">For your security - you have been logged out due to inactivity.</span>
  <span *ngSwitchCase="ErrorCode.NotFoundError" i18n="The error message for generic 404 not found errors">The server resource was not found.</span>

  <span *ngSwitchCase="ErrorCode.ValidationPhoneNumberRequired" i18n="The validation error message when a phone number is missing in a form">Phone number is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationPhoneNumberInvalid" i18n="The validation error message when a phone number is invalid in a form">Please enter a valid phone number.</span>
  <span *ngSwitchCase="ErrorCode.ValidationPhoneNumberTooLong" i18n="The validation error message when a phone number is too long in a form">Must be 30 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationPhoneNumberTooShort" i18n="The validation error message when a phone number is too short in a form">Must be 7 characters or more.</span>
  <span *ngSwitchCase="ErrorCode.ValidationEmailRequired" i18n="The validation error message when an email address is missing in a form">Email is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationEmailInvalid" i18n="The validation error message when an email address is invalid in a form">Please enter a valid email.</span>
  <span *ngSwitchCase="ErrorCode.ValidationEmailTooLong" i18n="The validation error message when an email address is too long in a form">Must be 128 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationConfirmEmailRequired" i18n="The validation error message when a confirm email address is missing in a form">Confirm your email.</span>
  <span *ngSwitchCase="ErrorCode.ValidationConfirmEmailMismatch" i18n="The validation error message when a confirm email address does not match the email address in a form">Emails do not match. Try again.</span>
  <span *ngSwitchCase="ErrorCode.ValidationPasswordRequired" i18n="The validation error message when the password is missing in a form">Password is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationPasswordTooShort" i18n="The validation error message when the password is too short in a form">Password is too short.</span>
  <span *ngSwitchCase="ErrorCode.ValidationNewPasswordMustBeDifferent" i18n="The validation error message when the new password is the same as the old password in a form">The new password must be different than the old password.</span>
  <span *ngSwitchCase="ErrorCode.ValidationConfirmPasswordRequired" i18n="The validation error message when the confirm password is missing in a form">Confirm your password.</span>
  <span *ngSwitchCase="ErrorCode.ValidationConfirmPasswordMismatch" i18n="The validation error message when the confirm password is not equal to the password in a form">Passwords do not match. Try again.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTermsOfUseRequired" i18n="The validation error message when the user has not checked the terms of use checkbox in a form">Please accept the terms and conditions.</span>
  <span *ngSwitchCase="ErrorCode.ValidationKeyLicenseLabelRequired" i18n="The validation error message when the license key label is missing in a form">Key label is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSeatsRequired" i18n="The validation error message when license seats are missing in a form">Seats is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSeatsNumberTooLarge" i18n="The validation error message when license seats number is too long in a form">Must be 10,000 seats or fewer.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSeatsNumberInvalid" i18n="The validation error message when a number is invalid in a form">Please enter a valid number.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSeatsTooLow" i18n="The validation error message when the max count of seats is too low in a form">Must be more than filled seat count.</span>
  <span *ngSwitchCase="ErrorCode.ValidationStorageRequired" i18n="The validation error message when a license storage is missing in a form">Storage is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationStorageTooLarge" i18n="The validation error message when a storage is too large in a form">Must be 100TB or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationStorageTooLow" i18n="The validation error message when the max storage space is to low in a form">Must be more than used storage space.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCreditCardNumberRequired" i18n="The validation error message when a credit card number is missing in a form">Card number is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCreditCardNumberInvalid" i18n="The validation error message when a credit card number is invalid in a form">Please enter a valid card number.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCreditCardExpirationRequired" i18n="The validation error message when a credit card expiration date is missing in a form">Expiration date is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCreditCardExpirationInvalid" i18n="The validation error message when a credit card expiration date is invalid in a form">Please enter a valid expiration date.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCreditCardCodeRequired" i18n="The validation error message when a credit card code is required in a form">Card code is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCreditCardCodeInvalid" i18n="The validation error message when a credit card code is invalid in a form">Please enter a valid card code.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserFirstNameRequired" i18n="The validation error message when the user's first name is missing in a form">First name is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserFirstNameTooLong" i18n="The validation error message when the user's first name is too long in a form">Must be 40 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserFirstNameBlank" i18n="The validation error message when the user's first name is blank in a form">First name cannot be blank.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserLastNameRequired" i18n="The validation error message when the user's last name is missing in a form">Last name is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserLastNameTooLong" i18n="The validation error message when the user's last name is too long in a form">Must be 80 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserLastNameBlank" i18n="The validation error message when the user's last name is blank in a form">Last name cannot be blank.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserInitialsRequired" i18n="The validation error message when the user's initials are missing in a form">Initials are required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserInitialsTooLong" i18n="The validation error message when the user's initials are too long in a form">Enter only 2 characters.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserTitleTooLong" i18n="The validation error message when the user's title is too long in a form">Must be 128 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserLocationTooLong" i18n="The validation error message when the user's location is too long in a form">Must be 128 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserDepartmentTooLong" i18n="The validation error message when the user's department is too long in a form">Must be 128 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserSeatTypeRequired" i18n="The validation error message when the user seat type is missing in a form">User seat type is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationUserSeatTypeTooMany" i18n="The validation error message when there are not enough user seat types for the selected seat type in a form">Not enough seats available.</span>
  <span *ngSwitchCase="ErrorCode.ValidationBillingCompanyRequired" i18n="The validation error message when the company is missing in a form">Company is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationAddressRequired" i18n="The validation error message when the company is missing in a form">Address is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCityRequired" i18n="The validation error message when the city is missing in a form">City is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationPostalCodeRequired" i18n="The validation error message when the postal code is missing in a form">Postal code is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationVanityRequired" i18n="The validation error message when the vanity is missing in a form">Vanity is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationVanityInvalid" i18n="The validation error message when the vanity has invalid characters in a form">Only alphanumeric characters and hyphens allowed.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSiteVanityInvalid" i18n="The validation error message when the site vanity has invalid characters in a form">Only alphanumeric characters, periods, underscores and hyphens allowed.</span>
  <span *ngSwitchCase="ErrorCode.ValidationChecklistNameRequired" i18n="The validation error message when a checklist name is missing in a form">Name is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationChecklistNameTooLong" i18n="The validation error message when a checklist name is too long in a form">Must be 40 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationOutputAnalyticsNameRequired" i18n="The validation error message when an output analytics name is missing in a form">Name is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationOutputAnalyticsNameTooLong" i18n="The validation error message when an output analytics name is too long in a form">Must be 40 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationOutputAnalyticsDescriptionTooLong" i18n="The validation error message when an output analytics description is too long in a form">Must be 80 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSiteNameRequired" i18n="The validation error message when a site name is missing in a form">Name is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSiteNameTooLong" i18n="The validation error message when a site name is too long in a form">Must be 128 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationBuildIdRequired" i18n="The validation error message when a build id is missing in a form">Build Id is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationBuildIdInvalid" i18n="The validation error message when a build id is invalid in a form">Must be a number.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTargetIdRequired" i18n="The validation error message when a target id is missing in a form">Target Id is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTargetIdInvalid" i18n="The validation error message when a target id is invalid in a form">Must be a number.</span>
  <span *ngSwitchCase="ErrorCode.Validation360AngleTooLow" i18n="The validation error message when an angle is too low for a 0-360 degree angle">Angle must be positive.</span>
  <span *ngSwitchCase="ErrorCode.Validation360AngleTooHigh" i18n="The validation error message when an angle is too high for a 0-360 degree angle">Angle must be less than or equal to 360.</span>
  <span *ngSwitchCase="ErrorCode.ValidationImageRequired" i18n="The validation error message when an image file is missing in a form">Image is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationImageFileTypeInvalid" i18n="The validation error message when a file is not an image type">File must be an image.</span>
  <span *ngSwitchCase="ErrorCode.ValidationImageFileMissing" i18n="The validation error message when an image file cannot be found">Image does not exist.</span>
  <span *ngSwitchCase="ErrorCode.ValidationPfxRequired" i18n="The validation error message when a PFX file is missing in a form">PFX file is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationPfxFileTypeInvalid" i18n="The validation error message when a file is not a PFX type">File must be PFX.</span>
  <span *ngSwitchCase="ErrorCode.ValidationPfxFileContentInvalid" i18n="The validation error message when a PFX file's content is not valid">File content is invalid.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCsvRequired" i18n="The validation error message when a CSV file is missing in a form">CSV file is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCsvFileTypeInvalid" i18n="The validation error message when a file is not a CSV type">File must be CSV.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCsvEmpty" i18n="The validation error message when a CSV file has no items">CSV file cannot be empty.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCsvItemsInvalid" i18n="The validation error message when a CSV file has items but none are valid">CSV file must contain at least one valid entry.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCsvTooLarge" i18n="The validation error message when a CSV file is too large">File must be smaller than 15MB.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSiteRequired" i18n="The validation error message when a site is missing in a form">Site is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSiteDoesNotExist" i18n="The validation error message when no sites are available in a form">The selected teams have no access to any live private sites.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSiteThemeNameRequired" i18n="The validation error message when a site styles name is missing in a form">Name is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSiteThemeNameTooLong" i18n="The validation error message when a site styles name is too long in a form">Must be 128 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationContentSecurityPolicyNameRequired" i18n="The validation error message when content security policy name is required in a form">Name is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationContentSecurityPolicyNameTooLong" i18n="The validation error message when content security policy name is too long">Must be 128 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationContentSecurityPolicyNameBlank" i18n="The validation error message when content security policy name is just white space">Name cannot be blank.</span>
  <span *ngSwitchCase="ErrorCode.ValidationContentSecurityPolicyDirectiveInvalid" i18n="The validation error message when content security policy directive is not valid">This directive is not valid.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSiteLogoImageTooLarge" i18n="The validation error message when a site logo image is too large in a form">File must be smaller than 2MB.</span>
  <span *ngSwitchCase="ErrorCode.ValidationAvatarImageTooLarge" i18n="The validation error message when an avatar image is too large in a form">File must be smaller than 2MB.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTeamNameRequired" i18n="The validation error message when the team's name is missing in a form.">Team name is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTeamNameTooLong" i18n="The validation error message when the team's name is too long in a form.">Must be 256 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationViewerTeamRequired" i18n="The validation error message when a team for a viewer is missing in a form">At least one team is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationViewerTeamsMissingLivePrivateSite" i18n="The validation error message when a viewer has teams but none are associated with live private sites">At least one team associated with a live private site is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationNumberRequired" i18n="The validation error message when a number is missing in a form">A number is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationDaysRequired" i18n="The validation error message when a selection of days is missing in a form">Day is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationLanguagesRequired" i18n="The validation error message when a selection of a language is missing in the form">Language is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTargetNotSupported" i18n="The validation error message when a the target selected in the form is not supported">Building this target type is not supported on Flare Online.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTimeZoneRequired" i18n="The validation error message when selection of time zone is missing in a form">Time zone is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTimeRequired" i18n="The validation error message when selection of time is missing in a form">Time is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationDateRequired" i18n="The validation error message when a date is missing in a form">A date is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationDateFormat" i18n="The validation error message when a date has a bad format in a form">Date has to be in the form of mm/dd/yyyy.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTaskBoardTitleRequired" i18n="The validation error message when a task board title is required">Title is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTaskBoardTitleTooLong" i18n="The validation error message when a task board title is too long">Must be 128 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTaskBoardTitleBlank" i18n="The validation error message when a task board title empty">Title cannot be blank.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTaskBoardDescriptionTooLong" i18n="The validation error message when a task board description is too long">Must be 256 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationFilePathTooLong" i18n="The validation error message when a file path is too long in a form">Must be 1024 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationFilesRequired" i18n="The validation error message when a file is missing in a form">File is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationVariablesRequired" i18n="The validation error message when no variables set in a form">Variables are required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTranslationPackagesNameRequired" i18n="The validation error message when no name set in a translation package form">Name is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTranslationPackagesNameNotValid" i18n="The validation error message when name is not valid in a translation package form">Name contains illegal characters or spaces.</span>
  <span *ngSwitchCase="ErrorCode.ValidationTranslationPackagesNameMaxLength" i18n="The validation error message when name is too long in a translation package form">Must be 100 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationFilesTooLarge" i18n="The validation error message when the sum of the files sizes are too large in a form">Combined files size must be smaller than 15MB.</span>
  <span *ngSwitchCase="ErrorCode.ValidationFilePathInvalid" i18n="The validation error message when a file path is not valid in a form">Please enter a valid file path. The following characters are not allowed: \ : " <> | </span>
  <span *ngSwitchCase="ErrorCode.ValidationFilePathRequired" i18n="The validation error message when a file path is not valid in a form">File path is required. </span>
  <span *ngSwitchCase="ErrorCode.ValidationFilePathWhitespace" i18n="The validation error message when a file path is not valid in a form">File path cannot be just whitespace. </span>
  <span *ngSwitchCase="ErrorCode.ValidationFilePathExtension" i18n="The validation error message when a file extension is not valid in a form">This extension is not supported. Please specify a different extension. </span>
  <span *ngSwitchCase="ErrorCode.ValidationFilePathExtensionPeriod" i18n="The validation error message when a file ends with a period instead of an extension in a form">File names cannot end with a ' . ' </span>
  <span *ngSwitchCase="ErrorCode.ValidationFileNameWhitespace" i18n="The validation error message when a file path is not valid in a form">File name cannot be just whitespace. </span>
  <span *ngSwitchCase="ErrorCode.ValidationFileNameRequired" i18n="The validation error message when a file name in a file path is not valid in a form">File name is required. </span>
  <span *ngSwitchCase="ErrorCode.ValidationFilePathNotChanged" i18n="The validation error message when a file path has not been changed in a form">File path has not been changed. </span>
  <span *ngSwitchCase="ErrorCode.ValidationFolderPathTooLong" i18n="The validation error message when a folder path is too long in a form">Must be 1024 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationFolderPathInvalid" i18n="The validation error message when a folder path is not valid in a form">Please enter a valid folder path. The following characters are not allowed: \ : " <> | </span>
  <span *ngSwitchCase="ErrorCode.ValidationFolderPathRequired" i18n="The validation error message when a folder path is not valid in a form">Folder path is required. </span>
  <span *ngSwitchCase="ErrorCode.ValidationFolderNameRequired" i18n="The validation error message when a folder name in a folder path is not valid in a form">Folder name is required. </span>
  <span *ngSwitchCase="ErrorCode.ValidationFileNameSpecialChars" i18n="The validation error message when a file name is not valid in a form">One or more file paths contain invalid characters # %. </span>
  <span *ngSwitchCase="ErrorCode.ValidationFileTypeRequired" i18n="The validation error message when a file type is not valid in a form">File type is required. </span>
  <span *ngSwitchCase="ErrorCode.ValidationFileTemplateRequired" i18n="The validation error message when a file template is not valid in a form">File template is required. </span>
  <span *ngSwitchCase="ErrorCode.ValidationCommitMessageRequired" i18n="The validation error message when a commit message is missing in a form">Commit message is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationCommitMessageTooLong" i18n="The validation error message when a commit message is too long in a form">Must be 2048 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSamlEndpointRequired" i18n="The validation error message when the SAML endpoint is missing in a form">Endpoint is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSamlIdpIssuerRequired" i18n="The validation error message when the SAML IDP issuer is missing in a form">Identity provider issuer is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSamlPublicCertRequired" i18n="The validation error message when the SAML public certificate is missing in a form">Public certificate is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationReviewPackageNameRequired" i18n="The validation error message when a review package name is missing in a form">Name is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationReviewPackageNameTooLong" i18n="The validation error message when a review package name is too long in a form">Must be 128 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationReviewPackageDescriptionTooLong" i18n="The validation error message when a review package name is too long in a form">Must be 256 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationProjectRequired" i18n="The error message when a project is missing in a form">Project is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationBranchRequired" i18n="The error message when a branch is missing in a form">Branch is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationMultimediaLinkRequired" i18n="The error message when a link for multimedia is missing in a form"> Link to video is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationSupportedExternalMultimediaLink" i18n="The error message when a link for multimedia is not either YouTube or Vimeo"> Only YouTube or Vimeo video links are supported.</span>

  <span *ngSwitchCase="ErrorCode.UnknownError" i18n="The error message for an unexpected error">An unexpected error occurred.</span>
  <span *ngSwitchCase="ErrorCode.IdentityError" i18n="The Microsoft error message for an unexpected identity error">An identity error occurred.</span>
  <span *ngSwitchCase="ErrorCode.ModelInvalidError" i18n="The error message when a server request has invalid input">The request sent to the server included invalid data.</span>
  <span *ngSwitchCase="ErrorCode.UserNonSSOAdminLoginError" i18n="The error message when a non-admin user tries to log in as an admin on an SSO license">You do not have admin permissions on this license.</span>
  <span *ngSwitchCase="ErrorCode.TooManyRequestsError" i18n="The error message when the user has tried a request too many times.">Maximum attempts reached, please try again in a few minutes.</span>
  <span *ngSwitchCase="ErrorCode.CreateError" i18n="The error message when the DB couldn't create an entity">Error occurred when creating item.</span>
  <span *ngSwitchCase="ErrorCode.UserAddPasswordError" i18n="The error message when setting the user's password for the first time fails">There was a problem setting your password.</span>
  <span *ngSwitchCase="ErrorCode.UserConfirmEmailError" i18n="The error message when a user's email cannot be confirmed">Your email could not be confirmed.</span>
  <span *ngSwitchCase="ErrorCode.UserTokenError" i18n="The error message when a user token cannot be verified">The user token is invalid.</span>
  <span *ngSwitchCase="ErrorCode.UserChangePasswordError" i18n="The error message when changing the user's password fails">There was a problem updating your password.</span>
  <span *ngSwitchCase="ErrorCode.UserPasswordSetError" i18n="The error message when a user's password has already been set">Your password has already been set.</span>
  <span *ngSwitchCase="ErrorCode.UserLoginError" i18n="The error message when user cannot login for an expected reason">Login failed.</span>
  <span *ngSwitchCase="ErrorCode.UserUnauthorizedError" i18n="The error message when a user is not authorized to make a server request">You are not authorized to do this action.</span>
  <span *ngSwitchCase="ErrorCode.UserOAuthNotRegisteredError" i18n="The error message when a user is not registered">User is not registered in the system.</span>
  <span *ngSwitchCase="ErrorCode.UserOAuthSecretNotSentError" i18n="The error message when a client secret is not sent">The client secret was not sent.</span>
  <span *ngSwitchCase="ErrorCode.UserOAuthSecretInvalidError" i18n="The error message when a client secret is not valid">The client secret is invalid.</span>
  <span *ngSwitchCase="ErrorCode.UserOAuthInactiveError" i18n="The error message when a client is inactive">Your client is inactive</span>
  <span *ngSwitchCase="ErrorCode.AuthMissingRole" i18n="The error message when a user does not have the required roles to login.">You do not have the required roles to login. <br />Please contact your system administrator to be given access.</span>
  <span *ngSwitchCase="ErrorCode.UserNotFoundError" i18n="The error message when a user is not found">Your account was not found.</span>
  <span *ngSwitchCase="ErrorCode.UserIncorrectCredentialsError" i18n="The error message when a user's login credentials are incorrect">Your email address or password is incorrect.</span>
  <span *ngSwitchCase="ErrorCode.UserExpiredPasswordError" i18n="The error message when a user's password has expired">Your password has expired.</span>
  <span *ngSwitchCase="ErrorCode.UserEmailIsPasswordError" i18n="The error message when a user's password is the same as their email">The new password cannot be your email.</span>
  <span *ngSwitchCase="ErrorCode.UserLockedOutError" i18n="The error message when a user is locked out after too many failed login attempts">You have exceeded the maximum number of incorrect login attempts and are locked out for five (5) minutes.</span>
  <span *ngSwitchCase="ErrorCode.UserDeactivatedError" i18n="The error message when a user is not active">Your account is deactivated.</span>
  <span *ngSwitchCase="ErrorCode.UserNotConfirmedError" i18n="The error message when a user is not confirmed">Your account has not been confirmed.</span>
  <span *ngSwitchCase="ErrorCode.UserUpdatePasswordError" i18n="The error message when a password update fails">Your password could not be updated.</span>
  <span *ngSwitchCase="ErrorCode.UserClaimNotFoundError" i18n="The error message when a user claim doesn't exist">The user claim was not found.</span>
  <span *ngSwitchCase="ErrorCode.UserCreatedError" i18n="The error message when an error occurs when creating a user">The user could not be created.</span>
  <span *ngSwitchCase="ErrorCode.UserLatestViewedVersionError" i18n="The error message when accessing the user's last viewed version number of Flare Online">The version number could not be found.</span>
  <span *ngSwitchCase="ErrorCode.UserBulkUploadError" i18n="The error message when there is an error in the bulk user upload csv file">Ensure that A1, B1, and C1 column headers are the following values in order: Email, FirstName, LastName. Rows with missing values will be skipped entirely.</span>
  <span *ngSwitchCase="ErrorCode.UserBulkSeatError" i18n="The error message when there are not enough seats available in a bulk user upload">Users could not be added. Not enough seats available.</span>
  <span *ngSwitchCase="ErrorCode.UserBulkCSVMalformedError" i18n="The error message when there are too many users in a bulk user upload csv file">Must be 1,000 users or less.</span>
  <span *ngSwitchCase="ErrorCode.UserOldPasswordHistoryError" i18n="The error message when a user enters an old password">The new password cannot be a previously used password.</span>
  <span *ngSwitchCase="ErrorCode.LicenseExpiredError" i18n="The error message when a license is expired">The license is expired.</span>
  <span *ngSwitchCase="ErrorCode.LicenseInvalidTeamError" i18n="The error message when a team license is invalid">The license for this team is invalid.</span>
  <span *ngSwitchCase="ErrorCode.LicensePurchaseInProgressError" i18n="The error message when a license purchase is in progress">The license purchase is still being processed.</span>
  <span *ngSwitchCase="ErrorCode.LicenseProjectNotFoundError" i18n="The error message when a project is not found for a license">The project was not found.</span>
  <span *ngSwitchCase="ErrorCode.LicenseTeamNotFoundError" i18n="The error message when a team is not found for a license">The team was not found.</span>
  <span *ngSwitchCase="ErrorCode.LicenseNotFoundError" i18n="The error message when a license is not found">The license was not found.</span>
  <span *ngSwitchCase="ErrorCode.LicenseUserAlreadyExistsError" i18n="The validation error message when an email address already exists on a user">A user with this email already exists.</span>
  <span *ngSwitchCase="ErrorCode.LicenseUserInvalidEmailError" i18n="The validation error message when an email address is invalid">Please enter a valid email.</span>
  <span *ngSwitchCase="ErrorCode.ValidationLicenseNameRequired" i18n="The validation error message when the license name is missing in a form">License name is required.</span>
  <span *ngSwitchCase="ErrorCode.ValidationLicenseNameTooLong" i18n="The validation error message when the license name is too long in a form">Must be 100 characters or less.</span>
  <span *ngSwitchCase="ErrorCode.ValidationLicenseNameBlank" i18n="The validation error message when the license name is blank in a form">License name cannot be blank.</span>
  <span *ngSwitchCase="ErrorCode.ValidationLicenseDefaultTeamRequired" i18n="The validation error message when the license default teams is missing in a form">Default teams are required when creating users.</span>
  <span *ngSwitchCase="ErrorCode.ProjectNotActiveError" i18n="The error message when a project is not active">The project is not active.</span>
  <span *ngSwitchCase="ErrorCode.ProjectMultipleLicenseError" i18n="The error message when multiple users are added to multiple licenses in bulk">Users can only be added in bulk to one license at a time.</span>
  <span *ngSwitchCase="ErrorCode.ProjectFileNotFoundError" i18n="The error message when a project file is not found">The project file was not found.</span>
  <span *ngSwitchCase="ErrorCode.ProjectNotDeletedError" i18n="The error message when a project deletion fails">The project could not be deleted.</span>
  <span *ngSwitchCase="ErrorCode.ProjectNotDeletedFromSourceControlError" i18n="The error message when a project cannot be deleted from source control">The project could not be removed from source control.</span>
  <span *ngSwitchCase="ErrorCode.ProjectNotAuthorizedError" i18n="The error message when a user does not have specific authorization for a project">You do not have authorization to make this change for this project. </span>
  <span *ngSwitchCase="ErrorCode.ReviewNotFoundError" i18n="The error message when a review file is not found">The review file was not found.</span>
  <span *ngSwitchCase="ErrorCode.ReviewProjectNotFoundError" i18n="The error message when a review file's project is not found">The review's project was not found.</span>
  <span *ngSwitchCase="ErrorCode.ReviewPathNotFoundError" i18n="The error message when a review's file is not found in source control">The review's file was not found in source control.</span>
  <span *ngSwitchCase="ErrorCode.AnalyzerProjectTooLargeError" i18n="The error message when a project is too large to be analyzed">This feature is currently limited to projects smaller than 1GB.</span>
  <span *ngSwitchCase="ErrorCode.AnalyzerScanCodeError" i18n="The error message when an anazlyer scan fails">The scan failed.</span>
  <span *ngSwitchCase="ErrorCode.SiteNameRequiredError" i18n="The error message when the site name is not specified">The site name is required.</span>
  <span *ngSwitchCase="ErrorCode.SiteNameExistsError" i18n="The error message when a site name already exists">The site name already exists.</span>
  <span *ngSwitchCase="ErrorCode.SiteRequiredError" i18n="The error message when a site id is not specified">The site id is required.</span>
  <span *ngSwitchCase="ErrorCode.SiteNotFound" i18n="The error message when a site is not found">The site was not found.</span>
  <span *ngSwitchCase="ErrorCode.SiteStyleMisformattedError" i18n="The error message when a server request for saving site styles has invalid input">The request sent to the server included invalid theme data.</span>
  <span *ngSwitchCase="ErrorCode.SiteStyleNotFoundError" i18n="The error message when the site styles are not found">The site theme was not found.</span>
  <span *ngSwitchCase="ErrorCode.TeamMisformattedError" i18n="The error message when a server request for a team has invalid input">The request sent to the server included invalid team data.</span>
  <span *ngSwitchCase="ErrorCode.TeamNameRequiredError" i18n="The error message when the team name is not specified">The team name is required.</span>
  <span *ngSwitchCase="ErrorCode.TeamNameExistsError" i18n="The error message when a team name already exists">The team name already exists.</span>
  <span *ngSwitchCase="ErrorCode.TeamMultipleLicenseError" i18n="The error message when multiple users are added to multiple teams in different licenses in bulk">Users can only be added in bulk to multiple teams in one license at a time.</span>
  <span *ngSwitchCase="ErrorCode.TeamCreatedError" i18n="The error message when a team cannot be created">The team could not be created.</span>
  <span *ngSwitchCase="ErrorCode.TeamNotFoundError" i18n="The error message when a team is not found">The team was not found.</span>
  <span *ngSwitchCase="ErrorCode.GitFileContentNotProvidedError" i18n="The error message when a server request for a Git file has invalid input">The request sent to the server included invalid Git file data.</span>
  <span *ngSwitchCase="ErrorCode.GitFileIdNotProvidedError" i18n="The error message when a Git file id is not specified">The Git file id is required.</span>
  <span *ngSwitchCase="ErrorCode.GitFilePathNotProvidedError" i18n="The error message when a Git file path is not specified">The Git file path is required.</span>
  <span *ngSwitchCase="ErrorCode.GitUserNotFoundError" i18n="The error message when a user's Git account is not found">The Git user was not found.</span>
  <span *ngSwitchCase="ErrorCode.GitKeyExistsError" i18n="The error message when an SSH key already exists for the user">The SSH key already exists.</span>
  <span *ngSwitchCase="ErrorCode.GitUserTokenError" i18n="The error message when the user's git token cannot be retrieved">The Git user token could not be retrieved.</span>
  <span *ngSwitchCase="ErrorCode.GitUserUpdateError" i18n="The error message when a Git user update fails">The Git user update failed.</span>
  <span *ngSwitchCase="ErrorCode.GitPostError" i18n="The error message when a Git POST call fails">The Git POST call failed.</span>
  <span *ngSwitchCase="ErrorCode.GitGetError" i18n="The error message when a Git GET call fails">The Git GET call failed.</span>
  <span *ngSwitchCase="ErrorCode.GitPutError" i18n="The error message when a Git PUT call fails">The Git PUT call failed.</span>
  <span *ngSwitchCase="ErrorCode.GitDeleteError" i18n="The error message when Git DELETE call fails">The Git DELETE call failed.</span>
  <span *ngSwitchCase="ErrorCode.CollabTypeNotValidError" i18n="The error message when an invalid editor file type is requested">An invalid file type was requested.</span>
  <span *ngSwitchCase="ErrorCode.OutputAnalyticsSearchDeleteError" i18n="The error message when a analytics search cannot be deleted">The analytics search could not be deleted.</span>
  <span *ngSwitchCase="ErrorCode.OutputAnalyticsLogTopicViewError" i18n="The error message when logging a topic view failed">Analytics logging of a topic view failed.</span>
  <span *ngSwitchCase="ErrorCode.OutputAnalyticsLogSearchError" i18n="The error message when logging a search failed">Analytics logging of a search failed.</span>
  <span *ngSwitchCase="ErrorCode.OutputAnalyticsExcludedIPsError" i18n="The error message when creating an excluded IP failed">Creating an exlucded IP failed.</span>
  <span *ngSwitchCase="ErrorCode.OutputAnalyticsKeyError" i18n="The error message when an analytics key update failed">Analytics key could not be updated.</span>
  <span *ngSwitchCase="ErrorCode.AuditSendError" i18n="The error message when an audit event failed">Notification error.</span>
  <span *ngSwitchCase="ErrorCode.BuildCodeError" i18n="The error message when a build fails">The build failed.</span>
  <span *ngSwitchCase="ErrorCode.VanityUrlDoesNotExistError" i18n="The error message when a vanity does not exist">The vanity does not exist.</span>
  <span *ngSwitchCase="ErrorCode.ContentSecurityPolicyNotUniqueError" i18n="The error message when a content security policy name already exists">The content security policy name already exists.</span>

  <span *ngIf="showErrorCode && (hasErrorCode$ | async)"> ({{ errorCode }})</span>
</ng-container>