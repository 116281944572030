import { FocusMonitor } from '@angular/cdk/a11y';
import { BACKSPACE, ENTER } from '@angular/cdk/keycodes';
import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, HostBinding, Input, NgZone, OnDestroy, Optional, QueryList, Self, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm, UntypedFormControl } from '@angular/forms';
import { LegacyErrorStateMatcher as ErrorStateMatcher } from '@angular/material/legacy-core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { AutocompleteItemDirective } from '@portal-core/ui/autocomplete/directives/autocomplete-item/autocomplete-item.directive';
import { CustomInputBase } from '@portal-core/ui/forms/util/custom-input-base.directive';
import { ListOptionComponent } from '@portal-core/ui/list/components/list-option/list-option.component';
import { SelectListBase } from '@portal-core/ui/list/util/select-list-base';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { PopupTriggerDirective } from '@portal-core/ui/popup/directives/popup-trigger/popup-trigger.directive';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { isEqual } from 'lodash';
import { Observable, Subscription, debounceTime, first, switchMap } from 'rxjs';

/**
 * AutocompleteInputComponent supports MatFormField and reactive forms.
 * When using an autocomplete input it must contain an mcAutocompleteList that implements SelectListBase or be passed a SelectListBase with the list @Input.
 * Optionally (but almost always) provide a template for rendering selected items using mcAutocompleteItem.
 * ```html
 * <mat-form-field>
 *   <mc-autocomplete-input>
 *     <mc-projects-select-list mcAutocompleteList [licenseId]="licenseId"></mc-projects-select-list>
 *     <ng-template mcAutocompleteItem let-projectId="item">
 *       <mc-project-avatar compact [disabled]="true" [project]="getProjectById$(projectId) | async" size="small" [truncate]="true"></mc-project-avatar>
 *     </ng-template>
 *   </mc-autocomplete-input>
 * </mat-form-field>
 * ```
 */
@Component({
  selector: 'mc-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[class.mc-autocomplete-input-disabled]': 'disabled',
  },
  // tslint:disable-next-line: no-inputs-metadata-property
  inputs: ['disabled', 'tabIndex'],
  providers: [
    { provide: MatFormFieldControl, useExisting: AutocompleteInputComponent }
  ]
})
export class AutocompleteInputComponent extends CustomInputBase<(number | string)[]> implements OnDestroy, AfterContentInit, AfterViewInit {
  /** Whether or not avatars are being used to display the selected items. If so then the input is rendered at a larger height to accommodate the avatars. */
  @HostBinding('class.mc-autocomplete-input-avatar-items')
  @Input() hasAvatarItems: boolean = true;
  /** Optionally specify an external list to use instead of specifying an autocomplete component in the content. */
  @Input() list?: SelectListBase<any>;
  /** Whether or not multiple items can be selected. Defaults to true. */
  @Input() multiple?: boolean = true;
  /** The height of the popup in pixels. */
  @Input() popupHeight?: number | 'width' = 200;
  /** Whether or not the popup should be opened when the autocomplete element gains focus. Defaults to true. */
  @Input() triggerPopupOnFocus: boolean = true;
  /** Whether or not the popup should be closed after each selection when multiple items are allowed. */
  @Input() closePopupOnSelection?: boolean = false;


  /** A reference to the selected item DOM elements. */
  @ViewChildren('autocompleteInputItem') inputItemsQueryList: QueryList<ElementRef<HTMLElement>>;
  /** A reference to the popup trigger directive. */
  @ViewChild('popupTrigger', { static: false, read: PopupTriggerDirective }) popupTrigger: PopupTriggerDirective;
  /** A reference to the text input field's DOM element. */
  @ViewChild('textInput', { static: false }) textInputElementRef: ElementRef<HTMLInputElement>;
  /** The SelectListBase component provided in ng-content. */
  @ContentChild(SelectListBase) contentList: SelectListBase<any>;
  /** The mcAutocompleteItem template provided in ng-content. */
  @ContentChild(AutocompleteItemDirective) autocompleteItemDirective: AutocompleteItemDirective;

  /** An observable for the list input. */
  @InputObservable('list') list$: Observable<SelectListBase<any>>;

  /** Required by CustomInputBase */
  controlType: string = 'mc-autocomplete-input';
  /** The disabled subscription for auto unsubscribing. */
  private disabledSubscription: Subscription;
  /** The selection change subscription for auto unsubscribing. */
  private selectionChangeSubscription: Subscription;
  /** The tab out subscription for auto unsubscribing. */
  private tabOutSubscription: Subscription;
  /** The text change subscription for auto unsubscribing. */
  private textChangeSubscription: Subscription;
  /** Binds to the input field to observe value changes and to set the value. */
  textControl: UntypedFormControl = new UntypedFormControl();

  /** Returns the id of the select list's active item's DOM element. Used for a11y. */
  get activeListOptionId(): string {
    return this.selectList?.keyManager?.activeItem?.id;
  }

  /** A reference to the collection service used by the autocomplete's list. */
  get collectionService(): CollectionServiceBase<any> {
    return this.list?.collectionService ?? this.contentList?.collectionService;
  }

  /** Returns true if the autocomplete input's value is empty. It is considered empty if the value is not an array or is an empty array. */
  get empty(): boolean {
    return !Array.isArray(this.value) || this.value.length === 0;
  }

  /** A reference to the SelectListBase provided by mcAutocompleteList or the list @Input. */
  get selectList(): SelectListBase<any> {
    return this.list ?? this.contentList;
  }

  /** Required by MatFormFieldControl */
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty || this.textControl.value || this.popupTrigger?.opened;
  }

  /** Returns the id of the select list's DOM element. Used for a11y. */
  get listId(): string {
    return this.selectList?.listId;
  }

  constructor(
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
    cdr: ChangeDetectorRef,
    focusMonitor: FocusMonitor,
    private pageFilterService: PageFilterService,
    private ngZone: NgZone
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, cdr, focusMonitor);
  }

  /** Subscribes to events from the autocomplete control's popup list. */
  ngAfterContentInit() {
    // Listen for changes to the state of the list and update the disabled state of the input
    this.disabledSubscription = this.stateChanges.subscribe(() => {
      if (this.disabled) {
        this.textControl.disable();
      } else {
        this.textControl.enable();
      }
    });

    // Listen for changes to the text control's value and apply it as a filter for the list
    this.textChangeSubscription = this.textControl.valueChanges.pipe(
      debounceTime(400)
    ).subscribe(textValue => {
      if (this.selectList) {
        this.selectList.applyFilter$('ac-search', this.pageFilterService.create({
          Id: 'ac-search',
          Type: PageFilterGroupType.Search
        }).search(this.selectList.filterConfig.stringFilterNames, textValue, this.selectList.filterConfig.stringFilters.map(column => {
          return {
            PropertyName: column.filterName,
            PropertyType: column.filterDataType ?? column.type
          };
        })).value);
      }
    });

    if (this.contentList) {
      // Listen to selection changes from the popup list and add them to the selected item list
      this.selectionChangeSubscription = this.contentList.selectionChange$.subscribe((options: ListOptionComponent[]) => {
        this.onSelectionChanged(options);
      });

      // Listen for tab out events from the popup list and close the popup
      this.tabOutSubscription = this.contentList.tabOut.subscribe(() => {
        this.onTabOut();
      });
    }

    // If there are selected items on initialization then make sure the data for them is loaded
    this.maybeBulkLoadItems();
  }

  /** Subscribes to events from the autocomplete control's external list. */
  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (!this.contentList) {
      // Listen to selection changes from the popup list and add them to the selected item list
      this.selectionChangeSubscription = this.list$.pipe(
        switchMap(selectList => selectList.selectionChange$)
      ).subscribe((options: ListOptionComponent[]) => {
        this.onSelectionChanged(options);
      });

      // Listen for tab out events from the popup list and close the popup
      this.tabOutSubscription = this.list$.pipe(
        switchMap(selectList => selectList.tabOut)
      ).subscribe(() => {
        this.onTabOut();
      });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.disabledSubscription?.unsubscribe();
    this.selectionChangeSubscription?.unsubscribe();
    this.tabOutSubscription?.unsubscribe();
    this.textChangeSubscription?.unsubscribe();
  }

  /** Handles blur events on the input element. */
  onInputBlur() {
    // When a popup is being used for the list its unnecessary to clear the active item because the list gets cleared.
    // However, when the list is external to the autocomplete input, the active item needs to be cleared so that the list no longer shows an active item when the autocomplete doesn't have focus
    this.selectList?.clearKeyManagerActiveItem();
  }

  /** Handles selection change events from the list. */
  onSelectionChanged(options: ListOptionComponent[]) {
    if (options?.length > 0) {

      if (this.multiple) {
        const optionId = options[0].value;
        const itemToRemove = this.value?.find(valueOption => valueOption === optionId);
        if (this.closePopupOnSelection) {
          this.popupTrigger?.close();
        }
        if (itemToRemove) {
          this.removeItem(itemToRemove);
        } else {
          this.addItems(options.map(option => option.value))
        }
      } else {
        // Clear the search filter now. This is necessary because when the popup is subsequently closed the list becomes detached and is unavailable when the text change subscription runs.
        // Its unavailable because the debounceTime delays when the filter gets applied.
        this.selectList.applyFilter$('ac-search', null);
        this.popupTrigger?.close();
        this.addItems(options.map(option => option.value));
      }
      this.textControl.setValue('');
      this.selectList?.setKeyManagerActiveItem(options?.[0]);
      this.focus();
      this.cdr.markForCheck();
    }
  }

  /** Handles tab out events from the list key manager. */
  onTabOut() {
    this.popupTrigger?.close();
  }

  /** Handles click events on the selected items by removing them. */
  onItemClicked(item: number | string) {
    if (this.disabled) {
      return;
    }

    this.removeItem(item);
  }

  /** Handles the backspace key down event to remove the item and move the focus. */
  onItemKeyDown(item: number | string, event: KeyboardEvent) {
    if (event.keyCode === BACKSPACE) {
      const index = this.indexOfItem(item);

      this.removeItem(item);

      // After removing the item focus the next item or the input field if no items are left
      if (this.value?.length > 0) {
        // Wait for the DOM to be updated before focusing the next item
        setTimeout(() => {
          this.focusItemByIndex(index >= this.value.length ? this.value.length - 1 : index);
        }, 0);
      } else {
        this.focus();
      }
    }
  }

  /** Used to resize the list when the popup is open and fully ready. */
  onPopupOpened() {
    // Check the viewport size of the list once the popup opens.
    // This ensures the list will be the correct size when it is shown which is necessary for the infinite list to request the correct number of items from the server.
    this.ngZone.onStable.asObservable().pipe(
      first()
    ).subscribe(() => {
      this.ngZone.run(() => {
        this.selectList?.list?.checkViewportSize();
        this.cdr.markForCheck();
      });
    });
  }

  /** Forwards keydown events from the input text field to the autocomplete list for navigation purposes. */
  onInputKeyDown(event: KeyboardEvent) {
    // Forward keydown events to the list's key manager so that it can handle the list navigation
    if (this.selectList) {
      // If enter is pressed AND there is an active item in the popup list or external list THEN select that item
      if (event.keyCode === ENTER && this.selectList.keyManager.activeItem && (this.popupTrigger.opened || this.list)) {
        this.selectList.clickKeyManagerActiveItem();
        event.preventDefault();
        // Else if backspace is pressed in an empty text field and there are selected items then give focus to the last item
      } else if (event.keyCode === BACKSPACE && !this.textInputElementRef.nativeElement.value && this.value?.length > 0) {
        this.focusLastItem();
        event.preventDefault();
      } else {
        // Otherwise forward the event to the list
        this.selectList.keyManager.onKeydown(event);
      }
    }
  }

  /** Clears the selected values and search input field in the autocomplete control. */
  clear() {
    this.clearSearch();
    this.value = null;
  }

  /** Clears the search input field in the autocomplete control. */
  clearSearch() {
    this.textControl.setValue('');
    this.selectList.applyFilter$('ac-search', null);
  }

  /** Closes the popup list. */
  close() {
    this.popupTrigger?.close();
  }

  /** Gives focus to the autocomplete control. */
  focus() {
    this.textInputElementRef.nativeElement.focus();
  }

  /** Gives focus to the item at the given index. */
  focusItemByIndex(index: number) {
    if (index >= 0 && index < this.inputItemsQueryList.length) {
      this.inputItemsQueryList.toArray()[index].nativeElement.focus();
    }
  }

  /** Gives focus to the last item. */
  focusLastItem() {
    if (Array.isArray(this.value)) {
      this.focusItemByIndex(this.value.length - 1);
    }
  }

  /** Returns the index of the item. */
  indexOfItem(item: number | string): number {
    return this.value?.indexOf(item);
  }

  /** Opens the popup list. */
  open() {
    if (!this.disabled) {
      this.popupTrigger?.open();
    }
  }

  /**
   * Adds the item to the selected values.
   * @param item The item to add to the selected values.
   */
  addItem(item: number | string) {
    this.addItems([item]);
  }

  /**
   * Adds the items to the selected values.
   * @param items The items to add to the selected values.
   */
  addItems(items: (number | string)[]) {
    let value: (number | string)[] = this.value ?? [];

    // If configured to for a single-value
    if (!this.multiple) {
      // Clear out any existing items before adding the new item
      value = [];

      // Limit the new items to one item
      if (items.length > 1) {
        items = items.slice(0, 1);
      }
    }

    value.push(...items);
    this.value = value;
    this.onChange(this.value); // So Angular Forms know this control's value has changed
    this.onTouched(); // So Angular Forms know this control has been touched
  }

  /**
   * Removes an item from the selected values.
   * @param item The item to remove from the selected values.
   */
  removeItem(item: number | string) {
    const index = this.value?.indexOf(item);

    // Only change this.value and fire the events if something is going to be removed
    if (index >= 0) {
      this.value = this.value.filter(itemA => itemA !== item);
      this.onChange(this.value); // So Angular Forms know this control's value has changed
      this.onTouched(); // So Angular Forms know this control has been touched

      // Update the popup's position since the form-field's size may have changed when the item was removed
      if (this.popupTrigger?.opened) {
        this.ngZone.onStable.asObservable().pipe(
          first()
        ).subscribe(() => {
          this.popupTrigger?.updatePosition();
        });
      }
    }
  }

  /** Bulk loads the items that are selected. This is primarily used to load the data when the autocomplete value is set from outside the autocomplete control. */
  private maybeBulkLoadItems() {
    this.collectionService?.loadItems$(this.value).subscribe();
  }

  /** Returns whether two values are equal. */
  valuesAreEqual(valueA: (number | string)[], valueB: (number | string)[]): boolean {
    return isEqual(valueA, valueB);
  }

  /** Required by CustomInputBase */
  getDefaultPlaceholder(): string {
    return '';
  }

  /** Required by CustomInputBase */
  getFocusableElementRef(): ElementRef<HTMLElement> {
    return this.textInputElementRef;
  }

  /** Required by MatFormFieldControl */
  onContainerClick(event: MouseEvent): void {
    this.focus();
    this.open();
  }
}
