<div class="mc-vertical-split-dialog">
  <button mat-icon-button color="secondary-invert" class="mc-vertical-split-dialog-close-button" tabindex="-1" [matDialogClose]="false">
    <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
  </button>

  <a matDialogTitle href="https://www.madcapsoftware.com/videos/central" target="_blank" class="mc-app-getting-started-dialog-hero-link">
    <img alt="Help Videos" src="assets/images/getting-started.png">
  </a>

  <mat-dialog-content>
    <h2 class="mc-vertical-split-dialog-title mc-app-getting-started-dialog-title">
      Need help getting started?
    </h2>
    <p class="mc-vertical-split-dialog-title">
      <a href="https://www.madcapsoftware.com/videos/central" target="_blank">Watch the Videos</a>
    </p>
    <div class="mc-vertical-split-dialog-body">
      <mat-checkbox #disableGettingStartedMessageCheckbox color="accent-vivid" class="mc-checkbox-wrap">
        Don't display this message again. Links to videos and other resources can also be found in the
        <a href="https://documentation.madcapsoftware.com/central/Default.htm#cshid=Getting_Started_Dialog" target="_blank">help</a>.
      </mat-checkbox>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mc-dialog-actions-align-center">
    <button mat-raised-button color="primary" matDialogClose>OK</button>
  </mat-dialog-actions>
</div>