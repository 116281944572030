<form class="mc-spring-board">
  <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="onTabChange($event)" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
    <mat-tab label="Users">
      <div class="mc-spring-board">
        <div class="mc-spring-board-header">
          <button #popupTriggerUsers *ngIf="(canEdit$ | async)" type="button" color="primary" [disabled]="savingState.loading$ | async" mat-raised-button [mcPopupTriggerAutoClose]="false" [mcPopupTriggerFor]="addTeamUsersPopup" [mcPopupWidth]="506">
            <div class="mc-d-flex mc-align-items-center">
              <ng-container *ngIf="savingState.loading$ | async; else addTeamUsers">Adding Team Users</ng-container>
              <ng-template #addTeamUsers>Add Team Users</ng-template>
              <span class="mc-fill-remaining-flex-space"></span>
              <mc-dropdown-arrow class="mc-team-access-form-dropdown-arrow"></mc-dropdown-arrow>
            </div>
          </button>

          <mat-form-field color="secondary" appearance="standard" class="mc-form-field">
            <mat-label>Filter Team Users</mat-label>
            <div class="mc-team-access-form-access-team-entity">
              <input #filterTeamUsers matInput placeholder="Name, Email" name="filterTeamUser" (keyup)="onFilterTeamUsersKeyUp($event)">
              <mc-filter-picker-toggle color="secondary" [for]="filterPickerPopupUsers"></mc-filter-picker-toggle>
              <mc-filter-picker-popup #filterPickerPopupUsers color="secondary" [filterable]="teamLicenseUsersListComponent"></mc-filter-picker-popup>
            </div>
          </mat-form-field>
          <mc-filter-bar display="list" [filterable]="teamLicenseUsersList"></mc-filter-bar>
        </div>
        <div class="mc-spring-board-form-body">
          <mc-team-license-users-list #teamLicenseUsersList [teamId]="team?.Id" [canEdit]="(canEdit$ | async)" (saved)="onSaved()"></mc-team-license-users-list>
        </div>
      </div>

      <mc-popup #addTeamUsersPopup (closed)="onTeamUsersPopupClosed()">
        <div class="mc-spring-board">
          <div class="mc-spring-board-form-body">
            <mat-form-field color="secondary" appearance="standard" class="mc-form-field">
              <mat-label>Add Team Users</mat-label>
              <mc-autocomplete-input #teamLicenseUsersAutoComplete [multiple]="true" [closePopupOnSelection]="true" [triggerPopupOnFocus]="false" placeholder="Type to find users">
                <mc-license-users-select-list mcAutocompleteList [licenseId]="team.LicenseId" [showSeatType]="true"></mc-license-users-select-list>
                <ng-template mcAutocompleteItem let-licenseUserId="item">
                  <mc-license-user-avatar compact [disabled]="true" [licenseUser]="licenseUserId | mcLicenseUserAsync | async" size="small" [truncate]="true"></mc-license-user-avatar>
                </ng-template>
              </mc-autocomplete-input>
            </mat-form-field>
          </div>
          <div class="mc-spring-board-form-footer">
            <div class="mc-form-buttons">
              <button type="button" mat-stroked-button color="warn" (click)="onCancelTeamUsersClicked()">Cancel</button>
              <button type="button" mat-raised-button color="primary" (click)="onAddTeamUsersClicked()">Add Users to Team</button>
            </div>
          </div>
        </div>
      </mc-popup>
    </mat-tab>

    <mat-tab label="Projects">
      <div class="mc-spring-board">
        <div class="mc-spring-board-header">
          <button #popupTriggerProjects *ngIf="(canEdit$ | async)" type="button" color="primary" [disabled]="savingState.loading$ | async" mat-raised-button [mcPopupTriggerAutoClose]="false" [mcPopupTriggerFor]="addTeamProjectsPopup" [mcPopupWidth]="506">
            <div class="mc-d-flex mc-align-items-center">
              <ng-container *ngIf="savingState.loading$ | async; else addTeamProjects">Adding Team Projects</ng-container>
              <ng-template #addTeamProjects>Add Team Projects</ng-template>
              <span class="mc-fill-remaining-flex-space"></span>
              <mc-dropdown-arrow class="mc-team-access-form-dropdown-arrow"></mc-dropdown-arrow>
            </div>
          </button>

          <mat-form-field color="secondary" appearance="standard" class="mc-form-field">
            <mat-label>Filter Team Projects</mat-label>
            <div class="mc-team-access-form-access-team-entity">
              <input #filterTeamProjects matInput placeholder="Name" name="filterTeamProject" (keyup)="onFilterTeamProjectsKeyUp($event)">
            </div>
          </mat-form-field>
          <mc-filter-bar display="list" [filterable]="teamProjectsList"></mc-filter-bar>
        </div>
        <div class="mc-spring-board-form-body">
          <mc-team-projects-list #teamProjectsList [teamId]="team?.Id" [canEdit]="(canEdit$ | async)" (saved)="onSaved()"></mc-team-projects-list>
        </div>
      </div>

      <mc-popup #addTeamProjectsPopup (closed)="onTeamProjectsPopupClosed()">
        <div class="mc-spring-board">
          <div class="mc-spring-board-form-body">
            <mat-form-field color="secondary" appearance="standard" class="mc-form-field">
              <mat-label>Add Team Projects</mat-label>
              <mc-autocomplete-input #teamProjectsAutoComplete [multiple]="true" [closePopupOnSelection]="true" [triggerPopupOnFocus]="false" placeholder="Type to find projects">
                <mc-projects-select-list mcAutocompleteList [licenseId]="team.LicenseId" [projectStatuses]="projectStatuses"></mc-projects-select-list>
                <ng-template mcAutocompleteItem let-projectId="item">
                  <mc-project-avatar compact [disabled]="true" [project]="projectId | mcProjectAsync | async" size="small" [truncate]="true"></mc-project-avatar>
                </ng-template>
              </mc-autocomplete-input>
            </mat-form-field>
          </div>
          <div class="mc-spring-board-form-footer">
            <div class="mc-form-buttons">
              <button type="button" mat-stroked-button color="warn" (click)="onCancelTeamProjectsClicked()">Cancel</button>
              <button type="button" mat-raised-button color="primary" (click)="onAddTeamProjectsClicked()">Add Projects to Team</button>
            </div>
          </div>
        </div>
      </mc-popup>
    </mat-tab>

    <mat-tab label="Sites">
      <div class="mc-spring-board">
        <div class="mc-spring-board-header">
          <button #popupTriggerSites *ngIf="(canEdit$ | async)" type="button" color="primary" [disabled]="savingState.loading$ | async" mat-raised-button [mcPopupTriggerAutoClose]="false" [mcPopupTriggerFor]="addTeamSitesPopup" [mcPopupWidth]="506">
            <div class="mc-d-flex mc-align-items-center">
              <ng-container *ngIf="savingState.loading$ | async; else addTeamSites">Adding Team Sites</ng-container>
              <ng-template #addTeamSites>Add Team Sites</ng-template>
              <span class="mc-fill-remaining-flex-space"></span>
              <mc-dropdown-arrow class="mc-team-access-form-dropdown-arrow"></mc-dropdown-arrow>
            </div>
          </button>

          <mat-form-field color="secondary" appearance="standard" class="mc-form-field">
            <mat-label>Filter Team Sites</mat-label>
            <div class="mc-team-access-form-access-team-entity">
              <input #filterTeamSites matInput placeholder="Name" name="filterTeamSite" (keyup)="onFilterTeamSitesKeyUp($event)">
            </div>
          </mat-form-field>
          <mc-filter-bar display="list" [filterable]="teamSitesList"></mc-filter-bar>
        </div>
        <div class="mc-spring-board-form-body">
          <mc-team-sites-list #teamSitesList [teamId]="team?.Id" [canEdit]="(canEdit$ | async)" (saved)="onSaved()"></mc-team-sites-list>
        </div>
      </div>

      <mc-popup #addTeamSitesPopup (closed)="onTeamSitesPopupClosed()">
        <div class="mc-spring-board">
          <div class="mc-spring-board-form-body">
            <mat-form-field color="secondary" appearance="standard" class="mc-form-field">
              <mat-label>Add Team Sites</mat-label>
              <mc-autocomplete-input #teamSitesAutoComplete [multiple]="true" [closePopupOnSelection]="true" [triggerPopupOnFocus]="false" placeholder="Type to find sites">
                <mc-sites-select-list mcAutocompleteList [licenseId]="team.LicenseId"></mc-sites-select-list>
                <ng-template mcAutocompleteItem let-siteId="item">
                  <mc-site-avatar compact [disabled]="true" [site]="siteId | mcSiteAsync | async" size="small" [truncate]="true"></mc-site-avatar>
                </ng-template>
              </mc-autocomplete-input>
            </mat-form-field>
          </div>
          <div class="mc-spring-board-form-footer">
            <div class="mc-form-buttons">
              <button type="button" mat-stroked-button color="warn" (click)="onCancelTeamSitesClicked()">Cancel</button>
              <button type="button" mat-raised-button color="primary" (click)="onAddTeamSitesClicked()">Add Sites to Team</button>
            </div>
          </div>
        </div>
      </mc-popup>
    </mat-tab>
  </mat-tab-group>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>
  </div>

  <!-- Loading State -->
  <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

  <!-- Saving State -->
  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>

<!-- loading State Error -->
<div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>