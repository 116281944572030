<form class="mc-spring-board" [formGroup]="multimediaForm" (ngSubmit)="onSubmit()">
  <mat-toolbar class="mc-spring-board-header" color="secondary">
    {{src ? 'Edit ' : 'Insert '}}Multimedia
    <span class="mc-fill-remaining-flex-space"></span>
    <button mat-icon-button type="button" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-form-body">
    <mat-form-field class="mc-form-field" appearance="standard" color="secondary">
      <mat-label>From</mat-label>
      <mat-select #multimediaTypeSelect formControlName="from" placeholder="Multimedia From">
        <mat-option [value]="'fromProject'">
          Project
        </mat-option>
        <mat-option [value]="'fromWeb'">
          Web
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="multimediaTypeSelect.value === 'fromProject'" appearance="standard" color="secondary" class="mc-form-field">
      <mat-label>File *</mat-label>
      <mc-project-file-picker-input formControlName="filePath" [branchName]="data.commitId" [projectId]="data.projectId" [fileFilter]="multimediaFilter" rootFolder="Content" [defaultPath]=" ProjectFolder.Multimedia + '/'"></mc-project-file-picker-input>
      <mat-error>
        <mc-form-control-error-message [form]="multimediaForm" controlName="filePath" type="mc_multimedia"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="multimediaTypeSelect.value === 'fromWeb'" appearance="standard" class="mc-form-field" color="secondary">
      <mat-label>Web</mat-label>
      <input matInput type="text" formControlName="webLink" placeholder="Multimedia From Web">
      <mat-error>
        <mc-form-control-error-message [form]="multimediaForm" controlName="webLink" type="mc_multimedia"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <div class="mc-form-fields-row">
      <mat-form-field appearance="standard" class="mc-form-field" color="secondary">
        <mat-label>Width</mat-label>
        <mc-style-picker-input #stylePickerWidthInput formControlName="width" label="Width" [presets]="presets"></mc-style-picker-input>
        <button matSuffix mat-icon-button type="button" color="secondary" (click)="stylePickerWidthInput.openPopup()">
          <mat-icon fontSet="project-titan" fontIcon="icon-ruler"></mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="standard" class="mc-form-field" color="secondary">
        <mat-label>Height</mat-label>
        <mc-style-picker-input #stylePickerHeightInput formControlName="height" label="Height" [presets]="presets"></mc-style-picker-input>
        <button matSuffix mat-icon-button type="button" color="secondary" (click)="stylePickerHeightInput.openPopup()">
          <mat-icon fontSet="project-titan" fontIcon="icon-ruler"></mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div class="mc-spring-board-form-footer">
    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="closeDialog()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="!multimediaForm.valid">
        {{src ? 'Save ' : 'Insert '}}
      </button>
    </div>
  </div>
</form>