import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { legacyMixinColor as mixinColor, LegacyCanColor as CanColor, LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { FilterPickerPopupComponent } from '@portal-core/ui/filter-picker/components/filter-picker-popup/filter-picker-popup.component';

// Boilerplate for applying the color mixin to FilterPickerToggleComponent
class FilterPickerToggleComponentBase {
  constructor(public _elementRef: ElementRef) { }
}
const FilterPickerToggleComponentMixinBase: Constructor<CanColor> & typeof FilterPickerToggleComponentBase = mixinColor(FilterPickerToggleComponentBase);

/**
 * A toggle button for opening a FilterPickerPopupComponent.
 * Requires the `for` property to be bound to an FilterPickerPopupComponent.
 */
@Component({
  selector: 'mc-filter-picker-toggle',
  templateUrl: './filter-picker-toggle.component.html',
  styleUrls: ['./filter-picker-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterPickerToggleComponent extends FilterPickerToggleComponentMixinBase implements CanColor {
  /** The theme color of the toggle button. */
  @Input() color: ThemePalette;

  /** Whether the toggle button is disabled. */
  @Input() disabled: boolean = false;

  /** Whether ripples on the toggle button should be disabled. */
  @Input() disableRipple: boolean;

  /** FilterPickerPopupComponent instance that the button will toggle. */
  @Input('for')
  get filterPickerPopup(): FilterPickerPopupComponent {
    return this._filterPickerPopup;
  }
  set filterPickerPopup(value: FilterPickerPopupComponent) {
    this._filterPickerPopup = value;

    // Update the popup content component if it exists
    if (this._filterPickerPopup) {
      this._filterPickerPopup.registerToggle(this);
    }
  }
  private _filterPickerPopup: FilterPickerPopupComponent;

  /** Tabindex for the toggle button. */
  @HostBinding('attr.tabindex')
  @Input() tabIndex: number;

  /** The tooltip for the toggle button. */
  @Input() tooltip?: string = 'Open Filters';

  /** Applies the mc-filter-picker-toggle class to the host element. */
  @HostBinding('class.mc-filter-picker-toggle') hostClass: boolean = true;

  /** Determines whether the mc-filter-picker-toggle-active class is added to the host element. */
  @HostBinding('class.mc-filter-picker-toggle-active')
  get popupActive(): boolean {
    return this.filterPickerPopup && this.filterPickerPopup.opened;
  }

  /** The button element in this component. */
  @ViewChild('toggleButton', { static: true }) toggleButton: MatButton;

  /** Handles the focus event on the host element in order to focus the button within. */
  @HostListener('focus')
  onFocus() {
    this.toggleButton.focus();
  }

  constructor(private elementRef: ElementRef) {
    super(elementRef);
  }

  /**
   * Gets the element that the filter picker popup should be connected to.
   * @return The element to connect the popup to.
   */
   getConnectedOverlayOrigin(): ElementRef {
    return this.elementRef;
  }

  /** Handles the button being clicked in order to open the filter picker popup.  */
  onToggleButtonClicked(event: MouseEvent) {
    if (this.filterPickerPopup && !this.disabled) {
      this.filterPickerPopup.open();
      event.stopPropagation();
    }
  }
}
