import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { SiteAvatarModule } from '@portal-core/sites/components/site-avatar/site-avatar.module';
import { SitesSelectListComponent } from '@portal-core/sites/components/sites-select-list/sites-select-list.component';
import { ListModule } from '@portal-core/ui/list/list.module';

@NgModule({
  declarations: [
    SitesSelectListComponent
  ],
  imports: [
    CommonModule,
    ListModule,
    MatListModule,
    SiteAvatarModule,
  ],
  exports: [
    SitesSelectListComponent
  ]
})
export class SitesSelectListModule { }
