<div class="mc-vertical-split-dialog">
  <div matDialogTitle>
    <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-image-icon mc-image-icon-normal" color="primary"></mat-icon>
    <button mat-icon-button class="mc-vertical-split-dialog-close-button" tabindex="-1" [matDialogClose]="false">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <p class="mc-vertical-split-dialog-body mc-vertical-split-dialog-body-centered">
      This browser does not allow {{ actionDisplayText }} from the context menu, but you can use these keyboard shortcuts:
    </p>

    <div class="mc-cut-copy-paste-help-dialog-shortcuts">
      <div class="mc-cut-copy-paste-help-dialog-shortcut-group" [ngClass]="action === 'cut' ? 'mc-cut-copy-paste-help-dialog-shortcut-group-active' : 'mc-text-muted'">
        <p class="mc-cut-copy-paste-help-dialog-shortcut">
          <mat-icon *ngIf="useCommandIcon" fontSet="project-titan" fontIcon="icon-command" class="mc-image-icon"></mat-icon>
          <ng-container *ngIf="!useCommandIcon">Ctrl+</ng-container>X
        </p>
        <p class="mc-cut-copy-paste-help-dialog-shortcut-description">Cut</p>
      </div>

      <div class="mc-cut-copy-paste-help-dialog-shortcut-group" [ngClass]="action === 'copy' ? 'mc-cut-copy-paste-help-dialog-shortcut-group-active' : 'mc-text-muted'">
        <p class="mc-cut-copy-paste-help-dialog-shortcut">
          <mat-icon *ngIf="useCommandIcon" fontSet="project-titan" fontIcon="icon-command" class="mc-image-icon"></mat-icon>
          <ng-container *ngIf="!useCommandIcon">Ctrl+</ng-container>C
        </p>
        <p class="mc-cut-copy-paste-help-dialog-shortcut-description">Copy</p>
      </div>

      <div class="mc-cut-copy-paste-help-dialog-shortcut-group" [ngClass]="action === 'paste' ? 'mc-cut-copy-paste-help-dialog-shortcut-group-active' : 'mc-text-muted'">
        <p class="mc-cut-copy-paste-help-dialog-shortcut">
          <mat-icon *ngIf="useCommandIcon" fontSet="project-titan" fontIcon="icon-command" class="mc-image-icon"></mat-icon>
          <ng-container *ngIf="!useCommandIcon">Ctrl+</ng-container>V
        </p>
        <p class="mc-cut-copy-paste-help-dialog-shortcut-description">Paste</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mc-dialog-actions-align-center">
    <button mat-raised-button color="primary" matDialogClose>OK</button>
  </mat-dialog-actions>
</div>