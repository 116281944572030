import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ProjectsSelectListModule } from '@portal-core/projects/components/projects-select-list/projects-select-list.module';
import { AutocompleteInputModule } from '@portal-core/ui/autocomplete/components/autocomplete-input/autocomplete-input.module';
import { FilterPickerComponent } from './filter-picker.component';
import { ProjectAsyncModule } from '@portal-core/projects/pipes/project-async/project-async.module';

@NgModule({
  declarations: [FilterPickerComponent],
  imports: [
    AutocompleteInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    ProjectAvatarModule,
    ProjectsSelectListModule,
    ReactiveFormsModule,
    ProjectAsyncModule
  ],
  exports: [FilterPickerComponent]
})
export class FilterPickerModule {}
