import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { NoItemPlaceholderSize } from '@portal-core/general/enums/no-item-placeholder-size.enum';

@Component({
  selector: 'mc-no-item-placeholder',
  templateUrl: './no-item-placeholder.component.html',
  styleUrls: ['./no-item-placeholder.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoItemPlaceholderComponent {
  @Input() message: string;
  @Input() documentationTextHelper: string;
  @Input() documentationUrl: string;
  @Input() size: NoItemPlaceholderSize = NoItemPlaceholderSize.XxLarge;

  @HostBinding('class.mc-bg-component') hostClass: boolean = true;
}
