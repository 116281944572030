<form class="mc-spring-board" (ngSubmit)="onSubmit(teamSettingsForm)" [formGroup]="teamSettingsForm">
  <div class="mc-spring-board-form-body">
    <div class="mc-form-field">
      <mc-image-picker placeholder="Avatar" formControlName="Avatar" color="secondary"></mc-image-picker>
      <mat-error class="mc-form-field-subscript">
        <mc-form-control-error-message [form]="teamSettingsForm" controlName="Avatar" type="mc_avatar_image"></mc-form-control-error-message>
      </mat-error>
    </div>
    <mat-form-field class="mc-form-field" color="secondary">
      <input matInput type="text" formControlName="Name" placeholder="Name" required>
      <mat-error>
        <mc-form-control-error-message [form]="teamSettingsForm" controlName="Name" type="mc_team_name"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>
    <mat-form-field color="secondary" class="mc-form-field">
      <textarea matInput formControlName="Description" placeholder="Description"></textarea>
    </mat-form-field>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="teamSettingsForm.pristine || teamSettingsForm.invalid || (savingState.loading$ | async)">Save</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>