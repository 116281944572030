<form class="mc-spring-board" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-form-body">
    <mat-form-field appearance="standard" class="mc-form-field mc-permissions-form-level-form-field" color="secondary">
      <mat-label>Level</mat-label>

      <mat-select class="mc-select-trigger-dynamic-height" [value]="levelId" (selectionChange)="onPermissionsLevelSelectionChanged($event)">
        <mat-select-trigger>
          <ng-container *ngIf="levelId === LicenseLevelId; else projectTemplate">
            Global Permissions
          </ng-container>
          <ng-template #projectTemplate>
            <mc-project-avatar [disabled]="true" [project]="levelId | mcProjectAsync | async" size="small" [truncate]="true"></mc-project-avatar>
          </ng-template>
        </mat-select-trigger>

        <mat-option [value]="LicenseLevelId">Global Permissions</mat-option>
        <mat-option *ngFor="let projectId of projectIds" [value]="projectId">
          <mc-project-avatar [disabled]="true" [project]="projectId | mcProjectAsync | async" size="small" [truncate]="true"></mc-project-avatar>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="mc-permissions-form-tree-container">
      <p class="mc-form-note">
        Setting Global Permissions affects all projects. If you select a permission globally, you cannot then deselect that permission for a specific project.
        Instead, you must first deselect the permission globally. After that, you can go to the permissions for each project and choose whether that option should be allowed.
      </p>

      <mc-permissions-tree [disabledPermissions]="disabledPermissions" [selectedPermissions]="selectedPermissions" [readonly]="readonly"></mc-permissions-tree>

      <div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
        <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
      </div>
    </div>
  </div>

  <div *ngIf="!readonly && !(loadingState.generalError$ | async)" class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button class="mc-dialog-card-action-button" (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button class="mc-dialog-card-action-button" color="primary">Save</button>
    </div>
  </div>

  <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>
  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>