import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { IncludedInModule } from '@portal-core/general/pipes/included-in/included-in.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ListModule } from '@portal-core/ui/list/list.module';
import { TeamProjectsListComponent } from './team-projects-list.component';


@NgModule({
  declarations: [
    TeamProjectsListComponent
  ],
  imports: [
    CommonModule,
    ListModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    ProjectAvatarModule,
    MatSnackBarModule,
    IncludedInModule,
    MatTooltipModule
  ],
  exports: [
    TeamProjectsListComponent
  ]
})
export class TeamProjectsListModule { }
