import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { FilterPickerModule } from '@portal-core/ui/filter-picker/components/filter-picker/filter-picker.module';
import { FilterPickerPopupContentComponent } from './filter-picker-popup-content.component';

@NgModule({
  declarations: [
    FilterPickerPopupContentComponent
  ],
  imports: [
    A11yModule,
    CommonModule,
    FilterPickerModule
  ],
  exports: [
    FilterPickerPopupContentComponent
  ]
})
export class FilterPickerPopupContentModule { }
