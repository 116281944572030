<form [formGroup]="filterPickerForm">
  <div *ngIf="searchFilterConfig$ | async as searchFilterConfig" class="mc-form-section">
    <h4 class="text-heavy-weight">Search</h4>
    <mat-form-field appearance="standard" [color]="color" class="mc-form-field">
      <mat-label>{{ searchFilterConfig.placeholder }}</mat-label>
      <input #searchField matInput formControlName="search">
    </mat-form-field>
  </div>

  <div *ngIf="showFilterSection$ | async" class="mc-form-section">
    <h4 class="text-heavy-weight">Filter</h4>

    <ng-container *ngIf="selectsFormArray.controls?.length > 0" formArrayName="selects">
      <ng-container *ngFor="let selectGroup of selectsFormArray.controls; first as isFirst">
        <ng-container *ngIf="selectGroup.get('autocomplete').value">
          <mat-form-field appearance="standard" [color]="color" class="mc-form-field" [formGroup]="selectGroup">
            <mat-label>{{ selectGroup.get('title').value }}</mat-label>

            <!-- In order to grab a reference to the first control in the selects section we need to define a separate bit of markup with the template variable name -->
            <ng-container *ngIf="isFirst">
              <mc-autocomplete-input #selectAutocomplete formControlName="options" popupHeight="width" [ngSwitch]="selectGroup.get('autocomplete').value">
                <mc-projects-select-list *ngIf="selectGroup.get('autocomplete').value === 'projects'" mcAutocompleteList [licenseId]="getColumnInputValue(selectGroup.get('name').value, 'licenseId')" type="license"></mc-projects-select-list>
                <mc-projects-select-list *ngIf="selectGroup.get('autocomplete').value === 'review-projects'" mcAutocompleteList [licenseId]="getColumnInputValue(selectGroup.get('name').value, 'licenseId')" type="review"></mc-projects-select-list>

                <ng-template *ngIf="selectGroup.get('autocomplete').value === 'projects' || selectGroup.get('autocomplete').value === 'review-projects'" mcAutocompleteItem let-projectId="item">
                  <mc-project-avatar compact [disabled]="true" [project]="projectId | mcProjectAsync | async" size="small" [truncate]="true"></mc-project-avatar>
                </ng-template>
              </mc-autocomplete-input>
            </ng-container>
            <ng-container *ngIf="!isFirst">
              <mc-autocomplete-input formControlName="options" popupHeight="width" [ngSwitch]="selectGroup.get('autocomplete').value">
                <mc-projects-select-list *ngIf="selectGroup.get('autocomplete').value === 'projects'" mcAutocompleteList [licenseId]="getColumnInputValue(selectGroup.get('name').value, 'licenseId')" type="license"></mc-projects-select-list>
                <mc-projects-select-list *ngIf="selectGroup.get('autocomplete').value === 'review-projects'" mcAutocompleteList [licenseId]="getColumnInputValue(selectGroup.get('name').value, 'licenseId')" type="review"></mc-projects-select-list>

                <ng-template *ngIf="selectGroup.get('autocomplete').value === 'projects' || selectGroup.get('autocomplete').value === 'review-projects'" mcAutocompleteItem let-projectId="item">
                  <mc-project-avatar compact [disabled]="true" [project]="projectId | mcProjectAsync | async" size="small" [truncate]="true"></mc-project-avatar>
                </ng-template>
              </mc-autocomplete-input>
            </ng-container>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="!selectGroup.get('autocomplete').value">
          <mat-form-field appearance="standard" [color]="color" class="mc-form-field" [formGroup]="selectGroup">
            <mat-label>{{ selectGroup.get('title').value }}</mat-label>

            <!-- In order to grab a reference to the first control in the selects section we need to define a separate bit of markup with the template variable name -->
            <ng-container *ngIf="isFirst">
              <mat-select #selectSelect multiple formControlName="options">
                <mat-option *ngFor="let item of selectGroup.get('selectOptions').value" [value]="item.id">{{item.text}}</mat-option>
              </mat-select>
            </ng-container>
            <ng-container *ngIf="!isFirst">
              <mat-select multiple formControlName="options">
                <mat-option *ngFor="let item of selectGroup.get('selectOptions').value" [value]="item.id">{{item.text}}</mat-option>
              </mat-select>
            </ng-container>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="datesFormArray.controls?.length > 0" formArrayName="dates">
      <div *ngFor="let dateGroup of datesFormArray.controls; let i = index" class="mc-form-fields-row" [formGroup]="dateGroup">
        <mat-form-field appearance="standard" [color]="color" class="mc-filter-picker-date-condition">
          <mat-label>{{ dateGroup.get('title').value }}</mat-label>
          <mat-select #dateSelect [placeholder]="dateGroup.get('title').value" formControlName="condition">
            <mat-option><i>Any</i></mat-option>
            <mat-option [value]="PageFilterType.Equals">On</mat-option>
            <mat-option [value]="PageFilterType.LessThan">Before</mat-option>
            <mat-option [value]="PageFilterType.GreaterThan">After</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard" [color]="color">
          <mat-label>Date</mat-label>
          <input #fromInput matInput placeholder="Date" formControlName="date" [matDatepicker]="fromDate">
          <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
          <mat-datepicker [color]="color" #fromDate></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>

    <div *ngIf="boolsFormArray.controls?.length > 0" class="mc-filter-picker-boolean-section" formArrayName="bools">
      <ng-container *ngFor="let boolGroup of boolsFormArray.controls; let i = index" [formGroup]="boolGroup">
        <mat-slide-toggle #boolToggles [color]="color" formControlName="toggled" labelPosition="before" class="mc-slide-toggle-space-between">{{ boolGroup.get('title').value }}</mat-slide-toggle>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="sortColumns$ | async as sortColumns">
    <div *ngIf="sortColumns.length > 0" class="mc-form-section">
      <h4 class="text-heavy-weight">Sort</h4>
      <div class="mc-filter-picker-sort-by" [formGroup]="orderGroup">
        <mat-form-field appearance="standard" [color]="color" class="mc-filter-picker-sort-by-select">
          <mat-select #sortSelect placeholder="Select a sort option" formControlName="by">
            <mat-option><i>None</i></mat-option>
            <mat-option *ngFor="let sortColumn of sortColumns" [value]="sortColumn.name">{{ sortColumn.title }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="mc-filter-picker-sort-by-dir">
          Desc
          <mat-slide-toggle [color]="color" formControlName="dir" class="mc-slide-toggle-always-colored"></mat-slide-toggle>
          Asc
        </div>
      </div>
    </div>
  </ng-container>

  <div class="mc-form-buttons">
    <button #clearButton type="button" mat-stroked-button color="warn" (click)="onClearFilterClicked()">Clear</button>
  </div>
</form>