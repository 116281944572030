import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CollabFileType } from '@common/collab/enums/collab-file-type.enum';
import { FlareCommands } from '@common/flare/flare-commands';
import { FlareSchema } from '@common/flare/flare-schema';
import { AnnotationAttributes } from '@common/flare/types/annotation-attributes.type';
import { ChangeType } from '@common/prosemirror/changeset/change-type.enum';
import { Conditions, selectedConditions } from '@common/prosemirror/commands/conditions.command';
import { getNodeRangesAndTargetsForLiftFromInlineNode } from '@common/prosemirror/commands/node';
import { ProxyNodeCommand } from '@common/prosemirror/commands/proxy.command';
import { DimensionOptions } from '@common/prosemirror/commands/table.command';
import { containsInlineNode, getLowestLevelContainer, hasNode } from '@common/prosemirror/model/node';
import { resolvedPosFind } from '@common/prosemirror/model/resolved-pos';
import { AppliedConditionsPluginKey, AppliedConditionsPluginState } from '@common/prosemirror/plugins/applied-conditions.plugin';
import { TrackedChangesPluginKey, TrackedChangesPluginState, TrackedChangesSetMetaKey } from '@common/prosemirror/plugins/tracked-changes';
import { getNodeContainingSelection, selectionIsInNode } from '@common/prosemirror/state/selection';
import { extname } from '@common/util/path';
import { AnalyticsService } from '@portal-core/analytics/services/analytics.service';
import { MaxAttachmentSizeBytes } from '@portal-core/data/common/constants/max-attachment-size.constant';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { AlertDialogComponent } from '@portal-core/general/components/alert-dialog/alert-dialog.component';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { FileService } from '@portal-core/general/services/file.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { FlareFileTextEditorFeatureUnsupportedDialogComponent, FlareFileTextEditorFeatureUnsupportedDialogData } from '@portal-core/project-files/components/flare-file-text-editor-feature-unsupported-dialog/flare-file-text-editor-feature-unsupported-dialog.component';
import { LinkPropertiesDialogComponent, LinkPropertiesDialogData, LinkPropertiesDialogResult } from '@portal-core/project-files/components/link-properties-dialog/link-properties-dialog.component';
import { MultimediaDialogComponent, MultimediaDialogData, MultimediaDialogResult } from '@portal-core/project-files/components/multimedia-dialog/multimedia-dialog.component';
import { ProjectFilesAIDialogComponent, ProjectFilesAIDialogData } from '@portal-core/project-files/components/project-files-ai-dialog/project-files-ai-dialog.component';
import { ProjectFilesImageDialogComponent, ProjectFilesImageDialogData, ProjectFilesImageDialogResult } from '@portal-core/project-files/components/project-files-image-dialog/project-files-image-dialog.component';
import { ProjectFilesProxyDialogComponent, ProjectFilesProxyDialogData, ProjectFilesProxyDialogResult } from '@portal-core/project-files/components/project-files-proxy-dialog/project-files-proxy-dialog.component';
import { ApplyConditionsDialogComponent, ApplyConditionsDialogData } from '@portal-core/project-files/conditions/components/apply-conditions-dialog/apply-conditions-dialog.component';
import { ProjectConditionSet } from '@portal-core/project-files/conditions/models/project-condition-set.model';
import { TopicFileAndSnippetFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ProjectFileProxyType } from '@portal-core/project-files/enums/project-file-proxy-type.enum';
import { FlareFileTextEditorService } from '@portal-core/project-files/services/flare-file-text-editor.service';
import { MetaDataService } from '@portal-core/project-files/services/meta-data.service';
import { InsertVariableDialogComponent, InsertVariableDialogData } from '@portal-core/project-files/variables/components/insert-variable-dialog/insert-variable-dialog.component';
import { ProjectVariableSet } from '@portal-core/project-files/variables/models/project-variable-set.model';
import { EditorImageExtensions } from '@portal-core/text-editor/constants/editor-image-extensions.constant';
import { InsertImageEvent } from '@portal-core/text-editor/models/insert-image.event';
import { InsertSnippetEvent } from '@portal-core/text-editor/models/insert-snippet-event';
import { EditorToolbarComponent } from '@portal-core/ui/editor/components/editor-toolbar/editor-toolbar.component';
import { EditorToolbarDropdownMenuClosedEvent } from '@portal-core/ui/editor/types/editor-toolbar-dropdown-menu-closed-event.type';
import { EditorToolbarItem } from '@portal-core/ui/editor/types/editor-toolbar-item.type';
import { EditorToolbarControl } from '@portal-core/ui/editor/util/editor-toolbar-control';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { redo, undo } from 'prosemirror-history';
import { NodeType } from 'prosemirror-model';
import { Command, EditorState, NodeSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Observable, first, map, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'mc-flare-file-text-editor-toolbar',
  templateUrl: './flare-file-text-editor-toolbar.component.html',
  styleUrls: ['./flare-file-text-editor-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlareFileTextEditorToolbarComponent implements OnInit, OnChanges {
  @Input() getEditorState: () => EditorState;
  @Input() featureSetVersion: number;
  @Input() licenseUser: LicenseUser;
  @Input() commitId: string;
  @Input() projectId: number;
  @Input() schema: FlareSchema;
  @Input() branchName: string;
  @Input() fileType?: CollabFileType = CollabFileType.Unknown;
  @Input() filePath: string;
  @Input() fileId: number;
  @Input() projectLanguage: string;
  @Input() trackedChanges?: boolean = true;
  @Input() showAI?: boolean = true;
  @Input() showTrackedChanges?: boolean = true;

  @Output() dispatch: EventEmitter<Command> = new EventEmitter<Command>();
  @Output() dropdownMenuClosed: EventEmitter<EditorToolbarDropdownMenuClosedEvent<Command>> = new EventEmitter<EditorToolbarDropdownMenuClosedEvent<Command>>();
  @Output() insertingAnnotation: EventEmitter<Partial<AnnotationAttributes>> = new EventEmitter<Partial<AnnotationAttributes>>();
  @Output() insertingImage: EventEmitter<InsertImageEvent> = new EventEmitter<InsertImageEvent>();
  @Output() insertingSnippet: EventEmitter<InsertSnippetEvent> = new EventEmitter<InsertSnippetEvent>();
  @Output() trackedChangesToggle: EventEmitter<void> = new EventEmitter<void>();
  @Output() showConditionsToggle: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('toolbar', { static: true }) toolbar: EditorToolbarComponent<Command, EditorState>;
  @ViewChild('insertNewImageInput', { static: false }) insertNewImageInputElement: ElementRef<HTMLInputElement>;

  @InputObservable('fileType') fileType$: Observable<CollabFileType>;

  flareCommands: FlareCommands;
  EditorImageExtensions: string[] = EditorImageExtensions;
  imageAcceptAttr: string = EditorImageExtensions.map(ext => '.' + ext).join(',');

  toolbarControl$: Observable<EditorToolbarControl<Command>>;
  userCanEditWithAIAssist$: Observable<boolean>;

  constructor(
    private fileService: FileService,
    private errorService: ErrorService,
    private flareFileTextEditorService: FlareFileTextEditorService,
    private permissionsService: PermissionsService,
    private analyticsService: AnalyticsService,
    private metaDataService: MetaDataService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.userCanEditWithAIAssist$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.AIAssist, this.projectId);
    this.toolbarControl$ = this.fileType$.pipe(
      map(() => this.createToolbarItems())
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.schema) {
      this.flareCommands = this.schema ? new FlareCommands(this.schema) : null;
    }
  }

  public updateState(editorState: EditorState) {
    this.toolbar.updateState(editorState);
  }

  public checkViewport() {
    this.toolbar.checkViewport();
  }

  onItemClicked(item: EditorToolbarItem<Command>) {
    this.sendGoogleAnalyticsEvent(item.tooltip);

    if (item.command) {
      this.dispatch.emit(item.command as Command);
    }
  }

  onDropdownMenuClosed(event: EditorToolbarDropdownMenuClosedEvent<Command>) {
    this.dropdownMenuClosed.emit(event);
  }

  onTableDimensionsPicked(item: EditorToolbarItem<Command>, options: DimensionOptions) {
    this.sendGoogleAnalyticsEvent(item.tooltip);

    if (this.flareCommands) {
      this.dispatch.emit((editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView) => {
        return this.flareCommands.insertTable(editorState, dispatch, view, options);
      });
    }
  }

  onDimensionPickerDropdownMenuClosed(item: EditorToolbarItem<Command>, event: any) {
    this.sendGoogleAnalyticsEvent(item.tooltip);

    this.dropdownMenuClosed.emit({
      item: event.dimensions ? item : null,
      source: event.source
    });
  }

  openProxyDialog = (editorState: EditorState, proxyType: ProjectFileProxyType, command: ProxyNodeCommand): boolean => {
    const selection = editorState.selection;

    const proxyNode = getNodeContainingSelection(selection, node => node.type.name === `madcap${proxyType.toLowerCase()}proxy`);

    const isEdit = !!proxyNode;
    // Document can only have one body proxy.
    if (proxyType === ProjectFileProxyType.Body && !isEdit && this.bodyProxyExists(editorState)) {
      this.dialog.open(AlertDialogComponent, {
        width: '36rem',
        data: { message: 'This template page already contains a body proxy. More than one body proxy is not necessary.' }
      });
      return true;
    }

    const attrs = proxyNode?.attrs;

    const metaData: Dictionary<Dictionary> = editorState['metaData$']?.metaData;
    const skins = this.metaDataService.extractSkinsFromMetaData(metaData, proxyType)?.map(skin => skin.FilePath);
    let TOCs: string[];
    if (proxyType === ProjectFileProxyType.Menu) {
      TOCs = this.metaDataService.extractTOCsFromMetaData(metaData);
    }

    this.dialog.open<ProjectFilesProxyDialogComponent, ProjectFilesProxyDialogData, ProjectFilesProxyDialogResult>(ProjectFilesProxyDialogComponent, {
      ...ProjectFilesProxyDialogComponent.DialogConfig,
      data: {
        type: proxyType,
        attrs: attrs,
        isEdit: isEdit,
        commitId: this.commitId,
        projectId: this.projectId,
        skinFilePaths$: skins ? of(skins) : this.metaDataService.getProjectSkins$(this.committedItem, proxyType).pipe(map(skins => skins?.map(skin => skin.FilePath) ?? null)),
        TOCs$: proxyType === ProjectFileProxyType.Menu ? (TOCs ? of(TOCs) : this.metaDataService.getProjectTOCs$(this.committedItem)) : null
      }
    }).afterClosed().subscribe((result) => {
      if (result && command) {
        this.dispatch.emit((editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView) => {
          return command(editorState, dispatch, view, result.attrs);
        });
      }
    });
    // to use as a command
    return true;
  }

  // Check if the state has a body proxy that exists in the doc but outside of the selection. The nodes in the selection will be overwritten.
  private bodyProxyExists(editorState: EditorState): boolean {
    const docWithoutSelection = editorState.tr.deleteSelection();
    return hasNode(docWithoutSelection.doc, (node) => node.type.name === 'madcapbodyproxy' && !node.attrs?.changeList?.some(change => change.changeType === ChangeType.Remove));
  }

  openInsertImageDialog = (editorState: EditorState): boolean => {
    if (this.fileType === CollabFileType.Review && !this.flareFileTextEditorService.supported('image-insertion', this.featureSetVersion)) {
      this.dialog.open<FlareFileTextEditorFeatureUnsupportedDialogComponent, FlareFileTextEditorFeatureUnsupportedDialogData>(FlareFileTextEditorFeatureUnsupportedDialogComponent, {
        ...FlareFileTextEditorFeatureUnsupportedDialogComponent.DialogConfig,
        data: {
          feature: 'image-insertion'
        }
      });
      return true;
    }

    const selection = editorState.selection;
    const imageAttrs = getNodeContainingSelection(selection, node => node.type.name === 'image')?.attrs ?? {};

    this.dialog.open<ProjectFilesImageDialogComponent, ProjectFilesImageDialogData, ProjectFilesImageDialogResult>(ProjectFilesImageDialogComponent, {
      ...ProjectFilesImageDialogComponent.DialogConfig,
      data: {
        commitId: this.commitId,
        projectId: this.projectId,
        editorFilePath: this.filePath,
        width: imageAttrs?.style?.width,
        height: imageAttrs?.style?.height,
        alt: imageAttrs?.alt,
        initialSrc: imageAttrs?.src,
        isReview: this.fileType === CollabFileType.Review,
        branch: this.branchName
      }
    }).afterClosed().subscribe(result => {
      if (result && this.flareCommands) {
        // A file with a path is the default file that came from the selected node. Therefore just update the node attributes
        if (this.fileType === CollabFileType.Review && result.file) {
          // Upload a new file
          this.onNewImageSelected(result);
        } else {
          this.dispatch.emit((editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView) => {
            return this.flareCommands.insertOrEditImage(editorState, dispatch, view, result.attrs);
          })
        }
      }
    });
    // to use as a command
    return true;
  }

  openPropertiesLinkDialog = (editorState: EditorState, usingCrossRef: boolean = false): boolean => {
    const selection = editorState.selection;
    const selectionSlice = selection.content();
    const content = selectionSlice.content;

    const linkNode = getNodeContainingSelection(selection, node => usingCrossRef ? node.type.name === 'madcapcrossreference' : node.type.name === 'link');
    const attrs = linkNode?.attrs;

    const contentContainerNode = selectionSlice.size ? getLowestLevelContainer(selectionSlice) : null;
    const selectionJustText = contentContainerNode?.isText || !contentContainerNode ? true : false;
    const contentIsLink = contentContainerNode?.type.spec.isLink;
    this.dialog.open<LinkPropertiesDialogComponent, LinkPropertiesDialogData, LinkPropertiesDialogResult>(LinkPropertiesDialogComponent, {
      ...LinkPropertiesDialogComponent.DialogConfig,
      data: {
        commitId: this.commitId,
        projectId: this.projectId,
        content: selectionJustText ? linkNode?.textContent ?? content.textBetween(0, content.size) : contentContainerNode,
        currentFilePath: this.filePath,
        href: attrs?.href,
        target: attrs?.target,
        document: editorState.doc,
        alt: attrs?.alt,
        class: attrs?.class,
        id: attrs?.id,
        tabIndex: attrs?.tabindex,
        title: attrs?.title,
        selectionJustText,
        usingCrossRef,
        contentIsLink
      }
    }).afterClosed().subscribe((result) => {
      if (result && this.flareCommands) {
        this.dispatch.emit((editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView) => {
          if (usingCrossRef) {
            return this.flareCommands.insertOrEditCrossRefLink(editorState, dispatch, view, result.attrs, result.content, result.linkToNode, result.foundBookmark);
          } else {
            return this.flareCommands.insertOrEditLink(editorState, dispatch, view, result.attrs, result.content, result.linkToNode, result.foundBookmark);
          }
        });
      }
    });
    // to use as a command
    return true;
  }

  openApplyConditionsDialog(conditions: Conditions, projectConditionSets: ProjectConditionSet[]) {
    this.dialog.open<ApplyConditionsDialogComponent, ApplyConditionsDialogData, Conditions>(ApplyConditionsDialogComponent, {
      ...ApplyConditionsDialogComponent.DialogConfig,
      data: {
        selectedConditions: conditions?.conditions,
        selectedExcludeAction: conditions?.action,
        projectConditionSets$: projectConditionSets ? of(projectConditionSets) : this.metaDataService.getProjectConditionSets$(this.committedItem)
      }
    }).afterClosed().pipe(
      first() // To workaround a Material bug where afterClosed can emit more than once
    ).subscribe(result => {
      if (result && this.flareCommands) {
        this.dispatch.emit((editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView) => {
          const res = { conditions: result.conditions, action: result.action };
          return this.flareCommands.setConditions(editorState, dispatch, view, res);
        });
      }
    });
  }

  openMultimediaDialog = (editorState: EditorState): boolean => {
    const selection = editorState.selection;

    const multimediaNode = getNodeContainingSelection(selection, node => node.type.name === 'madcapmultimedia' || node.type.name === 'madcapmodel3d');
    const attrs = multimediaNode?.attrs;

    this.dialog.open<MultimediaDialogComponent, MultimediaDialogData, MultimediaDialogResult>(MultimediaDialogComponent, {
      ...MultimediaDialogComponent.DialogConfig,
      data: {
        width: attrs?.style?.width,
        height: attrs?.style?.height,
        src: attrs?.src,
        projectId: this.projectId,
        commitId: this.commitId,
        currentFilePath: this.filePath
      }
    }).afterClosed().subscribe((result) => {
      if (result && this.flareCommands) {
        this.dispatch.emit((editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView) => {
          // 3d model vs normal multimedia
          if (extname(result.attrs.src).toLowerCase() === '.u3d') {
            return this.flareCommands.insertOrEdit3dModel(editorState, dispatch, view, result.attrs, false);
          } else {
            return this.flareCommands.insertOrEditMultimedia(editorState, dispatch, view, result.attrs, result.isHTML5Video);
          }
        });
      }
    });
    // to use as a command
    return true;
  }

  openProjectFilesAIDialog(item: EditorToolbarItem<Command>) {
    this.sendGoogleAnalyticsEvent(item.tooltip);
    const selectionSlice = this.getEditorState().selection.content();
    const showConditions = (AppliedConditionsPluginKey.getState(this.getEditorState()) as AppliedConditionsPluginState)?.enabled ?? false;

    this.dialog.open<ProjectFilesAIDialogComponent, ProjectFilesAIDialogData, string>(ProjectFilesAIDialogComponent, {
      ...ProjectFilesAIDialogComponent.DialogConfig,
      data: {
        commitId: this.commitId,
        branchName: this.commitId,
        projectId: this.projectId,
        filePath: this.filePath,
        projectLanguage: this.projectLanguage,
        licenseUser: this.licenseUser,
        content: selectionSlice.content.size ? this.schema.nodeToCode(getLowestLevelContainer(selectionSlice)) : '',
        showConditions
      }
    }).afterClosed().subscribe(codeReplacement => {
      if (codeReplacement) {
        this.dispatch.emit((editorState: EditorState, dispatch?: ProsemirrorDispatcher, editorView?: EditorView) => {
          editorView.pasteHTML(codeReplacement, new ClipboardEvent('paste'));
          return true;
        });
      }
    });
    return false;
  }

  openInsertProjectSnippetDialog(item: EditorToolbarItem<Command>) {
    this.sendGoogleAnalyticsEvent(item.tooltip);

    this.insertingSnippet.emit({
      insertSnippetCommandCallback: (snippetSrc: string, isInlineContent: boolean) => {
        if (snippetSrc && this.flareCommands) {
          this.dispatch.emit((editorState: EditorState, dispatch?: ProsemirrorDispatcher, editorView?: EditorView) => {
            return this.flareCommands.insertSnippet(editorState, dispatch, editorView, snippetSrc, isInlineContent);
          });
        }
      }
    });
  }

  openInsertProjectVariablesDialog(projectVariableSets: ProjectVariableSet[] | undefined, setName: string | undefined, variableName: string | undefined, hiddenSystemVariables: string[] | undefined) {
    this.dialog.open<InsertVariableDialogComponent, InsertVariableDialogData, string>(InsertVariableDialogComponent, {
      ...InsertVariableDialogComponent.DialogConfig,
      data: {
        projectVariableSets$: projectVariableSets ? of(projectVariableSets) : this.metaDataService.getProjectVariableSets$(this.committedItem),
        setName,
        variableName,
        hiddenSystemVariables
      }
    }).afterClosed().pipe(
      first() // To workaround a Material bug where afterClosed can emit more than once
    ).subscribe(variableName => {
      if (variableName && this.flareCommands) {
        this.dispatch.emit((editorState: EditorState, dispatch?: ProsemirrorDispatcher, editorView?: EditorView) => {
          return this.flareCommands.insertVariable(editorState, dispatch, editorView, variableName);
        });
      }
    });
  }

  onNewImageSelected(newImage: ProjectFilesImageDialogResult) {
    this.fileService.unpackImage$(newImage.file, MaxAttachmentSizeBytes, EditorImageExtensions).subscribe(imageInfo => {
      // Run the image upload command on the doc
      const guid = uuidv4();
      this.dispatch.emit((editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView) => {
        return this.flareCommands.insertImagePlaceholders(editorState, dispatch, view, [{ id: guid }]);
      });
      // Emit the event for inserting an image
      this.insertingImage.emit({
        file: imageInfo.file,
        guid,
        attrs: newImage.attrs
      });
    }, error => {
      this.dialog.open(ErrorDialogComponent, {
        ...ErrorDialogComponent.DialogConfig,
        data: {
          title: 'Error Unpacking Image',
          message: 'An unexpected error happened while unpacking the image',
          errors: this.errorService.getErrorMessages(error)
        }
      });
    });
  }

  protected insertAnnotation(editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView): boolean {
    if (!this.licenseUser || !this.flareCommands) {
      return false;
    }

    const attrs: Partial<AnnotationAttributes> = {
      'MadCap:createDate': new Date().toISOString(),
      'MadCap:creator': this.licenseUser.User.FullName,
      'MadCap:creatorCentralUserId': this.licenseUser.User.Id,
      'MadCap:guid': uuidv4(),
      'MadCap:initials': this.licenseUser.User.Initials
    };

    // Only keep track of the guid when the command is invoked
    if (dispatch) {
      this.insertingAnnotation.emit(attrs);
    }

    return this.flareCommands.insertAnnotation(editorState, dispatch, view, attrs);
  }

  private annotationIsCreatedByCurrentUser(editorState: EditorState): boolean {
    if (!this.licenseUser) {
      return false;
    }

    const rangesAndTargets = getNodeRangesAndTargetsForLiftFromInlineNode(editorState, this.schema.nodes.madcapannotation, FlareCommands.AnnotationLiftFromInlineNodeOptions);

    if (rangesAndTargets.length === 0) {
      return false;
    }

    return rangesAndTargets.every(({ range }) => {
      const nodeInfo = resolvedPosFind(range.$from, node => node.type === this.schema.nodes.madcapannotation);
      return nodeInfo?.node?.attrs['MadCap:creatorCentralUserId'] === this.licenseUser.User.Id ?? false;
    });
  }


  private checkIfAICanBeInserted(editorState: EditorState): boolean {
    const $from = editorState.selection.$from;
    const index = $from.index();

    // with the AI dialog, anything can be inserted so text is used as the everything case.
    if (!$from.parent.canReplaceWith(index, index, this.schema.nodes.text)) {
      return false;
    }
    return true;
  }

  private toggleTrackedChanges(editorState: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    const trackedChangesState: TrackedChangesPluginState = TrackedChangesPluginKey.getState(editorState);

    if (dispatch) {
      const tr = editorState.tr;
      tr.setMeta(TrackedChangesSetMetaKey, {
        enabled: !trackedChangesState?.enabled
      } as TrackedChangesPluginState);
      dispatch(tr);
    }

    this.trackedChangesToggle.emit();
    return true;
  }

  private toggleShowConditions(): boolean {
    this.showConditionsToggle.emit();
    return true;
  }

  private inlineNodeIsActive(editorState: EditorState, nodeType: NodeType): boolean {
    return containsInlineNode(editorState.selection.from, editorState.selection.to, nodeType, editorState.doc);
  }

  private createToolbarItems(): EditorToolbarControl<Command> {
    return new EditorToolbarControl<Command>([
      {
        type: 'button',
        icon: 'icon-track-changes',
        tooltip: 'Toggle Tracking Changes',
        hidden: !this.showTrackedChanges || this.fileType !== CollabFileType.Edit,
        command: this.toggleTrackedChanges.bind(this),
        isActive: (editorState: EditorState) => (TrackedChangesPluginKey.getState(editorState) as TrackedChangesPluginState)?.enabled,
      }, {
        type: 'button',
        icon: 'icon-conditions-show-hide',
        tooltip: 'Show Conditions',
        command: this.toggleShowConditions.bind(this),
        isActive: (editorState: EditorState) => (AppliedConditionsPluginKey.getState(editorState) as AppliedConditionsPluginState)?.enabled,
      }, {
        type: 'divider',
      }, {
        type: 'button',
        icon: 'icon-undo',
        tooltip: 'Undo',
        command: undo,
        disabled: true,
        isDisabled: (editorState: EditorState) => !undo(editorState)
      }, {
        type: 'button',
        icon: 'icon-redo',
        tooltip: 'Redo',
        command: redo,
        disabled: true,
        isDisabled: (editorState: EditorState) => !redo(editorState)
      }, {
        type: 'divider',
      }, {
        type: 'button',
        group: 'annotate',
        icon: 'icon-review',
        tooltip: 'Insert Annotation',
        dropdownText: 'Insert Annotation',
        command: this.insertAnnotation.bind(this),
        disabled: true,
        isDisabled: (editorState: EditorState) => !this.insertAnnotation(editorState),
        isActive: (editorState: EditorState) => this.inlineNodeIsActive(editorState, this.schema.nodes.madcapannotation)
      }, {
        type: 'button',
        group: 'annotate',
        icon: 'icon-close',
        tooltip: 'Delete Annotation',
        dropdownText: 'Delete Annotation',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.deleteAnnotation(editorState, dispatch) ?? false,
        disabled: true,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.deleteAnnotation(editorState) || !this.annotationIsCreatedByCurrentUser(editorState)
      }, {
        type: 'divider',
      }, {
        type: 'button',
        group: 'font',
        icon: 'icon-bold',
        tooltip: 'Toggle Bold',
        dropdownText: 'Bold',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.toggleBold(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.toggleBold(editorState),
        isActive: (editorState: EditorState) => this.inlineNodeIsActive(editorState, this.schema.nodes.b)
      }, {
        type: 'button',
        group: 'font',
        icon: 'icon-italics',
        tooltip: 'Toggle Italic',
        dropdownText: 'Italic',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.toggleItalics(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.toggleItalics(editorState),
        isActive: (editorState: EditorState) => this.inlineNodeIsActive(editorState, this.schema.nodes.i)
      }, {
        type: 'button',
        group: 'font',
        icon: 'icon-underline',
        tooltip: 'Toggle Underline',
        dropdownText: 'Underline',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.toggleUnderline(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.toggleUnderline(editorState),
        isActive: (editorState: EditorState) => this.inlineNodeIsActive(editorState, this.schema.nodes.u)
      }, {
        type: 'divider'
      },
      {
        type: 'button',
        icon: 'icon-link',
        group: 'link',
        tooltip: 'Insert Or Edit Link',
        dropdownText: 'Insert Or Edit Link',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => this.openPropertiesLinkDialog(editorState),
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertOrEditLink(editorState)
      },
      {
        type: 'button',
        icon: 'icon-unlink',
        group: 'link',
        tooltip: 'Remove Link',
        dropdownText: 'Remove Link',
        hidden: this.fileType !== CollabFileType.Edit,
        disabled: true,
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.deleteLink(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.deleteLink(editorState),
      },
      {
        type: 'button',
        icon: 'icon-cross-reference',
        group: 'link',
        tooltip: 'Insert Or Edit Cross Reference',
        dropdownText: 'Insert Or Edit Cross Reference',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => this.openPropertiesLinkDialog(editorState, true),
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertOrEditLink(editorState)
      },
      {
        type: 'button',
        icon: 'icon-delete-cross-reference',
        group: 'link',
        tooltip: 'Remove Cross Reference',
        dropdownText: 'Remove Cross Reference',
        hidden: this.fileType !== CollabFileType.Edit,
        disabled: true,
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.deleteCrossReference(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.deleteCrossReference(editorState),
      },
      {
        type: 'divider',
        hidden: this.fileType !== CollabFileType.Edit
      }, {
        type: 'button',
        group: 'styles',
        text: 'P',
        tooltip: 'Wrap in Paragraph',
        dropdownText: 'Paragraph',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.changeBlockToParagraph(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.changeBlockToParagraph(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.paragraph)
      }, {
        type: 'button',
        group: 'styles',
        text: 'H1',
        tooltip: 'Change to Heading 1',
        dropdownText: 'Heading 1',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.changeBlockToH1(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.changeBlockToH1(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.h1)
      }, {
        type: 'button',
        group: 'styles',
        text: 'H2',
        tooltip: 'Change to Heading 2',
        dropdownText: 'Heading 2',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.changeBlockToH2(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.changeBlockToH2(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.h2)
      }, {
        type: 'button',
        group: 'styles',
        text: 'H3',
        tooltip: 'Change to Heading 3',
        dropdownText: 'Heading 3',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.changeBlockToH3(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.changeBlockToH3(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.h3)
      }, {
        type: 'button',
        group: 'styles',
        text: 'H4',
        tooltip: 'Change to Heading 4',
        dropdownText: 'Heading 4',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.changeBlockToH4(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.changeBlockToH4(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.h4)
      }, {
        type: 'button',
        group: 'styles',
        text: 'H5',
        tooltip: 'Change to Heading 5',
        dropdownText: 'Heading 5',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.changeBlockToH5(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.changeBlockToH5(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.h5)
      }, {
        type: 'button',
        group: 'styles',
        text: 'H6',
        tooltip: 'Change to Heading 6',
        dropdownText: 'Heading 6',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.changeBlockToH6(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.changeBlockToH6(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.h6)
      }, {
        type: 'divider',
      }, {
        type: 'button',
        group: 'lists',
        icon: 'icon-list',
        tooltip: 'Insert Bullet List',
        dropdownText: 'Bullet List',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.wrapInBulletList(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.wrapInBulletList(editorState)
      }, {
        type: 'button',
        group: 'lists',
        icon: 'icon-ordered',
        tooltip: 'Insert Ordered List',
        dropdownText: 'Ordered List',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.wrapInOrderedList(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.wrapInOrderedList(editorState)
      }, {
        type: 'button',
        group: 'lists',
        icon: 'icon-definition-list',
        tooltip: 'Insert Definition List',
        dropdownText: 'Definition List',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.wrapInDefinitionList(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.wrapInDefinitionList(editorState)
      }, {
        type: 'divider',
        group: 'lists',
        dropdownOnly: true
      }, {
        type: 'button',
        group: 'lists',
        icon: 'icon-margin-right',
        tooltip: 'Decrease Indent',
        dropdownText: 'Decrease Indent',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.outdentAnyListItem(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.outdentAnyListItem(editorState)
      }, {
        type: 'button',
        group: 'lists',
        icon: 'icon-margin-left',
        tooltip: 'Increase Indent',
        dropdownText: 'Increase Indent',
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.indentAnyListItem(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.indentAnyListItem(editorState)
      }, {
        type: 'button',
        group: 'proxies',
        icon: 'icon-insert-proxy',
        tooltip: 'Body Proxy',
        dropdownText: 'Body Proxy',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => this.openProxyDialog(editorState, ProjectFileProxyType.Body, this.flareCommands?.insertBodyProxy),
        // Not allowed in topics or snippets
        isDisabled: (editorState: EditorState) => new RegExp(TopicFileAndSnippetFilter).test(this.filePath) || !this.flareCommands?.insertBodyProxy(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.madcapbodyproxy)
      }, {
        type: 'button',
        group: 'proxies',
        icon: 'icon-insert-proxy',
        tooltip: 'Breadcrumbs Proxy',
        dropdownText: 'Breadcrumbs Proxy',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => this.openProxyDialog(editorState, ProjectFileProxyType.Breadcrumbs, this.flareCommands?.insertBreadcrumbsProxy),
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertBreadcrumbsProxy(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.madcapbreadcrumbsproxy)
      }, {
        type: 'button',
        group: 'proxies',
        icon: 'icon-insert-proxy',
        tooltip: 'Topic Toolbar Proxy',
        dropdownText: 'Topic Toolbar Proxy',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => this.openProxyDialog(editorState, ProjectFileProxyType.TopicToolbar, this.flareCommands?.insertTopicToolbarProxy),
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertTopicToolbarProxy(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.madcaptopictoolbarproxy)
      }, {
        type: 'button',
        group: 'proxies',
        icon: 'icon-insert-proxy',
        tooltip: 'Mini-TOC Proxy',
        dropdownText: 'Mini-TOC Proxy',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => this.openProxyDialog(editorState, ProjectFileProxyType.MiniTOC, this.flareCommands?.insertMiniTOCProxy),
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertMiniTOCProxy(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.madcapminitocproxy)
      }, {
        type: 'button',
        group: 'proxies',
        icon: 'icon-insert-proxy',
        tooltip: 'Glossary Proxy',
        dropdownText: 'Glossary Proxy',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => this.openProxyDialog(editorState, ProjectFileProxyType.Glossary, this.flareCommands?.insertGlossaryProxy),
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertGlossaryProxy(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.madcapglossaryproxy)
      }, {
        type: 'button',
        group: 'proxies',
        icon: 'icon-insert-proxy',
        tooltip: 'Search Bar Proxy',
        dropdownText: 'Search Bar Proxy',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => this.openProxyDialog(editorState, ProjectFileProxyType.SearchBar, this.flareCommands?.insertSearchBarProxy),
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertSearchBarProxy(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.madcapsearchbarproxy)
      }, {
        type: 'button',
        group: 'proxies',
        icon: 'icon-insert-proxy',
        tooltip: 'Menu Proxy',
        dropdownText: 'Menu Proxy',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => this.openProxyDialog(editorState, ProjectFileProxyType.Menu, this.flareCommands?.insertMenuProxy),
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertMenuProxy(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.madcapmenuproxy)
      }, {
        type: 'button',
        group: 'proxies',
        icon: 'icon-insert-proxy',
        tooltip: 'Flare Online Account Proxy',
        dropdownText: 'Flare Online Account Proxy',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => this.openProxyDialog(editorState, ProjectFileProxyType.CentralAccount, this.flareCommands?.insertCentralAccountProxy),
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertCentralAccountProxy(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.madcapcentralaccountproxy)
      }, {
        type: 'button',
        group: 'proxies',
        icon: 'icon-insert-proxy',
        tooltip: 'eLearning Toolbar Proxy',
        dropdownText: 'eLearning Toolbar Proxy',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => this.openProxyDialog(editorState, ProjectFileProxyType.ELearningToolbar, this.flareCommands?.insertELearningToolbarProxy),
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertELearningToolbarProxy(editorState),
        isActive: (editorState: EditorState) => selectionIsInNode(editorState.selection, this.schema.nodes.madcapelearningtoolbarproxy)
      }, {
        type: 'divider'
      }, {
        type: 'button',
        icon: 'icon-picture',
        tooltip: 'Insert Or Edit Image',
        dropdownText: 'Insert Or Edit Image',
        command: (editorState: EditorState) => this.openInsertImageDialog(editorState),
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertOrEditImage(editorState),
      }, {
        type: 'button',
        icon: 'icon-multimedia',
        tooltip: 'Insert Or Edit Multimedia',
        dropdownText: 'Insert Or Edit Multimedia',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => this.openMultimediaDialog(editorState),
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertOrEditMultimedia(editorState)
      }, {
        type: 'custom',
        icon: 'icon-chat-gpt',
        tooltip: 'AI Assist',
        templateName: 'AI',
        hidden: !this.showAI || this.fileType !== CollabFileType.Edit,
        isDisabled: (editorState: EditorState) => !this.checkIfAICanBeInserted(editorState),
      },
      {
        type: 'button',
        icon: 'icon-variable',
        tooltip: 'Insert Variable',
        hidden: this.fileType !== CollabFileType.Edit,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertVariable(editorState),
        command: (editorState: EditorState) => {
          const metaData: Dictionary<Dictionary> = editorState['metaData$']?.metaData;

          const projectVariableSets = this.metaDataService.extractVariableSetsFromMetaData(metaData);
          const hiddenSystemVariables = ['System.LinkedTitle', 'System.LinkedHeader', 'System.LinkedFile'];

          const selection = editorState.selection;
          let setName: string | undefined;
          let variableName: string | undefined;

          if (selection instanceof NodeSelection) {
            if (!selection.empty && selection.node.type.name === this.schema.nodes.madcapvariable.name) {
              [setName, variableName] = selection.node.attrs.name?.split('.') || [];
            }
          }

          this.openInsertProjectVariablesDialog(projectVariableSets, setName, variableName, hiddenSystemVariables);

          return true;
        },
      },
      {
        type: 'custom',
        icon: 'icon-snippet',
        tooltip: 'Insert Snippet',
        templateName: 'projectSnippet',
        hidden: this.fileType !== CollabFileType.Edit,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertSnippet(editorState),
      }, {
        type: 'custom',
        tooltip: 'Insert Table',
        templateName: 'table',
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertTable(editorState)
      }, {
        type: 'button',
        icon: 'icon-drop-down-text',
        tooltip: 'Insert Drop-Down',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState, dispatch?: ProsemirrorDispatcher) => this.flareCommands?.insertDropDown(editorState, dispatch) ?? false,
        isDisabled: (editorState: EditorState) => !this.flareCommands?.insertDropDown(editorState),
      }, {
        type: 'button',
        icon: 'icon-conditions-editor',
        tooltip: 'Apply Conditions',
        hidden: this.fileType !== CollabFileType.Edit,
        command: (editorState: EditorState) => {
          const metaData = editorState['metaData$']?.metaData;
          const projectConditionSets = this.metaDataService.extractConditionSetsFromMetaData(metaData);

          this.openApplyConditionsDialog(selectedConditions(editorState), projectConditionSets);
          return true;
        }
      }
    ], [
      {
        name: 'font',
        priority: 1,
        icon: 'icon-font-styles'
      }, {
        name: 'styles',
        priority: 2,
        icon: 'icon-paragraph'
      }, {
        name: 'lists',
        priority: 3,
        icon: 'icon-list'
      }, {
        name: 'proxies',
        priority: 4,
        icon: 'icon-insert-proxy',
        tooltip: 'Insert or Edit Proxies'
      }, {
        name: 'link',
        priority: 5,
        icon: 'icon-link'
      }, {
        name: 'annotate',
        priority: 6,
        icon: 'icon-review'
      }
    ]);
  }

  /** Gets input data for metaDataService. */
  private get committedItem() {
    return { commitId: this.commitId, fileType: this.fileType, projectId: this.projectId, fileId: this.fileId };
  }

  private sendGoogleAnalyticsEvent(buttonName: string): void {
    if (buttonName) {
      this.analyticsService.trackEvent('html_editor_action', {
        'event_category': 'HTML Editor',
        'event_label': `HTML Editor: ${buttonName}`,
        'license_id': this.licenseUser?.LicenseId
      });
    }
  }
}
