import { Injectable } from '@angular/core';
import themes from '@build/scss/themes.json';
import { ThemeColorPalette } from '@portal-core/general/models/theme-color-palette.model';
import { Theme } from '@portal-core/general/models/theme.model';
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  static TextColorSuffix = '-contrast';

  themes: Theme[] = themes;
  currentThemeName = 'peacock';

  constructor() { }

  getColor(palette: string | ThemeColorPalette, color: string = 'default', themeName: string = this.currentThemeName): string {
    if (typeof palette === 'string') {
      palette = this.getPalette(palette, themeName);
    }

    if (palette) {
      let isTextColor = false;

      if (color.endsWith(ThemeService.TextColorSuffix)) {
        isTextColor = true;
        color = color.substring(0, color.length - ThemeService.TextColorSuffix.length);
      }

      const foundColor = palette.colors.find(paletteColor => paletteColor.name === color || paletteColor.number === color);

      if (foundColor) {
        return foundColor[isTextColor ? 'textColor' : 'bgColor'];
      }
    }

    return null;
  }

  // Use when opacity is needed
  getRgbColor(palette: string | ThemeColorPalette, alpha?: number, color: string = 'default', themeName: string = this.currentThemeName): string {
    let hex = this.getColor(palette, color, themeName);
    hex = hex.replace('#', '');
    if (hex.length !== 3 && hex.length !== 6) {
      return;
    }
    const r = parseInt(hex.slice(0, hex.length / 3), 16);
    const g = parseInt(hex.slice(hex.length / 3, 2 * hex.length / 3), 16);
    const b = parseInt(hex.slice(2 * hex.length / 3, 3 * hex.length / 3), 16);

    if (alpha && alpha >= 0 && alpha <= 1) {
      return `rgba(${r}, ${g}, ${b}, ${alpha.toFixed(1)})`;
    } else {
      return `rgba(${r}, ${g}, ${b})`;
    }
  }

  getPalette(paletteName: string, themeName: string = this.currentThemeName): ThemeColorPalette {
    const theme = this.getTheme(themeName);

    if (theme) {
      return theme.palettes.find(palette => palette.name === paletteName) ||
        theme.aliasPalettes.find(palette => palette.name === paletteName) ||
        theme.simplePalettes.find(palette => palette.name === paletteName);
    }
  }

  getTheme(themeName: string = this.currentThemeName): Theme {
    return this.themes.find(theme => theme.name === themeName);
  }
}
