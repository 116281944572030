<mc-list [dataListId]="dataListId" [listControl]="listControl">
  <ng-template mcListItem let-licenseUser>
    <mat-list-item>
      <mc-license-user-avatar [licenseUser]="licenseUser" size="small" [truncate]="true" [showSeatType]="true"></mc-license-user-avatar>
      <button *ngIf="canEdit" type="button" mat-icon-button matTooltip="Remove User" [disabled]="licenseUser.Id | mcIncludedIn : removing" (click)="onRemoveLicenseUser(licenseUser)">
        <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-trash'"></mat-icon>
      </button>
    </mat-list-item>
  </ng-template>

  <ng-template mcEmptyList>
    <p>There are no License Users on this Team.</p>
  </ng-template>

  <ng-template mcAllItemsLoadedList>
    <p>All License Users loaded.</p>
  </ng-template>
</mc-list>