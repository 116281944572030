<mc-list [dataListId]="dataListId" [listControl]="listControl">
  <ng-template mcListItem let-site>
    <mat-list-item>
      <mc-site-avatar [site]="site" size="small" [truncate]="true"></mc-site-avatar>
      <button *ngIf="canEdit" type="button" mat-icon-button matTooltip="Remove Site" [disabled]="site.Id | mcIncludedIn : removing" (click)="onRemoveSite(site.Id)">
        <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-trash'"></mat-icon>
      </button>
    </mat-list-item>
  </ng-template>

  <ng-template mcEmptyList>
    <p>There are no Sites on this Team.</p>
  </ng-template>

  <ng-template mcAllItemsLoadedList>
    <p>All Sites loaded.</p>
  </ng-template>
</mc-list>