import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FlareOnlineAvatarComponent } from './flare-online-avatar.component';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  declarations: [
    FlareOnlineAvatarComponent
  ],
  exports: [
    FlareOnlineAvatarComponent
  ]
})
export class FlareOnlineAvatarModule { }
