import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { ProjectPageFilterConfig } from '@portal-core/projects/util/project-page-filter-config';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { ListControl } from '@portal-core/ui/list/util/list-control';
import { ListBase } from '@portal-core/ui/list/util/list.base';
import { of } from 'rxjs';

@Component({
  selector: 'mc-team-projects-list',
  templateUrl: './team-projects-list.component.html',
  styleUrls: ['./team-projects-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamProjectsListComponent extends ListBase<Project> implements OnChanges {
  @Input() teamId: number;
  @Input() canEdit: boolean = false;

  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  removing: number[] = [];

  filterConfig: ProjectPageFilterConfig = new ProjectPageFilterConfig(['Name', 'Initials']);

  listControl: ListControl<Project> = new ListControl<Project>(this.projectsService, filter => {
    if (typeof this.teamId === 'number') {
      return this.teamsService.getTeamProjects$(this.teamId, filter);
    } else {
      return of(null);
    }
  });

  constructor(
    private teamsService: TeamsService,
    private projectsService: ProjectsService,
    private snackBar: MatSnackBar
  ) {
    super(projectsService);

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.teamId) {
      this.hardReload();
    }
  }

  onRemoveProject(projectId: number) {
    this.removing = [
      ...this.removing,
      projectId
    ];
    this.projectsService.deleteProjectTeam$(projectId, this.teamId).subscribe(() => {
      this.projectsService.removePagedDataListItems$(this.dataListId, [projectId]);
      this.removing = this.removing.filter(id => id !== projectId);
      this.saved.emit();
    }, error => {
      this.removing = this.removing.filter(id => id !== projectId);
      this.snackBar.open('Unable to remove the project from the team.', 'OK');
    });
  }
}
