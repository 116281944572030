<form class="mc-spring-board" [formGroup]="linkForm" (ngSubmit)="onSubmit()">
  <mat-toolbar class="mc-spring-board-header" color="secondary">
    {{data.href ? 'Edit ' : 'Insert '}} {{usingCrossRef ? 'Cross-Reference' : 'Hyperlink'}}
    <span class="mc-fill-remaining-flex-space"></span>
    <button mat-icon-button type="button" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-form-body">
    <mat-form-field class="mc-form-field" appearance="standard" color="secondary">
      <mat-label>Link To</mat-label>
      <mat-select #linkTypeSelect formControlName="linkTo" placeholder="Link to">
        <mat-option [value]="'inProject'">
          {{usingCrossRef ? 'Topic In Project' : 'File In Project' }}
        </mat-option>
        <mat-option [value]="'inDocument'">
          Place In This Document
        </mat-option>
        <mat-option *ngIf="!usingCrossRef" [value]="'external'">
          External Link
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="linkTypeSelect.value ==='inProject'" appearance="standard" color="secondary" class="mc-form-field">
      <mat-label>File *</mat-label>
      <mc-project-file-picker-input [branchName]="commitId" rootFolder="Content" [fileFilter]="TopicFileFilter" [projectId]="projectId" (valueChange)="onFilePathSelected($event)" [value]="filePaths" [defaultPath]="filePath"></mc-project-file-picker-input>
    </mat-form-field>

    <mat-form-field *ngIf="linkTypeSelect.value ==='inDocument'" appearance="standard" color="secondary" class="mc-form-field">
      <mat-label>Place *</mat-label>
      <mc-document-node-selector-input formControlName="node" [document]="data.document">
      </mc-document-node-selector-input>
    </mat-form-field>

    <mat-form-field *ngIf="linkTypeSelect.value ==='external'" appearance="standard" class="mc-form-field" color="secondary">
      <mat-label>Link</mat-label>
      <input matInput type="text" formControlName="externalLink" placeholder="External Link">
    </mat-form-field>

    <mat-form-field *ngIf="!usingCrossRef" appearance="standard" class="mc-form-field" color="secondary">
      <mat-label>Text</mat-label>
      <input matInput type="text" formControlName="linkText" placeholder="Text">
      <mat-hint *ngIf="!selectionJustText" class=" mc-form-text mc-text-notice">Can't change text when selection contains XML tags.</mat-hint>
    </mat-form-field>

    <mat-expansion-panel class="mc-link-properties-dialog-panel mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="mc-link-properties-dialog-title"> Link Properties </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div *ngIf="!usingCrossRef" class="mc-form-fields-row">
        <mat-form-field appearance="standard" class="mc-form-field" color="secondary">
          <mat-label>Style Class</mat-label>
          <input matInput type="text" formControlName="class" placeholder="Style Class">
        </mat-form-field>

        <mat-form-field appearance="standard" class="mc-form-field" color="secondary">
          <mat-label>Style ID</mat-label>
          <input matInput type="text" formControlName="ID" placeholder="Style ID">
        </mat-form-field>
      </div>

      <div class="mc-form-fields-row">
        <mat-form-field appearance="standard" class="mc-form-field" appearance="standard" color="secondary">
          <mat-label>Target</mat-label>
          <mat-select formControlName="target" placeholder="Target">
            <mat-option *ngFor="let option of targetOptions" [value]="option.value">
              {{option.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!usingCrossRef" appearance="standard" class="mc-form-field" color="secondary">
          <mat-label>Tab Index</mat-label>
          <input matInput type="number" formControlName="tabindex" placeholder="Tab Index">
        </mat-form-field>
      </div>

      <div class="mc-form-fields-row">
        <mat-form-field appearance="standard" class="mc-form-field" color="secondary">
          <mat-label>Alternate Text</mat-label>
          <input matInput type="text" formControlName="alt" placeholder="Alternate Text">
        </mat-form-field>

        <mat-form-field appearance="standard" class="mc-form-field" color="secondary">
          <mat-label>Screen Tip</mat-label>
          <input matInput type="text" formControlName="title" placeholder="Screen Tip">
        </mat-form-field>
      </div>

    </mat-expansion-panel>

  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="loadingState.generalError$ | async as generalError" [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async" compact></mc-errors-expansion-panel>
    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="closeDialog()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="(linkToVal === 'inProject' && !link) || (!linkForm.value.node && linkToVal === 'inDocument')  || !linkForm.valid">
        {{data.href ? 'Save' : 'Insert'}}
      </button>
    </div>
  </div>

  <mc-loader *ngIf="loadingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>