<cdk-virtual-scroll-viewport [itemSize]="itemHeight" (scrolledIndexChange)="onScrollIndexChanged()" [ngSwitch]="listType">
  <mat-action-list *ngSwitchCase="ListType.Action" [ngClass]="listClass" [id]="listId">
    <!-- <ng-container *cdkVirtualFor="let item of items; trackBy: trackListItemBy" [ngTemplateOutlet]="listItemDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container> -->

    <!-- Ideally the above ng-container would be used but a bug in cdkVirtualFor messes up the item order. So the cdkVirtualFor is being used on a div to work around this -->
    <!-- This might be fixed in Angular 8+ and we should try to switch to the above ng-container when Angular is updated -->
    <div *cdkVirtualFor="let item of items; trackBy: trackListItemBy">
      <ng-container [ngTemplateOutlet]="listItemDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </mat-action-list>

  <mat-nav-list *ngSwitchCase="ListType.Nav" [ngClass]="listClass" [id]="listId">
    <!-- <ng-container *cdkVirtualFor="let item of items; trackBy: trackListItemBy" [ngTemplateOutlet]="listItemDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container> -->

    <!-- Ideally the above ng-container would be used but a bug in cdkVirtualFor messes up the item order. So the cdkVirtualFor is being used on a div to work around this -->
    <!-- This might be fixed in Angular 8+ and we should try to switch to the above ng-container when Angular is updated -->
    <div *cdkVirtualFor="let item of items; trackBy: trackListItemBy">
      <ng-container [ngTemplateOutlet]="listItemDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </mat-nav-list>

  <mat-list *ngSwitchCase="ListType.Select" class="mc-list-activatable" [ngClass]="listClass" role="listbox" [id]="listId">
    <div *cdkVirtualFor="let item of items; trackBy: trackListItemBy; index as index">
      <mc-list-option *ngIf="!selectionListControl?.buildLink" [id]="'mc-list-option-' + index" [value]="selectionListControl?.getValue(item)" (selectedChange)="onSelectListOptionSelectedChanged($event)">
        <ng-container [ngTemplateOutlet]="listItemDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      </mc-list-option>
      <a *ngIf="selectionListControl?.buildLink" mc-list-option role="link" [id]="'mc-list-option-' + index" [value]="selectionListControl?.getValue(item)" [routerLink]="selectionListControl?.buildLink(item)" [queryParams]="selectionListControl?.buildQueryParams ? selectionListControl.buildQueryParams(item) : null" (selectedChange)="onSelectListOptionSelectedChanged($event)">
        <ng-container [ngTemplateOutlet]="listItemDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      </a>
    </div>
  </mat-list>

  <mat-list *ngSwitchCase="ListType.Standard" [ngClass]="listClass" [id]="listId">
    <!-- <ng-container *cdkVirtualFor="let item of items; trackBy: trackListItemBy" [ngTemplateOutlet]="listItemDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container> -->

    <!-- Ideally the above ng-container would be used but a bug in cdkVirtualFor messes up the item order. So the cdkVirtualFor is being used on a div to work around this -->
    <!-- This might be fixed in Angular 8+ and we should try to switch to the above ng-container when Angular is updated -->
    <div *cdkVirtualFor="let item of items; trackBy: trackListItemBy">
      <ng-container [ngTemplateOutlet]="listItemDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </mat-list>

  <div *ngIf="allItemsLoaded && allItemsLoadedListDirective && items?.length > 0" class="mc-infinite-list-all-items-loaded" [style.height]="itemHeight + 'px'">
    <ng-container [ngTemplateOutlet]="allItemsLoadedListDirective?.templateRef"></ng-container>
  </div>
</cdk-virtual-scroll-viewport>

<div *ngIf="items?.length === 0 && emptyListDirective" class="mc-infinite-list-empty">
  <ng-container [ngTemplateOutlet]="emptyListDirective?.templateRef"></ng-container>
</div>

<mc-loader *ngIf="loading" [backgroundColor]="loaderBackgroundColor" [color]="loaderColor"></mc-loader>
<div *ngIf="loadingGeneralError" class="error-pane error-pane-transparent">
  <mc-errors-expansion-panel [generalError]="loadingGeneralError" [detailedErrors]="loadingDetailedErrors" (retry)="onRetryLoad()"></mc-errors-expansion-panel>
</div>

<mat-progress-bar *ngIf="loadingMore" color="accent-vivid" mode="indeterminate" class="mc-infinite-list-loading-more-bar"></mat-progress-bar>
<div *ngIf="loadingMoreGeneralError" class="error-pane mc-infinite-list-loading-more-error-pane">
  <mc-errors-expansion-panel compact [generalError]="loadingMoreGeneralError" [detailedErrors]="loadingMoreDetailedErrors"></mc-errors-expansion-panel>
</div>