<form class="mc-spring-board" [formGroup]="AIForm" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-form-body">
    <mat-form-field appearance="standard" class="mc-form-field" color="secondary">
      <mat-label>API Key</mat-label>
      <input type="text" matInput formControlName="key" placeholder="API Key" [type]="hide ? 'password' : 'text'">
      <mat-error>
        <mc-form-control-error-message [form]="AIForm" controlName="key" type="mc_open_ai_settings"></mc-form-control-error-message>
      </mat-error>
      <mat-icon fontSet="project-titan" matSuffix fontIcon="icon-eye-open" (click)="hide = !hide"></mat-icon>
    </mat-form-field>
    <mat-form-field appearance="standard" class="mc-form-field" color="secondary">
      <mat-label>Version</mat-label>
      <mat-select matInput formControlName="version">
        <mat-option *ngFor="let model of models" [value]="model.value">
          {{model.text}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <p class="mc-form-text">
      <span class="text-medium-weight">Note: </span>
      MadCap Software is not responsible for results you might receive from ChatGPT, which is a third-party tool. This includes the possibility of occasional inaccuracies, bias in training data, and privacy/security issues. Please refer to the OpenAI privacy and security policies, as well as their documentation, for more information.
    </p>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>
    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button class="mc-dialog-card-action-button" (click)="cancel.emit(true)">Cancel</button>
      <button type="submit" mat-raised-button class="mc-dialog-card-action-button" color="primary" [disabled]="(savingState.loading$ | async) || AIForm.pristine">Save</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>