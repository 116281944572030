<mat-toolbar *ngIf="!asset.IsDeleted" class="mc-toolbar-small" [color]="isNew ? 'secondary-muted' : 'secondary'">
  <mat-icon class="mc-self-centered-icon mc-flex-shrink-0" color="secondary-invert" fontSet="project-titan" fontIcon="icon-attach"></mat-icon>

  <span class="mc-toolbar-spacer-small"></span>

  <span *ngIf="isNew" class="mc-text-invert text-small mc-toolbar-text" [matTooltip]="asset.FileName">
    <span class="mc-toolbar-text-truncate">{{ asset.FileName }}</span>
  </span>
  <a *ngIf="!isNew" [href]="url" class="mc-text-invert text-small mc-toolbar-text" target="_blank" [matTooltip]="asset.FileName">
    <span class="mc-toolbar-text-truncate">{{ asset.FileName }}</span>
  </a>

  <span class="mc-toolbar-spacer-small"></span>

  <span class="mc-text-invert text-small mc-flex-shrink-0">({{ asset.SizeInBytes | mcFileSize }})</span>

  <span class="mc-toolbar-spacer-small"></span>
  <span class="mc-fill-remaining-flex-space"></span>

  <button *ngIf="!isNew || canEditAllTasks" mat-icon-button type="button" color="secondary-invert" class="mc-task-asset-options-button" [matMenuTriggerFor]="menu">
    <mat-icon fontSet="project-titan" fontIcon="icon-meatballs-solid"></mat-icon>
  </button>

  <mat-menu #menu="matMenu" class="mc-menu popup-card" [overlapTrigger]="false">
    <ng-template matMenuContent>
      <button *ngIf="!isNew" type="button" mat-menu-item (click)="onDownload()">Download</button>
      <button *ngIf="canEditAllTasks" type="button" mat-menu-item (click)="onDelete()">Delete</button>
    </ng-template>
  </mat-menu>
</mat-toolbar>