<form class="mc-spring-board" [formGroup]="proxyForm" (ngSubmit)="onSubmit()">

  <mat-toolbar class="mc-spring-board-header" color="secondary">
    {{data.isEdit ? 'Edit' : 'Insert'}} {{proxyTypeText}} Proxy
    <span class="mc-fill-remaining-flex-space"></span>
    <button mat-icon-button type="button" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-form-body">
    <p class="mc-form-note" *ngIf="!!headerText">{{headerText}}</p>

    <!-- Menu -->
    <ng-container *ngIf="type === ProjectFileProxyType.Menu">
      <mat-form-field appearance="standard" color="secondary" class="mc-form-field">
        <mat-label>TOC, Browse Sequence, or Headings</mat-label>
        <mat-select formControlName="menuTOC">
          <ng-container *ngIf="TOCs$ | async as TOCs; else loading">
            <mat-option value="">(default)</mat-option>
            <mat-option value="$topicHeadings">(Headings)</mat-option>
            <mat-option *ngFor="let TOC of TOCs" [value]="TOC">
              {{formatMenuTOC(TOC)}}
            </mat-option>
          </ng-container>
          <ng-template #loading>
            <!-- Give the loader a tall enough background to fit -->
            <mat-option class="mc-project-files-proxy-dialog-select-loading-filler">
              <mc-loader></mc-loader>
            </mat-option>
          </ng-template>
        </mat-select>
      </mat-form-field>

      <mat-checkbox class="mc-project-files-proxy-dialog-menu-checkbox" color="accent-vivid" formControlName="menuContextSensitive">Context Sensitive</mat-checkbox>
      <mat-checkbox class="mc-project-files-proxy-dialog-menu-checkbox" color="accent-vivid" formControlName="menuParent">Include parent</mat-checkbox>
      <mat-checkbox class="mc-project-files-proxy-dialog-menu-checkbox" color="accent-vivid" formControlName="menuSiblings">Include siblings</mat-checkbox>
      <mat-checkbox class="mc-project-files-proxy-dialog-menu-checkbox" color="accent-vivid" formControlName="menuChildren">Include children</mat-checkbox>
    </ng-container>

    <!-- Menu, Mini TOC -->
    <mat-form-field *ngIf="type === ProjectFileProxyType.Menu || type === ProjectFileProxyType.MiniTOC" appearance="standard" color="secondary" class="mc-form-field">
      <mat-label>{{tocDepthLabel}}</mat-label>
      <mat-select formControlName="tocDepth">
        <mat-option [value]="-2">(default)</mat-option>
        <mat-option *ngIf="type === ProjectFileProxyType.Menu" [value]="-1">(all)</mat-option>
        <mat-option *ngFor="let depth of tocDepthPresets" [value]="depth">
          {{depth}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <p class="mc-form-note" *ngIf="type === ProjectFileProxyType.MiniTOC">Set the number of heading levels to show in the TOC.</p>

    <!-- Topic toolbar, Search Bar, Menu, Flare Online Account, eLearning Toolbar -->
    <mat-form-field *ngIf="type === ProjectFileProxyType.TopicToolbar || type === ProjectFileProxyType.SearchBar || type === ProjectFileProxyType.Menu || type === ProjectFileProxyType.CentralAccount || type === ProjectFileProxyType.ELearningToolbar" appearance="standard" color="secondary" class="mc-form-field">
      <mat-label>Skin</mat-label>
      <mat-select formControlName="skinFilePath">
        <ng-container *ngIf="skinFilePaths$ | async as paths; else loading">
          <mat-option value="">(default)</mat-option>
          <mat-option *ngFor="let path of paths" [value]="path">
            {{path}}
          </mat-option>
        </ng-container>
        <ng-template #loading>
          <!-- Give the loader a tall enough background to fit -->
          <mat-option class="mc-project-files-proxy-dialog-select-loading-filler">
            <mc-loader></mc-loader>
          </mat-option>
        </ng-template>
      </mat-select>
    </mat-form-field>

    <!-- Glossary -->
    <mat-form-field *ngIf="type === ProjectFileProxyType.Glossary" appearance="standard" color="secondary" class="mc-form-field">
      <mat-label *ngIf="type === ProjectFileProxyType.Glossary">Generate Headings</mat-label>
      <mat-select formControlName="glossaryHeading">
        <mat-option value="">(default)</mat-option>
        <mat-option [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Body, Breadcrumbs, Topic Toolbar, Mini Toc, Glossary -->
    <mat-form-field *ngIf="type === ProjectFileProxyType.Body || type === ProjectFileProxyType.Breadcrumbs || type === ProjectFileProxyType.TopicToolbar || type === ProjectFileProxyType.MiniTOC || type === ProjectFileProxyType.Glossary" appearance="standard" color="secondary" class="mc-form-field">
      <mat-label>Stylesheet class</mat-label>
      <input matInput type="text" formControlName="stylesheetClass" placeholder="Class name">
      <mat-error>
        <mc-form-control-error-message [form]="proxyForm" controlName="stylesheetClass" type="mc_proxy"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <!-- Topic Toolbar -->
    <!-- <mat-form-field *ngIf="type === ProjectFileProxyType.TopicToolbar" appearance="standard" class="mc-form-field" color="secondary">
      <mat-label>Buttons</mat-label>
      <input matInput type="text" formControlName="toolbarButtons" placeholder="Buttons">
      <mat-error>
        <mc-form-control-error-message [form]="proxyForm" controlName="toolbarButtons" type="mc_proxy"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field> -->
    <p class="mc-form-note" *ngIf="!!footerText">{{footerText}}</p>
  </div>

  <div class="mc-spring-board-form-footer">
    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="closeDialog()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="!proxyForm.valid">
        {{data.isEdit ? 'Save' : 'Insert'}}
      </button>
    </div>
  </div>
</form>