import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { LayoutModule } from '@angular/cdk/layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppNavbarLoggedOutLaunchPadModule } from '@env/app/app/components/app-navbar-logged-out-launch-pad/app-navbar-logged-out-launch-pad.module';
import { AppNavbarUserLaunchPadModule } from '@portal-client/app/components/app-navbar-user-launch-pad/app-navbar-user-launch-pad.module';
import { CoreModule } from '@portal-client/core/core.module';
import { DialogsModule } from '@portal-core/dialogs/dialogs.module';
import { EmptyModule } from '@portal-core/general/components/empty/empty.module';
import { StopPropagationModule } from '@portal-core/general/directives/stop-propagation/stop-propagation.module';
import { IdleLogoutDialogModule } from '@portal-core/idle-logout/components/idle-logout-dialog/idle-logout-dialog.module';
import { FullPageModule } from '@portal-core/layout/components/full-page/full-page.module';
import { NavbarHelpButtonModule } from '@portal-core/layout/components/navbar-help-button/navbar-help-button.module';
import { SubPageFillModule } from '@portal-core/layout/components/sub-page-fill/sub-page-fill.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { SystemMessageCardsModule } from '@portal-core/system-messages/components/system-message-cards/system-message-cards.module';
import { SystemMessageListModule } from '@portal-core/system-messages/components/system-message-list/system-message-list.module';
import { IfAuthorModule } from '@portal-core/user-access/directives/if-author/if-author.module';
import { IfSmeModule } from '@portal-core/user-access/directives/if-sme/if-sme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppAboutDialogComponent } from './components/app-about-dialog/app-about-dialog.component';
import { AppDrawerGutterComponent } from './components/app-drawer-gutter/app-drawer-gutter.component';
import { AppDrawerNavComponent } from './components/app-drawer-nav/app-drawer-nav.component';
import { AppDrawerUserLaunchPadComponent } from './components/app-drawer-user-launch-pad/app-drawer-user-launch-pad.component';
import { AppFullNavbarHeadingComponent } from './components/app-full-navbar-heading/app-full-navbar-heading.component';
import { AppGutterComponent } from './components/app-gutter/app-gutter.component';
import { AppNavHasVisitedChipComponent } from './components/app-nav-has-visited-chip/app-nav-has-visited-chip.component';
import { AppNavComponent } from './components/app-nav/app-nav.component';
import { AppRootComponent } from './components/app-root/app-root.component';

@NgModule({
  declarations: [
    AppRootComponent,
    AppDrawerUserLaunchPadComponent,
    AppNavComponent,
    AppFullNavbarHeadingComponent,
    AppGutterComponent,
    AppAboutDialogComponent,
    AppDrawerNavComponent,
    AppDrawerGutterComponent,
    AppNavHasVisitedChipComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    EmptyModule,
    FullPageModule,
    IfAuthorModule,
    IfSmeModule,
    NavbarHelpButtonModule,
    LayoutModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatTabsModule,
    MatToolbarModule,
    MatFormFieldModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    StopPropagationModule,
    SubPageFillModule,
    SystemMessageListModule,
    LicenseUserAvatarModule,
    DialogsModule,
    SystemMessageCardsModule,
    SystemMessageListModule,
    AppNavbarUserLaunchPadModule,
    AppNavbarLoggedOutLaunchPadModule,
    IdleLogoutDialogModule,
    AppRoutingModule // Last Import (AppRoutingModule needs to be the last import in the imports array)
  ],
  bootstrap: [
    AppRootComponent
  ]
})
export class AppModule { }
