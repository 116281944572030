import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';

/**
 * mc-flare-online-avatar
 * A component for displaying the flare online avatar image and name.
 */
@Component({
  selector: 'mc-flare-online-avatar',
  templateUrl: './flare-online-avatar.component.html',
  styleUrls: ['./flare-online-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlareOnlineAvatarComponent extends AvatarBase {
  @Input() name?: string = 'Flare Online';

  hasAvatarImage(): boolean {
    return true;
  }

  hasPermission(): boolean {
    return true;
  }

  isDeleted(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return false;
  }

  isLink(): boolean {
    return false;
  }
}

