<mc-list [dataListId]="dataListId" [listControl]="listControl">
  <ng-template mcListItem let-project>
    <div matTooltip="You cannot revoke a team's access from an archived project. You must unarchive the project first, then revoke the team's access." matTooltipClass="mc-tooltip-snug" [matTooltipDisabled]="project.Status !== 0">
      <mat-list-item>
        <mc-project-avatar [project]="project" size="small" [truncate]="true"></mc-project-avatar>
        <button *ngIf="canEdit" type="button" mat-icon-button matTooltip="Remove Project" [disabled]="project.Status === 0 || (project.Id | mcIncludedIn : removing)" (click)="onRemoveProject(project.Id)">
          <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-trash'"></mat-icon>
        </button>
      </mat-list-item>
    </div>
  </ng-template>

  <ng-template mcEmptyList>
    <p>There are no Projects on this Team.</p>
  </ng-template>

  <ng-template mcAllItemsLoadedList>
    <p>All Projects loaded.</p>
  </ng-template>
</mc-list>