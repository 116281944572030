<button type="button" mat-icon-button class="mc-dialog-floating-close-button" tabindex="-1" (click)="closeDialog()">
  <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
</button>

<mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

<div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>

<mc-switch-board color="secondary">
  <mc-switch-board-bar class="mc-spring-board">
    <ng-container *ngIf="build$ | async as build">
      <div class="mc-spring-board-header">
        <mc-build-avatar [build]="build" [disabled]="true"></mc-build-avatar>
      </div>

      <div class="mc-spring-board-body">
        <ul class="mc-property-list mc-profile-switch-board-bar-section">
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Project: </span>
            <mc-project-avatar class="mc-property-value" [project]="project$ | async" [showAvatar]="false" [truncate]="true"></mc-project-avatar>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Target: </span>
            <mc-target-avatar class="mc-property-value" [branchName]="build.BranchName" [project]="project$ | async" [targetPath]="build.TargetPath" [showAvatar]="false" [truncate]="true"></mc-target-avatar>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Branch: </span>
            <mc-branch-avatar class="mc-property-value" [branchName]="build.BranchName" [project]="project$ | async" [showAvatar]="false" [truncate]="true"></mc-branch-avatar>
          </li>
        </ul>

        <p *ngIf="build.LinkedToLiveSite" class="mc-profile-switch-board-bar-section text-light-weight">
          <mat-icon fontSet="project-titan" fontIcon="icon-linked-to-live-site" class="mc-centered-text-icon"></mat-icon> &nbsp;
          Linked to a "Live" site
        </p>

        <p class="mc-profile-switch-board-bar-section text-light-weight">
          <ng-container *ngIf="build.IsKept || build.LinkedToLiveSite">
            <mat-icon *ngIf="build.IsKept || build.LinkedToLiveSite" fontSet="project-titan" fontIcon="icon-completed" class="mc-centered-text-icon"></mat-icon> &nbsp;
          </ng-container>
          {{ build.IsKept || build.LinkedToLiveSite ? 'Set' : 'Not set' }} to "Keep"
        </p>

        <p *ngIf="build.LastNotification" [ngClass]="getTextColorClassForBuildState(build)">
          <ng-container [ngSwitch]="build.LastNotification?.ProcessState">
            <ng-container *ngSwitchCase="ProcessState.Complete">
              <mat-icon fontSet="project-titan" fontIcon="icon-checkmark" class="mc-centered-text-icon"></mat-icon> &nbsp;
              Completed
            </ng-container>
            <ng-container *ngSwitchCase="ProcessState.Cancelled">
              <mat-icon fontSet="project-titan" fontIcon="icon-stopbuildcommand" class="mc-centered-text-icon"></mat-icon> &nbsp;
              Canceled
            </ng-container>
            <ng-container *ngSwitchCase="ProcessState.Failed">
              <mat-icon fontSet="project-titan" fontIcon="icon-close" class="mc-centered-text-icon"></mat-icon> &nbsp;
              Failed
            </ng-container>
          </ng-container>

          <ng-container *ngIf="build.IsStopped">
            after {{ build.RanFor | mcDuration }}
          </ng-container>
        </p>
      </div>

      <mat-nav-list class="mc-spring-board-footer mc-list-text-size-normal mc-list-text-weight-normal mc-list-bg-dark mc-list-compact">
        <ng-container *ngIf="showCancelBuildTab$ | async">
          <a href="#" mat-list-item mcPreventDefault="click" (click)="onCancelBuildClicked(build)">
            <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-stopbuildcommand" color="warn-muted"></mat-icon> Cancel Build
          </a>
          <mat-divider></mat-divider>
        </ng-container>
        <a href="#" mat-list-item mcPreventDefault="click" [class.mc-list-item-selected]="buildProfileTab === BuildProfileTab.Overview" (click)="buildProfileTab = BuildProfileTab.Overview">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-overview" color="primary"></mat-icon> Overview
        </a>
        <a *ngIf="showSettingsTab$ | async" href="#" mat-list-item mcPreventDefault="click" [class.mc-list-item-selected]="buildProfileTab === BuildProfileTab.Settings" (click)="buildProfileTab = BuildProfileTab.Settings">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-gear" color="primary"></mat-icon> Settings
        </a>
        <a *ngIf="showLogTab$ | async" href="#" mat-list-item mcPreventDefault="click" [class.mc-list-item-selected]="buildProfileTab === BuildProfileTab.Log" (click)="buildProfileTab = BuildProfileTab.Log">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-openbuildlogcommand" color="primary"></mat-icon> View Log
        </a>
        <a *ngIf="showDownloadLogTab$ | async" href="#" mat-list-item mcPreventDefault="click" (click)="onDownloadLogClicked(build)">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-download" color="primary"></mat-icon> Download Log
        </a>
        <a *ngIf="showViewOutputTab$ | async" href="#" mat-list-item mcPreventDefault="click" (click)="onViewOutputClicked(build)">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-eye-open" color="primary"></mat-icon> View Output
        </a>
        <a *ngIf="showDownloadBuildTab$ | async" href="#" mat-list-item mcPreventDefault="click" (click)="onDownloadBuildClicked(build)">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-download" color="primary"></mat-icon> Download Build
        </a>
        <a *ngIf="showDeleteTab$ | async" href="#" mat-list-item mcPreventDefault="click" [class.mc-list-item-selected]="buildProfileTab === BuildProfileTab.Delete" (click)="buildProfileTab = BuildProfileTab.Delete">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-trash" color="primary"></mat-icon> Delete Build
        </a>
      </mat-nav-list>
    </ng-container>
  </mc-switch-board-bar>

  <mc-switch-board-content [ngSwitch]="buildProfileTab" class="mc-spring-board">
    <mat-tab-group *ngSwitchCase="BuildProfileTab.Overview" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Overview">
        <mc-build-overview [build]="build$ | async"></mc-build-overview>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="BuildProfileTab.Settings" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Settings">
        <mc-build-settings-form [build]="build$ | async" (cancel)="onCancel()" (saved)="onSaved(BuildProfileForm.Settings)"></mc-build-settings-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="BuildProfileTab.Log" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Messages">
        <mc-build-messages-list [buildId]="buildId"></mc-build-messages-list>
      </mat-tab>

      <mat-tab label="Warnings">
        <mc-build-logs-grid [buildId]="buildId" gridId="build-logs-dialog-warnings" [logMessageType]="ProcessLogMessageType.Warning"></mc-build-logs-grid>
      </mat-tab>

      <mat-tab label="Errors">
        <mc-build-logs-grid [buildId]="buildId" gridId="build-logs-dialog-errors" [logMessageType]="ProcessLogMessageType.Error"></mc-build-logs-grid>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="BuildProfileTab.Delete" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Delete Build">
        <mc-build-deletion-form [build]="build$ | async" (cancel)="onCancel()" (deleted)="onSaved(BuildProfileForm.Delete)"></mc-build-deletion-form>
      </mat-tab>
    </mat-tab-group>
  </mc-switch-board-content>
</mc-switch-board>