import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { _getLegacyOptionScrollPosition as _getOptionScrollPosition } from '@angular/material/legacy-core';
import { DomService } from '@portal-core/general/services/dom.service';
import { ListOptionComponent } from '@portal-core/ui/list/components/list-option/list-option.component';
import { AllItemsLoadedListDirective } from '@portal-core/ui/list/directives/all-items-loaded-list/all-items-loaded-list.directive';
import { EmptyListDirective } from '@portal-core/ui/list/directives/empty-list/empty-list.directive';
import { ListItemDirective } from '@portal-core/ui/list/directives/list-item/list-item.directive';
import { ListType } from '@portal-core/ui/list/enums/list-type.enum';
import { ListOptionSelectionChangeEvent } from '@portal-core/ui/list/types/list-option-selection-change-event.type';
import { SelectionListControl } from '@portal-core/ui/list/util/selection-list-control';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

/**
 * InfiniteListComponent
 *
 * Renders an infinite list of items.
 * This is a dumb component that does not load its own data but instead expects to be given its data, loading, and error state.
 */
@Component({
  selector: 'mc-infinite-list',
  templateUrl: './infinite-list.component.html',
  styleUrls: ['./infinite-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class InfiniteListComponent implements AfterViewInit {
  /** Whether all the items have been loaded for the list. */
  @Input() allItemsLoaded: boolean;

  /** The items in the list. */
  @Input() items: any[];

  /** The height of every item in the list. Defaults to the standard list item height (48) */
  @Input()
  get itemHeight(): number {
    return this._itemHeight;
  }
  set itemHeight(value: number) {
    this._itemHeight = coerceNumberProperty(value);
  }
  private _itemHeight: number = 48; // The default height for list items

  /** Class to be added to the list element */
  @Input() listClass: string;

  /** The type of list to display */
  @Input() listType: ListType = ListType.Standard;

  /** The background color of the loader. */
  @Input() loaderBackgroundColor: 'body' | 'component';

  /** The foreground color of the loader. */
  @Input() loaderColor?: string = 'accent-vivid';

  /** Whether the list is doing an initial load. This will cause the loading indicator to show. */
  @Input() loading: boolean;

  /** The detailed errors from loading. This will cause the detailed errors to show but only if the general error is also set. */
  @Input() loadingDetailedErrors: string[];

  /** The general error from loading. This will cause the general error to show. */
  @Input() loadingGeneralError: string;

  /** Whether the list is loading more. This will cause the loading more bar to show. */
  @Input() loadingMore: boolean;

  /** The detailed errors from loading more. This will cause the detailed errors to show but only if the general error is also set. */
  @Input() loadingMoreDetailedErrors: string[];

  /** The general error from loading more. This will cause the general error to show. */
  @Input() loadingMoreGeneralError: string;

  /** The control for getting values for a mat-list-option. */
  @Input() selectionListControl?: SelectionListControl<any>;

  /** Emits when the list is scrolled to the end. */
  @Output() endScroll: EventEmitter<void> = new EventEmitter<void>();

  /** Emits when there is a selection change in a Select list. */
  @Output() selectionChange: EventEmitter<ListOptionComponent[]> = new EventEmitter<ListOptionComponent[]>();

  /** Emits when the retry button is clicked in the loading errors expansion panel. */
  @Output() retryLoad: EventEmitter<void> = new EventEmitter<void>();

  @ContentChild(AllItemsLoadedListDirective) allItemsLoadedListDirective: AllItemsLoadedListDirective;
  @ContentChild(EmptyListDirective) emptyListDirective: EmptyListDirective;
  @ContentChild(ListItemDirective) listItemDirective: ListItemDirective;
  @ViewChild(CdkVirtualScrollViewport, { static: true }) virtualScrollViewport: CdkVirtualScrollViewport;
  @ViewChildren(ListOptionComponent) listItemOptions: QueryList<ListOptionComponent>;

  ListType: typeof ListType = ListType;

  /** Manages active item in option list based on key events. */
  keyManager: ActiveDescendantKeyManager<ListOptionComponent>;
  /** An id given to the underlying list that can be used for a11y purposes. */
  readonly listId: string = uuidv4();
  private keyManagerChangeSubscription: Subscription;

  constructor(private domService: DomService) { }

  ngAfterViewInit() {
    if (this.listType === ListType.Select) {
      this.keyManager = new ActiveDescendantKeyManager<ListOptionComponent>(this.listItemOptions);

      // Listen to active item changes from the key manager so that the item can be scrolled into view
      this.keyManagerChangeSubscription = this.keyManager.change.subscribe(activeItemIndex => {
        if (activeItemIndex >= 0) {
          const option = this.listItemOptions.toArray()[activeItemIndex];
          const optionElement = option.getElementRef().nativeElement;
          const optionElementOffsetTop = this.domService.offsetFrom(optionElement, this.virtualScrollViewport.elementRef.nativeElement).top;
          const newScrollTop = _getOptionScrollPosition(optionElementOffsetTop, optionElement.offsetHeight, this.virtualScrollViewport.measureScrollOffset('top'), this.virtualScrollViewport.getViewportSize());

          this.virtualScrollViewport.scrollTo({ top: newScrollTop, behavior: 'auto' });
        }
      });
    }
  }

  /** Handles the on scroll index changed event from the virtual scroll viewport. If at the end of the list then the endScroll event is emitted. */
  onScrollIndexChanged() {
    const endIndex = this.virtualScrollViewport.getRenderedRange().end;
    const totalItems = this.virtualScrollViewport.getDataLength();

    if (endIndex >= totalItems && endIndex !== 0 && totalItems !== 0) {
      this.endScroll.emit();
    }
  }

  /** Re-emits the selection change event from a list option for Select lists. */
  onSelectListOptionSelectedChanged(event: ListOptionSelectionChangeEvent) {
    this.selectionChange.emit([event.option]);
  }

  /** Handles the retry event from the errors expansion panel. */
  onRetryLoad() {
    this.retryLoad.emit();
  }

  /** The trackBy method for cdkVirtualFor. Tracks the items by their id. */
  trackListItemBy(index: number, item: any): any {
    return item ? item.Id : undefined;
  }

  /** Updates the viewport dimensions of the virtual scroll viewport and re-renders. */
  checkViewportSize() {
    if (this.virtualScrollViewport) {
      this.virtualScrollViewport.checkViewportSize();
    }
  }

  /** Removes the active item from the key manager. */
  clearKeyManagerActiveItem() {
    this.keyManager?.setActiveItem(-1);
  }

  /** sets the active item from the key manager. */
  setKeyManagerActiveItem(activeOptionOrIndex: any) {
    this.keyManager?.setActiveItem(activeOptionOrIndex);
  }

  /** Emulates a click on the active item of the list's key manager. */
  clickKeyManagerActiveItem() {
    if (this.keyManager?.activeItem) {
      this.keyManager.activeItem.click();
    }
  }
}
