<mat-dialog-content class="mc-app-about-dialog-content mc-brand-gradient">
  <img alt="Flare Online Logo" class="mc-app-about-dialog-logo-image" src="assets/images/madcap-flare-online-logo.svg">
  <img class="mc-app-about-dialog-central-text-logo" alt="Flare Online Text Logo" src="assets/images/madcap-flare-online-text-logo-white.svg">
  <p class="mc-app-about-dialog-version mc-text-invert">{{ appVersionAndBuildDate$ | async }}</p>
  <p class="mc-app-about-dialog-copyright mc-text-invert">Copyright &copy; {{ currentYear }} MadCap Software Inc. All Rights Reserved.</p>
  <p class="mc-app-about-dialog-licenses"><a class="mc-text-invert" href="assets/text/disclosure.txt" target="_blank">Third Party Software and Notices</a></p>

  <button mat-icon-button color="secondary-invert" class="mc-app-about-dialog-close-button" tabindex="-1" matDialogClose>
    <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
  </button>
</mat-dialog-content>