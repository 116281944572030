import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { Project } from '@portal-core/projects/models/project.model';
import { Site } from '@portal-core/sites/models/site.model';
import { Team } from '@portal-core/teams/models/team.model';
import { User } from '@portal-core/users/models/user.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getTeamsByLicenseId$(licenseId: number): Observable<Team[]> {
    return this.http.get<Team[]>(`${this.apiService.centralApiBaseUri}/api/Teams/License/${licenseId}`).pipe(
      map((res: any) => {
        return res.Items as Team[];
      })
    );
  }

  @SharePendingRequest()
  getTeamsWithSitesByLicenseId$(licenseId: number): Observable<Team[]> {
    return this.http.get<Team[]>(`${this.apiService.centralApiBaseUri}/api/Teams/License/${licenseId}/Sites`);
  }

  @SharePendingRequest()
  getTeamById$(itemId: number): Observable<Team> {
    return this.http.get<Team>(`${this.apiService.centralApiBaseUri}/api/Teams/${itemId}`);
  }

  @SharePendingRequest()
  getTeamsByIds$(itemIds: number[]): Observable<Team[]> {
    return this.http.post<Team[]>(`${this.apiService.centralApiBaseUri}/api/teams/getBatchTeamsByIds`, itemIds);
  }

  @SharePendingRequest()
  getTeamsPageByLicenseId$(licenseId: number, teamListLimit: number, filter: PageFilter): Observable<Page<Team>> {
    return this.http.post<Page<Team>>(`${this.apiService.centralApiBaseUri}/api/Teams/License/${licenseId}/GetTeamsByLicenseList/${teamListLimit}`, filter);
  }

  @SharePendingRequest()
  getTeamSites$(teamId: number, filter: PageFilter): Observable<Page<Site>> {
    return this.http.post<Page<Site>>(`${this.apiService.centralApiBaseUri}/api/Teams/${teamId}/sites`, filter);
  }

  @SharePendingRequest()
  getTeamProjects$(teamId: number, filter: PageFilter): Observable<Page<Project>> {
    return this.http.post<Page<Project>>(`${this.apiService.centralApiBaseUri}/api/Teams/${teamId}/projects`, filter);
  }

  @SharePendingRequest()
  getTeamUsers$(teamId: number): Observable<User[]> {
    return this.http.get<any>(`${this.apiService.centralApiBaseUri}/api/Teams/${teamId}/users`);
  }

  @SharePendingRequest()
  getTeamLicenseUsers$(teamId: number, filter: PageFilter): Observable<Page<LicenseUser>> {
    return this.http.post<Page<LicenseUser>>(`${this.apiService.centralApiBaseUri}/api/Teams/${teamId}/licenseusers`, filter);
  }

  @SharePendingRequest()
  getTeamsByUserAndLicense$(userId: string, licenseId: number): Observable<Team[]> {
    const params = new HttpParams().set('licenseId', licenseId.toString());
    return this.http.get<Team[]>(`${this.apiService.centralApiBaseUri}/api/Teams/User/${userId}/getTeamsByUserAndLicense`, { params: params }).pipe(
      map((res: any) => {
        return res.Items as Team[];
      })
    );
  }

  @SharePendingRequest()
  verifyTeamName$(licenseId: number, teamName: string): Observable<any> {
    return this.http.get<boolean>(`${this.apiService.centralApiBaseUri}/api/Teams/${licenseId}/verifyTeamName?teamName=${encodeURIComponent(teamName)}`);
  }

  createTeam$(licenseId: number, team: FormData): Observable<Team> {
    return this.http.post<Team>(`${this.apiService.centralApiBaseUri}/api/Teams/${licenseId}/createTeam`, team);
  }

  updateTeamSettings$(team: Team, avatarFile?: File): Observable<Team> {
    const formData = new FormData();

    formData.append('Team', team ? JSON.stringify(team) : null);

    if (typeof avatarFile !== 'undefined') {
      formData.append('AvatarImage', avatarFile);
    }

    formData.append('IsAvatarSet', JSON.stringify(avatarFile !== null ? true : false));

    return this.http.put<any>(`${this.apiService.centralApiBaseUri}/api/teams/${team.Id}/update`, formData);
  }

  deleteTeams$(licenseId: number, teamIds: number[]): Observable<any> {
    return this.http.post<any>(`${this.apiService.centralApiBaseUri}/api/Teams/${licenseId}/bulkDeleteTeams`, teamIds);
  }

  updateTeamAvatar$(teamId: number, avatarImage: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiService.centralApiBaseUri}/api/Teams/${teamId}/Avatar`, avatarImage);
  }

  deleteTeamAvatar$(teamId: number): Observable<any> {
    return this.http.delete<any>(`${this.apiService.centralApiBaseUri}/api/Teams/${teamId}/Avatar`);
  }

  addUserToTeams$(userId: string, addToTeamsIds: number[]): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Teams/User/${userId}/addUserToTeams`, addToTeamsIds);
  }

  removeUserFromTeam$(userId: string, teamId: number): Observable<MadCloudResult> {
    return this.http.delete<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Teams/${teamId}/User/${userId}`);
  }

  removeSiteFromTeam$(siteId: number, teamId: number): Observable<MadCloudResult> {
    return this.http.delete<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Teams/${teamId}/Site/${siteId}`);
  }

  addLicenseUsersToTeam$(teamId: number, licenseUserIds: number[]): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Teams/${teamId}/BulkAddLicenseUsers`, licenseUserIds);
  }

  addProjectsToTeam$(teamId: number, projectIds: number[]): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Teams/${teamId}/BulkAddProjects`, projectIds);
  }

  addSitesToTeam$(teamId: number, siteIds: number[]): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Teams/${teamId}/BulkAddSites`, siteIds);
  }
}
