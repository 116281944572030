<form *ngIf="changeBillingForm; else loading" class="mc-spring-board" novalidate (ngSubmit)="saveBillingSettings(changeBillingForm.value)" [formGroup]="changeBillingForm" #formGroupDirective="ngForm">
  <mc-loader *ngIf="awaitingResponse" class="mc-loader-semi-transparent"></mc-loader>
  <div class="mc-spring-board-form-body">
    <h4 class="mc-form-title">Payment Method</h4>
    <div formGroupName="creditCard" class="credit-card-group">
      <mat-form-field color="secondary" class="full-width">
        <input matInput type="text" placeholder="Card Number" formControlName="cardNumber" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="creditCard.cardNumber" type="mc_credit_card_number"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="half-width mc-input-spacing-right">
        <input matInput type="text" placeholder="Expiration Date (mmyy)" formControlName="expirationDate" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="creditCard.expirationDate" type="mc_credit_card_expiration_date"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="half-width mc-input-spacing-left">
        <input matInput type="text" placeholder="Card Code" formControlName="cardCode" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="creditCard.cardCode" type="mc_credit_card_code"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
    </div>

    <h4 class="mc-form-title">Billing Information</h4>
    <div formGroupName="billing">
      <mat-form-field color="secondary" class="half-width mc-input-spacing-right">
        <input matInput type="text" placeholder="First Name" formControlName="firstName" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="billing.firstName" type="mc_user_first_name"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="half-width mc-input-spacing-left">
        <input matInput type="text" placeholder="Last Name" formControlName="lastName" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="billing.lastName" type="mc_user_last_name"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="full-width">
        <input matInput type="text" placeholder="Company" formControlName="companyName" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="billing.companyName" type="mc_billing_company"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="full-width">
        <input matInput type="text" placeholder="Address" formControlName="street" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="billing.street" type="mc_address"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="half-width mc-input-spacing-right">
        <input matInput type="text" placeholder="City" formControlName="city" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="billing.city" type="mc_city"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="half-width mc-input-spacing-left">
        <input matInput type="text" placeholder="Postal Code" formControlName="postalCode" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="billing.postalCode" type="mc_postal_code"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <div class="half-width mc-input-spacing-right mc-location-select-container">
        <mc-country-select #country controlName="billing.country" [countrySelect]="changeBillingForm.get('billing.country')" (countrySelected)="onCountrySelected(changeBillingForm.get('billing.region'), $event)" [isRequired]="true">
        </mc-country-select>
      </div>
      <div class="half-width mc-input-spacing-left mc-location-select-container">
        <mc-region-select *ngIf="country.countrySelect.value == 'US' || country.countrySelect.value == 'CA'" controlName="billing.region" [regionSelect]="changeBillingForm.get('billing.region')" [countryCode]="country.countrySelect.value" [isRequired]="true">
        </mc-region-select>
      </div>
      <mat-form-field color="secondary" class="half-width mc-input-spacing-right">
        <input matInput type="text" placeholder="Phone" formControlName="phone" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="billing.phone" type="mc_phone"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="half-width mc-input-spacing-left">
        <input matInput type="text" placeholder="Email" formControlName="email" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="billing.email" type="mc_email"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="mc-shipping-title-container">
      <h4 class="mc-form-title">Shipping Information</h4>
      <span class="mc-fill-remaining-flex-space"></span>
      <mat-checkbox color="accent-vivid" #SameAsBilling formControlName="useBillingAsShipping">Same as Billing Information</mat-checkbox>
    </div>

    <!-- Shipping Information -->
    <div formGroupName="shipping" *ngIf="!SameAsBilling.checked">
      <mat-form-field color="secondary" class="half-width mc-input-spacing-right">
        <input matInput type="text" placeholder="First Name" formControlName="firstName" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="shipping.firstName" type="mc_user_first_name"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="half-width mc-input-spacing-left">
        <input matInput type="text" placeholder="Last Name" formControlName="lastName" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="shipping.lastName" type="mc_user_last_name"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="full-width">
        <input matInput type="text" placeholder="Company" formControlName="companyName" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="shipping.companyName" type="mc_billing_company"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="full-width">
        <input matInput type="text" placeholder="Address" formControlName="street" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="shipping.street" type="mc_address"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="half-width mc-input-spacing-right">
        <input matInput type="text" placeholder="City" formControlName="city" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="shipping.city" type="mc_city"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="half-width mc-input-spacing-left">
        <input matInput type="text" placeholder="Postal Code" formControlName="postalCode" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="shipping.postalCode" type="mc_postal_code"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <div class="half-width mc-input-spacing-right mc-location-select-container">
        <mc-country-select #shippingCountry controlName="shipping.country" [countrySelect]="changeBillingForm.get('shipping.country')" (countrySelected)="onCountrySelected(changeBillingForm.get('shipping.region'), $event)" [isRequired]="true">
        </mc-country-select>
      </div>
      <div class="half-width mc-input-spacing-left mc-location-select-container">
        <mc-region-select *ngIf="shippingCountry.countrySelect.value == 'US' || shippingCountry.countrySelect.value == 'CA'" controlName="shipping.region" [regionSelect]="changeBillingForm.get('shipping.region')" [countryCode]="shippingCountry?.countrySelect?.value" [isRequired]="true">
        </mc-region-select>
      </div>
      <mat-form-field color="secondary" class="half-width mc-input-spacing-right">
        <input matInput type="text" placeholder="Phone" formControlName="phone" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="shipping.phone" type="mc_phone"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field color="secondary" class="half-width mc-input-spacing-left">
        <input matInput type="text" placeholder="Email" formControlName="email" required>
        <mat-error>
          <mc-form-control-error-message [form]="changeBillingForm" controlName="shipping.email" type="mc_email"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="mc-spring-board-footer">
    <button mat-stroked-button type="button" (click)="closeDialog.emit(true)">Cancel</button>
    <button mat-raised-button type="button" (click)="deleteBillingSettings(formGroupDirective)" color="warn" *ngIf="!createNewCard">Delete</button>
    <button mat-raised-button type="submit" color="primary" [disabled]="changeBillingForm.pristine || changeBillingForm.invalid">Save</button>
  </div>
</form>

<ng-template #loading><mc-loader></mc-loader></ng-template>