import { ESCAPE } from '@angular/cdk/keycodes';
import { CdkScrollable, FlexibleConnectedPositionStrategy, FlexibleConnectedPositionStrategyOrigin, Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, Portal, TemplatePortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, EmbeddedViewRef, EventEmitter, Input, NgZone, OnDestroy, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { LegacyCanColor as CanColor, LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { SubscriptionProperty } from '@common/util/subscription-property.decorator';
import { ElementOverlayService } from '@portal-core/ui/overlay/services/element.overlay.service';
import { PopupContentDirective } from '@portal-core/ui/popup/directives/popup-content/popup-content.directive';
import { PopupTriggerDirective } from '@portal-core/ui/popup/directives/popup-trigger/popup-trigger.directive';
import { PopupContentComponent } from '@portal-core/ui/popup/types/popup-content-component.type';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Subscription, filter, first, merge } from 'rxjs';

/**
 * PopupBase
 * A base class for popup components. Provides common functionality for creating and displaying a popup.
 * C: the popup content component
 */
@Directive()
@AutoUnsubscribe()
export abstract class PopupBase<C extends PopupContentComponent> implements OnDestroy, CanColor {
  /** Whether or not the popup has a backdrop. */
  @Input() backdrop: boolean = true;

  /** Color palette to use on the popup. */
  @Input()
  get color(): ThemePalette {
    return this._color;
  }
  set color(value: ThemePalette) {
    this._color = value;

    // Update the popup content component if it exists
    if (this.popupComponentRef && this.popupComponentRef.instance) {
      this.popupComponentRef.instance.color = this._color;
    }
  }
  _color: ThemePalette;

  /** Whether the popup should be disabled. */
  @Input() disabled: boolean = false;

  /** Emits when the popup has been closed. */
  // tslint:disable-next-line: no-output-rename
  @Output('closed') closedStream: EventEmitter<void> = new EventEmitter<void>();

  /** Emits when the popup has been opened. */
  // tslint:disable-next-line: no-output-rename
  @Output('opened') openedStream: EventEmitter<void> = new EventEmitter<void>();

  /** Emits after the popup has been opened and the DOM is stable. */
  @Output('afterOpen') afterOpen: EventEmitter<void> = new EventEmitter<void>();

  /** The subscription for listening to popup close events. */
  @SubscriptionProperty() private closePopupSubscription: Subscription;

  /** Default color to fall back to if no value is set. */
  defaultColor: ThemePalette = 'primary';

  /** The element that was focused before the popup was opened. */
  private focusedElementBeforeOpen: HTMLElement;

  /** Whether the popup is open. */
  opened: boolean = false;

  /** The reference to the popup content component instantiated in the popup. */
  protected popupComponentRef: ComponentRef<C>;

  /** The portal containing the popup content. */
  private popupContentPortal: Portal<ComponentRef<C> | EmbeddedViewRef<any>>;

  /** The reference to the overlay when popup is opened. */
  popupRef: OverlayRef;

  /** A reference to the trigger that bound to this popup. */
  private trigger: PopupTriggerDirective;

  constructor(protected document: Document, protected ngZone: NgZone, protected overlay: Overlay, protected elementOverlayService: ElementOverlayService, protected viewContainerRef: ViewContainerRef) { }

  ngOnDestroy() {
    this.close();

    this.focusedElementBeforeOpen = null;

    // Clean up the popup
    if (this.popupRef) {
      this.popupRef.dispose();
      this.popupComponentRef = null;
    }
  }

  /** Opens the popup. */
  open() {
    if (this.opened || this.disabled) {
      return;
    }

    // Grab the currently focused element so that focus can be restored to it after the popup is closed
    this.focusedElementBeforeOpen = this.document.activeElement as HTMLElement;

    if (!this.popupContentPortal) {
      const content = this.getPopupContent();
      if (content instanceof TemplateRef) {
        this.popupContentPortal = new TemplatePortal<C>(content, this.viewContainerRef);
      } else {
        this.popupContentPortal = new ComponentPortal<C>(content, this.viewContainerRef);
      }
    }

    if (!this.popupRef) {
      this.createPopup();
    }

    if (!this.popupRef.hasAttached()) {
      this.popupComponentRef = this.popupRef.attach(this.popupContentPortal);

      const lazyContent = this.getPopupLazyContent();
      if (lazyContent) {
        lazyContent.attach();
      }

      this.initPopup();

      // Update the position once the popup has rendered
      this.ngZone.onStable.asObservable().pipe(
        first()
      ).subscribe(() => {
        this.popupRef.updatePosition();
        this.afterOpen.emit();
      });
    }

    this.opened = true;
    this.openedStream.emit();
  }

  /** Closes the popup. */
  close() {
    if (!this.opened) {
      return;
    }

    this.popupComponentRef = null;

    if (this.popupRef && this.popupRef.hasAttached()) {
      this.popupRef.detach();
    }

    if (this.popupContentPortal && this.popupContentPortal.isAttached) {
      this.popupContentPortal.detach();
    }

    const lazyContent = this.getPopupLazyContent();
    if (lazyContent) {
      lazyContent.detach();
    }

    const completeClose = () => {
      // The `opened` property could've been reset already if we got two events in quick succession.
      if (this.opened) {
        this.opened = false;
        this.closedStream.emit();
        this.focusedElementBeforeOpen = null;
      }
    };

    if (this.getPopupShouldRestoreFocusOnClose() && this.focusedElementBeforeOpen && typeof this.focusedElementBeforeOpen.focus === 'function') {
      this.focusedElementBeforeOpen.focus();
    }
    // Because IE moves focus asynchronously, we can't count on it being restored before we've
    // marked the popup as closed. If the event fires out of sequence and the element that
    // we're refocusing opens the popup on focus, the user could be stuck with not being
    // able to close the popup at all. We work around it by making the logic, that marks
    // the popup as closed, async as well.
    setTimeout(completeClose);
  }

  /**
   * Registers a trigger with this popup.
   * @param trigger The trigger to register with this popup.
   */
  registerTrigger(trigger: PopupTriggerDirective) {
    if (this.trigger) {
      throw Error('A PopupBase can only be associated with a single trigger.');
    }

    this.trigger = trigger;
  }

  /** Causes the popup's position to be recalculated. */
  updatePosition() {
    this.popupRef?.updatePosition();
  }

  /** Causes the popup's size to be recalculated. */
  updateSize() {
    this.popupRef?.updateSize(this.getPopupSize());
  }

  /** Creates the popup. */
  private createPopup() {
    const { width, height } = this.getPopupSize();

    const overlayConfig = new OverlayConfig({
      positionStrategy: this.createPopupPositionStrategy(),
      hasBackdrop: this.getPopupHasBackdrop(),
      backdropClass: 'mat-overlay-transparent-backdrop',
      // direction: this._dir,
      panelClass: this.getPopupPanelClass(),
      width,
      height
    });

    if (this.getPopupOverlayScrollables()?.length > 0) {
      overlayConfig.scrollStrategy = this.overlay.scrollStrategies.reposition();
    }

    const overlayContainerElement = this.getPopupOverlayContainerElement();
    if (overlayContainerElement) {
      this.popupRef = this.elementOverlayService.create(overlayContainerElement, overlayConfig);
    } else {
      this.popupRef = this.overlay.create(overlayConfig);
    }

    // Set the aria role
    const role = this.getPopupAriaRole();
    if (role) {
      this.popupRef.overlayElement.setAttribute('role', role);
    }

    // Listen to events that should close the popup
    this.closePopupSubscription = merge(
      this.popupRef.backdropClick().pipe(
        filter(() => this.getPopupShouldAutoClose())
      ),
      this.popupRef.detachments(),
      this.popupRef.keydownEvents().pipe(
        filter(event => event.keyCode === ESCAPE || this.filterKeyDownForClosingThePopup(event))
      )
    ).subscribe(event => {
      if (event) {
        event.preventDefault();
      }

      this.close();
    });
  }

  /** Create the popup PositionStrategy. */
  private createPopupPositionStrategy(): PositionStrategy {
    let position: FlexibleConnectedPositionStrategy;
    const overlayContainerElement = this.getPopupOverlayContainerElement();

    if (overlayContainerElement) {
      position = this.elementOverlayService.position().flexibleConnectedTo(overlayContainerElement, this.getPopupOrigin());
    } else {
      position = this.overlay.position().flexibleConnectedTo(this.getPopupOrigin());
    }

    const scrollables = this.getPopupOverlayScrollables();
    if (scrollables?.length > 0) {
      position = position.withScrollableContainers(scrollables);
    }

    return position
      .withTransformOriginOn(this.getPopupTransformOrigin())
      .withFlexibleDimensions(true)
      .withPush(false)
      .withGrowAfterOpen(true)
      .withViewportMargin(8)
      .withLockedPosition()
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom'
        }
      ]);
  }

  /**
   * Calls and returns the result of the trigger's filterKeyDownForClosingThePopup method or false if that method is not defined.
   * Optionally override to handle keydown events.
   */
  protected filterKeyDownForClosingThePopup(event: KeyboardEvent): boolean {
    return this.trigger ? this.trigger.filterKeyDownForClosingThePopup(event) : false;
  }

  /** Optionally override to specify an aria role value to use on the popup. */
  protected getPopupAriaRole(): string {
    return null;
  }

  /** Optionally override to specify if the popup has a backdrop. Defaults to true. */
  protected getPopupHasBackdrop(): boolean {
    return this.backdrop;
  }

  /** Optionally override to specify lazy content for the popup to render only after the popup is opened. Defaults to true. */
  protected getPopupLazyContent(): PopupContentDirective {
    return null;
  }

  /**
   * Returns the origin for the popup. The popup is displayed adjacent to the origin.
   * Calls and returns the result of the trigger's getPopupOrigin method.
   */
  protected getPopupOrigin(): FlexibleConnectedPositionStrategyOrigin {
    return this.trigger?.getConnectedOverlayOrigin();
  }

  /**
   * Returns the overlay container element for the popup. The popup is inserted into the overlay container element.
   * Defaults to Material's viewport overlay container.
   */
  protected getPopupOverlayContainerElement(): HTMLElement {
    return this.trigger?.getOverlayContainerElement();
  }

  protected getPopupOverlayScrollables(): CdkScrollable[] {
    return this.trigger?.getOverlayScrollables();
  }

  /**
   * Calls and returns the result of the trigger's getPopupPanelClass method.
   * Optionally override to specify that the CSS Class on the popup's panel.
   */
  protected getPopupPanelClass(): string | string[] {
    return this.trigger?.getPopupPanelClass();
  }

  /**
   * Calls and returns the result of the trigger's getPopupShouldAutoClose method.
   * Optionally override whether the popup should auto close when the user clicks outside of the popup. Defaults to true.
   */
  protected getPopupShouldAutoClose(): boolean {
    return this.trigger?.getPopupShouldAutoClose() ?? true;
  }

  /**
   * Calls and returns the result of the trigger's getPopupShouldRestoreFocusOnClose method.
   * Optionally override whether the popup should restore focus to the element that had focus before the popup was open. Defaults to true.
   */
  protected getPopupShouldRestoreFocusOnClose(): boolean {
    return this.trigger?.getPopupShouldRestoreFocusOnClose() ?? true;
  }

  /** Returns a square popup size that is equal to the width of the trigger element. */
  protected getPopupSize(): { width: number, height: number } {
    return this.trigger?.getPopupSize();
  }

  /** Optionally override to specify that the transform origin should be set on an element in the popup. Returns a CSS selector. */
  protected getPopupTransformOrigin(): string {
    return null;
  }

  /**
   * Calls the initPopup method on the trigger so that it can run any initialization it may have.
   * Optionally override to initialize the popup after it has been created but before it has been rendered.
   */
  protected initPopup() {
    this.trigger?.initPopup(this);
  }

  /** Returns the component class or template for the popup content. */
  protected abstract getPopupContent(): ComponentType<C> | TemplateRef<any>;
}
