import { Injectable } from '@angular/core';
import { PubSubMessage } from '@portal-core/general/types/pub-sub-message.type';
import { Observable, Subject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PubSubService {
  private message$: Subject<PubSubMessage> = new Subject<PubSubMessage>();

  subscribe<T>(name: string): Observable<PubSubMessage<T>> {
    return this.message$.pipe(
      filter(message => message.name === name)
    );
  }

  publish(name: string, data: any) {
    this.message$.next({ name, data });
  }
}