import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Site } from '@portal-core/sites/models/site.model';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { SitePageFilterConfig } from '@portal-core/sites/util/site-page-filter-config';
import { SelectListBase } from '@portal-core/ui/list/util/select-list-base';
import { SelectionListControl } from '@portal-core/ui/list/util/selection-list-control';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { of } from 'rxjs';

@Component({
  selector: 'mc-sites-select-list',
  templateUrl: './sites-select-list.component.html',
  styleUrls: ['./sites-select-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: SelectListBase, useExisting: forwardRef(() => SitesSelectListComponent) }]
})
export class SitesSelectListComponent extends SelectListBase<Site> implements OnChanges {

  @Input() licenseId: number;

  filterConfig: SitePageFilterConfig = new SitePageFilterConfig(['Name', 'VanityUrl.Url', 'LicenseHostMap.MappedDomain']);

  listControl: SelectionListControl<Site> = new SelectionListControl<Site>(this.sitesService, filter => {
    if (typeof this.licenseId === 'number') {
      return this.sitesService.getSitesPageByLicense$(this.licenseId, filter);
    } else {
      return of(null);
    }
  }, site => {
    return site.Id;
  });

  constructor(private sitesService: SitesService, private pageFilterService: PageFilterService) {
    super(sitesService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.licenseId) {
      this.hardReload();
    }
  }
}
