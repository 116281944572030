import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

export class LicenseUsersPageFilterConfig extends PageFilterConfigBase {
  constructor(enabledColumnNames: string[]) {
    super(enabledColumnNames, [
      {
        name: 'User.FullName',
        title: 'Name',
        type: PageDataType.String,
        filterDataType: PageDataType.FullName,
        filterEnabled: true,
      },
      {
        name: 'SeatType',
        title: 'Seat Type',
        type: PageDataType.Select,
        filterEnabled: true,
        sortEnabled: true,
        selectOptions: [
          { id: LicenseUserSeatType.Author.toString(), text: 'Author' },
          { id: LicenseUserSeatType.SME.toString(), text: 'SME' },
          { id: LicenseUserSeatType.Viewer.toString(), text: 'Viewer' }
        ]
      },
      {
        name: 'User.Email',
        title: 'Email',
        type: PageDataType.String,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'Status',
        title: 'Status',
        type: PageDataType.Select,
        filterEnabled: true,
        sortEnabled: true,
        selectOptions: [
          { id: LicenseUserStatus.Deactivated.toString(), text: 'Deactivated' },
          { id: LicenseUserStatus.Active.toString(), text: 'Active' },
          { id: LicenseUserStatus.Invited.toString(), text: 'Invited' },
          { id: LicenseUserStatus.Paused.toString(), text: 'Paused' }
        ]
      },
      {
        name: 'User.OnlineStatus',
        title: 'Online',
        type: PageDataType.Select
      },
      {
        name: 'User.LastSignIn',
        title: 'Last Sign In',
        type: PageDataType.Date,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'User.LastSignOut',
        title: 'Last Sign Out',
        type: PageDataType.Date,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'User.Location',
        title: 'Location',
        type: PageDataType.String,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'User.PhoneNumbers.Primary',
        title: 'Phone',
        type: PageDataType.String
      },
      {
        name: 'User.PhoneNumbers.Cell',
        title: 'Mobile Phone',
        type: PageDataType.String
      },
      {
        name: 'User.Title',
        title: 'Title',
        type: PageDataType.String,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'User.Department',
        title: 'Department',
        type: PageDataType.String,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'User.SeconderyEmail',
        title: 'Secondary Email',
        type: PageDataType.String,
        filterEnabled: true,
        sortEnabled: true
      }
    ]);
  }
}
