<mc-sub-navbar></mc-sub-navbar>

<main class="page mc-page-fill">
  <router-outlet></router-outlet>

  <aside class="page-card page-gutter" *ngIf="(showPageGutter | async)?.matches">
    <router-outlet name="app-gutter"></router-outlet>
    <router-outlet name="page-gutter"></router-outlet>
  </aside>
</main>

<button mat-button class="app-logo" (click)="onLogoClicked()" *ngIf="(showAppNav | async)?.matches">
  <img alt="Flare Online Logo" class="mc-sub-page-fill-logo-image" src="assets/images/madcap-flare-online-logo.svg">
</button>
<router-outlet name="app-nav" *ngIf="(showAppNav | async)?.matches"></router-outlet>