<form class="mc-spring-board" [formGroup]="deliveryMethodForm" (ngSubmit)="onSubmit(deliveryMethodForm)">
  <div class="mc-spring-board-form-body">
    <h4 class="mc-form-title">Send notifications by</h4>
    <mat-checkbox class="mc-user-notifications-editor-notifications-by-checkbox" color="accent-vivid" formControlName="notificationCenterDelivery">Notification Center</mat-checkbox>
    <mat-checkbox class="mc-user-notifications-editor-notifications-by-checkbox" color="accent-vivid" formControlName="emailDelivery">Email</mat-checkbox>

    <h4 class="mc-form-title">Send notifications when</h4>
    <div class="mc-user-notifications-editor-columns">
      <mat-tree *ngFor="let column of auditCategoryColumns$ | async" class="mc-tree mc-tree-compact" [dataSource]="column.auditCategories" [treeControl]="column.treeControl">
        <mat-tree-node *matTreeNodeDef="let node" [matTooltip]="node.Description">
          <div class="mc-tree-node-item">
            <mat-checkbox color="accent-vivid" [checked]="auditCategorySelection.isSelected(node)" (change)="toggleNodeSelection(node)">{{ node.Title }}</mat-checkbox>
          </div>
        </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChildren">
          <div class="mat-tree-node">
            <div matTreeNodeToggle matRipple class="mc-tree-node-item">
              <mat-icon class="mc-tree-text-icon mc-tree-expander-icon" color="accent-vivid" fontSet="project-titan" [fontIcon]="column.treeControl.isExpanded(node) ? 'icon-down-arrow-90' : 'icon-right-arrow-90'"></mat-icon>
              <mat-checkbox color="accent-vivid" class="mc-tree-text-icon text-heavy-weight" mcStopPropagation="click" [checked]="descendantsAllSelected(node)" [disabled]="node.disabled" [indeterminate]="descendantsPartiallySelected(node)" (change)="toggleNodeSelection(node)">{{ node.Title }}</mat-checkbox>
            </div>
          </div>

          <div class="mc-nested-tree-node-items" [class.mc-hidden]="!column.treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
    </div>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button class="mc-dialog-card-action-button" (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button class="mc-dialog-card-action-button" color="primary" [disabled]="notificationsSelectionsUntouched && deliveryMethodForm.pristine">Save</button>
    </div>
  </div>

  <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>
  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>

  <div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
    <mc-errors-expansion-panel compact [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
  </div>
</form>