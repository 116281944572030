import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { TeamLicenseUsersListComponent } from '@portal-core/teams/components/team-license-users-list/team-license-users-list.component';
import { TeamProjectsListComponent } from '@portal-core/teams/components/team-projects-list/team-projects-list.component';
import { TeamSitesListComponent } from '@portal-core/teams/components/team-sites-list/team-sites-list.component';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { AutocompleteInputComponent } from '@portal-core/ui/autocomplete/components/autocomplete-input/autocomplete-input.component';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { PopupComponent } from '@portal-core/ui/popup/components/popup/popup.component';
import { PopupTriggerDirective } from '@portal-core/ui/popup/directives/popup-trigger/popup-trigger.directive';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, Subscription } from 'rxjs';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';

export enum TeamAccessFormTab {
  Users = 0,
  Projects,
  Sites
}

@Component({
  selector: 'mc-team-access-form',
  templateUrl: './team-access-form.component.html',
  styleUrls: ['./team-access-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamAccessFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() selectedTabIndex?: TeamAccessFormTab;
  @Input() team: Team;

  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(TeamLicenseUsersListComponent, { static: false }) teamLicenseUsersListComponent?: TeamLicenseUsersListComponent;
  @ViewChild('teamLicenseUsersAutoComplete', { static: false }) teamLicenseUsersAutocomplete: AutocompleteInputComponent;
  @ViewChild('popupTriggerUsers', { static: false, read: PopupTriggerDirective }) popupTriggerUsers: PopupTriggerDirective;
  @ViewChild('addTeamUsersPopup', { static: false }) addTeamUsersPopup: PopupComponent;
  @ViewChild('filterTeamUsers', { static: false }) filterTeamUsers: ElementRef;
  teamLicenseUsersFilterName: string = 'team-license-users';
  filterTeamUsersSubscription: Subscription;

  @ViewChild(TeamProjectsListComponent, { static: false }) teamProjectsListComponent?: TeamProjectsListComponent;
  @ViewChild('teamProjectsAutoComplete', { static: false }) teamProjectsAutocomplete: AutocompleteInputComponent;
  @ViewChild('popupTriggerProjects', { static: false, read: PopupTriggerDirective }) popupTriggerProjects: PopupTriggerDirective;
  @ViewChild('addTeamProjectsPopup', { static: false }) addTeamProjectsPopup: PopupComponent;
  @ViewChild('filterTeamProjects', { static: false }) filterTeamProjects: ElementRef;
  teamProjectsFilterName: string = 'team-projects';
  filterTeamProjectsSubscription: Subscription;

  @ViewChild(TeamSitesListComponent, { static: false }) teamSitesListComponent?: TeamSitesListComponent;
  @ViewChild('teamSitesAutoComplete', { static: false }) teamSitesAutocomplete: AutocompleteInputComponent;
  @ViewChild('popupTriggerSites', { static: false, read: PopupTriggerDirective }) popupTriggerSites: PopupTriggerDirective;
  @ViewChild('addTeamSitesPopup', { static: false }) addTeamSitesPopup: PopupComponent;
  @ViewChild('filterTeamSites', { static: false }) filterTeamSites: ElementRef;
  teamSitesFilterName: string = 'team-sites';
  filterTeamSitesSubscription: Subscription;

  loadingState: LoadingState<string> = new LoadingState<string>();
  savingState: LoadingState<string> = new LoadingState<string>();

  canEdit$: Observable<boolean>;

  projectStatuses: ProjectStatus[] = [ProjectStatus.Active, ProjectStatus.Locked];

  constructor(
    private errorService: ErrorService,
    private permissionsService: PermissionsService,
    private projectsService: ProjectsService,
    private sitesService: SitesService,
    private teamsService: TeamsService,
    private licenseUsersService: LicenseUsersService,
    private pageFilterService: PageFilterService
  ) { }

  ngOnInit() {
    this.canEdit$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageTeamsProjects);
  }

  ngAfterViewInit(): void {
    this.initFilterSubscriptions();

    // set license user filter to only ever show certain user statuses
    const licenseUserStatusFilterBuilder = this.pageFilterService.create({
      Id: `${this.teamLicenseUsersFilterName}-status`,
      Type: PageFilterGroupType.Custom
    }).select('Status', [LicenseUserStatus.Active, LicenseUserStatus.Invited, LicenseUserStatus.Paused]);
    this.licenseUsersService.setPagedDataListFilterByName$(this.teamLicenseUsersListComponent.dataListId, `${this.teamLicenseUsersFilterName}-status`, licenseUserStatusFilterBuilder.value);
    this.licenseUsersService.setPagedDataListFilterByName$(this.teamLicenseUsersAutocomplete.selectList.dataListId, `${this.teamLicenseUsersFilterName}-status`, licenseUserStatusFilterBuilder.value);

    // set default license user order
    this.licenseUsersService.setPagedDataListOrder$(this.teamLicenseUsersListComponent.dataListId, 'User.FullName', 'asc');
    this.licenseUsersService.setPagedDataListOrder$(this.teamLicenseUsersAutocomplete.selectList.dataListId, 'User.FullName', 'asc');

    // set default project list order
    this.projectsService.setPagedDataListOrder$(this.teamProjectsListComponent.dataListId, 'Name', 'asc');
    this.projectsService.setPagedDataListOrder$(this.teamProjectsAutocomplete.selectList.dataListId, 'Name', 'asc');

    // set default site list order
    this.sitesService.setPagedDataListOrder$(this.teamSitesListComponent.dataListId, 'Name', 'asc');
    this.sitesService.setPagedDataListOrder$(this.teamSitesAutocomplete.selectList.dataListId, 'Name', 'asc');
  }

  ngOnDestroy(): void {
    this.filterTeamUsersSubscription?.unsubscribe();
    this.filterTeamUsersSubscription = null;
    this.filterTeamProjectsSubscription?.unsubscribe();
    this.filterTeamProjectsSubscription = null;
    this.filterTeamSitesSubscription?.unsubscribe();
    this.filterTeamSitesSubscription = null;
  }

  onSaved() {
    this.saved.emit();
  }

  onTabChange(event: MatTabChangeEvent) {
    this.initFilterSubscriptions();

    switch (event.tab.textLabel)
    {
      case 'Users':
        this.teamLicenseUsersListComponent.checkViewportSize();
        break;
      case 'Projects':
        this.teamProjectsListComponent.checkViewportSize();
        break;
      case 'Sites':
        this.teamSitesListComponent.checkViewportSize();
        break;
    }
  }

  initFilterSubscriptions() {
    if (!this.filterTeamUsersSubscription) {
      this.filterTeamUsersSubscription = this.teamLicenseUsersListComponent?.getFilter$(this.teamLicenseUsersFilterName).subscribe(filter => {
        if (!filter) {
          this.filterTeamUsers.nativeElement.value = '';
        }
      });
    }
    if (!this.filterTeamProjectsSubscription) {
      this.filterTeamProjectsSubscription = this.teamProjectsListComponent?.getFilter$(this.teamProjectsFilterName).subscribe(filter => {
        if (!filter) {
          this.filterTeamProjects.nativeElement.value = '';
        }
      });
    }
    if (!this.filterTeamSitesSubscription) {
      this.filterTeamSitesSubscription = this.teamSitesListComponent?.getFilter$(this.teamSitesFilterName).subscribe(filter => {
        if (!filter) {
          this.filterTeamSites.nativeElement.value = '';
        }
      });
    }
  }

  onAddTeamUsersClicked() {
    if (this.teamLicenseUsersAutocomplete.value) {
      this.savingState.update(true);

      this.teamsService.addLicenseUsersToTeam$(this.team.Id, this.teamLicenseUsersAutocomplete.value as number[]).subscribe(() => {
        this.teamLicenseUsersListComponent.hardReload();
        this.teamLicenseUsersAutocomplete.close();
        this.popupTriggerUsers.close();
        this.savingState.update(false);
        this.saved.emit();
      }, error => {
        this.savingState.update(false, 'There was an error adding users to the team.', this.errorService.getErrorMessages(error));
      });
    }
  }
  onCancelTeamUsersClicked() {
    this.savingState.update(false);
    this.addTeamUsersPopup.close();
  }
  onTeamUsersPopupClosed() {
    this.teamLicenseUsersAutocomplete.value = null;
  }
  onFilterTeamUsersKeyUp(event: any) {
    const fb = this.pageFilterService.create({
      Id: this.teamLicenseUsersFilterName,
      Type: PageFilterGroupType.Search
    });
    fb.search(['User.Email', 'User.FullName'], event.target.value, [
      { PropertyName: 'User.Email', PropertyType: PageDataType.String },
      { PropertyName: 'User.FullName', PropertyType: PageDataType.String }
    ]);
    this.licenseUsersService.setPagedDataListFilterByName$(this.teamLicenseUsersListComponent.dataListId, this.teamLicenseUsersFilterName, fb.value);
  }

  onAddTeamProjectsClicked() {
    if (this.teamProjectsAutocomplete.value) {
      this.savingState.update(true);

      const projectIds = this.teamProjectsAutocomplete.value as number[];
      this.teamsService.addProjectsToTeam$(this.team.Id, projectIds).subscribe(() => {
        this.teamProjectsListComponent.hardReload();
        this.teamProjectsAutocomplete.close();
        this.popupTriggerProjects.close();
        this.savingState.update(false);
        this.saved.emit();
      }, error => {
        this.savingState.update(false, 'There was an error adding projects to the team.', this.errorService.getErrorMessages(error));
      });
    }
  }
  onCancelTeamProjectsClicked() {
    this.savingState.update(false);
    this.addTeamProjectsPopup.close();
  }
  onTeamProjectsPopupClosed() {
    this.teamProjectsAutocomplete.value = null;
  }
  onFilterTeamProjectsKeyUp(event: any) {
    const fb = this.pageFilterService.create({
      Id: this.teamProjectsFilterName,
      Type: PageFilterGroupType.Search
    });
    fb.search(['Name', 'Initials'], event.target.value, [
      { PropertyName: 'Name', PropertyType: PageDataType.String },
      { PropertyName: 'Initials', PropertyType: PageDataType.String }
    ]);
    this.projectsService.setPagedDataListFilterByName$(this.teamProjectsListComponent.dataListId, this.teamProjectsFilterName, fb.value);
  }

  onAddTeamSitesClicked() {
    if (this.teamSitesAutocomplete.value) {
      this.savingState.update(true);

      const siteIds = this.teamSitesAutocomplete.value as number[];
      this.teamsService.addSitesToTeam$(this.team.Id, siteIds).subscribe(() => {
        this.teamSitesListComponent.hardReload();
        this.teamSitesAutocomplete.close();
        this.popupTriggerSites.close();
        this.savingState.update(false);
        this.saved.emit();
      }, error => {
        this.savingState.update(false, 'There was an error adding sites to the team.', this.errorService.getErrorMessages(error));
      });
    }
  }
  onCancelTeamSitesClicked() {
    this.savingState.update(false);
    this.addTeamSitesPopup.close();
  }
  onTeamSitesPopupClosed() {
    this.teamSitesAutocomplete.value = null;
  }
  onFilterTeamSitesKeyUp(event: any) {
    const fb = this.pageFilterService.create({
      Id: this.teamSitesFilterName,
      Type: PageFilterGroupType.Search
    });
    fb.search(['Name', 'VanityUrl.Url', 'LicenseHostMap.MappedDomain'], event.target.value, [
      { PropertyName: 'Name', PropertyType: PageDataType.String },
      { PropertyName: 'VanityUrl.Url', PropertyType: PageDataType.String },
      { PropertyName: 'LicenseHostMap.MappedDomain', PropertyType: PageDataType.String }
    ]);
    this.sitesService.setPagedDataListFilterByName$(this.teamSitesListComponent.dataListId, this.teamSitesFilterName, fb.value);
  }
}
