import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { NoItemPlaceholderModule } from '@portal-core/general/components/no-item-placeholder/no-item-placeholder.module';
import { SelectionListHeaderModule } from '@portal-core/general/components/selection-list-header/selection-list-header.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { LicenseUsersSelectListModule } from '@portal-core/license-users/components/license-users-select-list/license-users-select-list.module';
import { LicenseUserAsyncModule } from '@portal-core/license-users/pipes/license-user-async/license-user-async.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ProjectsSelectListModule } from '@portal-core/projects/components/projects-select-list/projects-select-list.module';
import { ProjectAsyncModule } from '@portal-core/projects/pipes/project-async/project-async.module';
import { SiteAvatarModule } from '@portal-core/sites/components/site-avatar/site-avatar.module';
import { SitesSelectListModule } from '@portal-core/sites/components/sites-select-list/sites-select-list.module';
import { SiteAsyncModule } from '@portal-core/sites/pipes/site-async/site-async.module';
import { TeamLicenseUsersListModule } from '@portal-core/teams/components/team-license-users-list/team-license-users-list.module';
import { TeamProjectsListModule } from '@portal-core/teams/components/team-projects-list/team-projects-list.module';
import { TeamSitesListModule } from '@portal-core/teams/components/team-sites-list/team-sites-list.module';
import { AutocompleteInputModule } from '@portal-core/ui/autocomplete/components/autocomplete-input/autocomplete-input.module';
import { FilterPickerPopupModule } from '@portal-core/ui/filter-picker/components/filter-picker-popup/filter-picker-popup.module';
import { FilterPickerToggleModule } from '@portal-core/ui/filter-picker/components/filter-picker-toggle/filter-picker-toggle.module';
import { DropdownArrowModule } from '@portal-core/ui/forms/components/dropdown-arrow/dropdown-arrow.module';
import { ListModule } from '@portal-core/ui/list/list.module';
import { FilterBarModule } from '@portal-core/ui/page-filters/components/filter-bar/filter-bar.module';
import { PopupModule } from '@portal-core/ui/popup/popup.module';
import { TeamAccessFormComponent } from './team-access-form.component';



@NgModule({
  declarations: [TeamAccessFormComponent],
  exports: [TeamAccessFormComponent],
  imports: [
    AutocompleteInputModule,
    CommonModule,
    ErrorsExpansionPanelModule,
    FilterPickerPopupModule,
    FilterPickerToggleModule,
    FormsModule,
    LoaderModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatTabsModule,
    MatTooltipModule,
    NoItemPlaceholderModule,
    ProjectAvatarModule,
    SelectionListHeaderModule,
    SiteAvatarModule,
    ProjectAsyncModule,
    SiteAsyncModule,
    LicenseUserAsyncModule,
    LicenseUserAvatarModule,
    TeamLicenseUsersListModule,
    ProjectsSelectListModule,
    TeamProjectsListModule,
    TeamSitesListModule,
    SitesSelectListModule,
    LicenseUsersSelectListModule,
    ListModule,
    MatFormFieldModule,
    DropdownArrowModule,
    PopupModule,
    MatInputModule,
    FilterBarModule
  ]
})
export class TeamAccessFormModule { }
