import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Site } from '@portal-core/sites/models/site.model';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { SitePageFilterConfig } from '@portal-core/sites/util/site-page-filter-config';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { ListControl } from '@portal-core/ui/list/util/list-control';
import { ListBase } from '@portal-core/ui/list/util/list.base';
import { of } from 'rxjs';

@Component({
  selector: 'mc-team-sites-list',
  templateUrl: './team-sites-list.component.html',
  styleUrls: ['./team-sites-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamSitesListComponent extends ListBase<Site> implements OnChanges {
  @Input() teamId: number;
  @Input() canEdit: boolean = false;

  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  removing: number[] = [];

  filterConfig: SitePageFilterConfig = new SitePageFilterConfig(['Name', 'VanityUrl.Url', 'LicenseHostMap.MappedDomain']);

  listControl: ListControl<Site> = new ListControl<Site>(this.sitesService, filter => {
    if (typeof this.teamId === 'number') {
      return this.teamsService.getTeamSites$(this.teamId, filter);
    } else {
      return of(null);
    }
  });

  constructor(
    private teamsService: TeamsService,
    private sitesService: SitesService,
    private snackBar: MatSnackBar,
  ) {
    super(sitesService);

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.teamId) {
      this.hardReload();
    }
  }

  onRemoveSite(siteId: number) {
    this.removing = [
      ...this.removing,
      siteId
    ];
    this.teamsService.removeSiteFromTeam$(siteId, this.teamId).subscribe(() => {
      this.sitesService.removePagedDataListItems$(this.dataListId, [siteId]);
      this.removing = this.removing.filter(id => id !== siteId);
      this.saved.emit();
    }, error => {
      this.removing = this.removing.filter(id => id !== siteId);
      this.snackBar.open('Unable to remove the site from the team.', 'OK');
    });
  }
}
