<mc-flare-file-text-editor-toolbar *ngIf="editable" [getEditorState]="getEditorState" [featureSetVersion]="featureSetVersion" [fileId]="fileId" [showAI]="showAI" [showTrackedChanges]="showTrackedChanges" [licenseUser]="licenseUser" [commitId]="commitId" [projectId]="projectId" [schema]="schema" [branchName]="branchName" [fileType]="fileType" (trackedChangesToggle)="onTrackedChangesToggle()" [projectLanguage]="projectLanguage" [filePath]="filePath" (dispatch)="onDispatch($event)" (dropdownMenuClosed)="onToolbarDropdownMenuClosed($event)" (insertingAnnotation)="onInsertingAnnotation($event)" (insertingImage)="onInsertingImage($event, true)" (insertingSnippet)="onInsertProjectSnippetDialog($event)" (showConditionsToggle)="onShowConditionsToggle()"></mc-flare-file-text-editor-toolbar>
<mc-flare-file-text-editor-tagbar [hidden]="!showTagbar" [schema]="schema" (dispatch)="onDispatch($event)"></mc-flare-file-text-editor-tagbar>

<div cdkScrollable class="mc-flare-text-editor-scroll-container" #editorScrollContainer>
  <div class="mc-flare-text-editor-container" [ngClass]="showGutter ? 'mc-flare-text-editor-show-gutter' : ''">
    <mc-collab-file-text-editor *ngIf="fileType === CollabFileType.Review" [branchName]="branchName" [commitId]="commitId" [fileId]="fileId" [filePath]="filePath" [fileType]="fileType" [licenseUser]="licenseUser" [nodeViews]="nodeViews$ | async" [plugins]="plugins$ | async" [projectId]="projectId" [projectLanguage]="projectLanguage" [schema]="schema" (collabSessionEnd)="onCollabSessionEnd($event)" (collabSessionStart)="onCollabSessionStart($event)" (editorContextMenu)="onEditorContextMenu($event)" (editorStateChange)="onEditorStateChanged($event)" (errorChange)="onErrorChanged($event)" (exceedsMaxSizeChange)="onExceedsMaxSizeChanged($event)" (exceedsMongoDBMaxSizeChange)="onExceedsMongoDBMaxSizeChanged($event)" (load)="onCollabSessionLoaded()" (offlineChange)="onOfflineChanged($event)" (readonlyChange)="onReadOnlyChanged($event)" (sizeChange)="onSizeChanged($event)" (syncStateChange)="onSyncStateChanged($event)" (change)="change.emit($event)"></mc-collab-file-text-editor>
    <mc-solo-file-text-editor *ngIf="fileType === CollabFileType.Edit" [content]="content" [language]="projectLanguage" [nodeViews]="nodeViews$ | async" [plugins]="plugins$ | async" [readonly]="readonly" [schema]="schema" (editorContextMenu)="onEditorContextMenu($event)" (editorStateChange)="onEditorStateChanged($event)" (load)="onSoloEditorLoaded($event)" (change)="change.emit($event)"></mc-solo-file-text-editor>

    <ng-container *ngIf="gutterItems$ | async as gutterItems">
      <ng-container *ngIf="gutterItems.length > 0">
        <mat-divider class="mc-flare-text-editor-gutter-divider" [vertical]="true"></mat-divider>
        <mc-flare-file-text-editor-gutter [items]="gutterItems" [licenseUser]="licenseUser" [readonly]="!editable" [selectedItemId]="selectedGutterItemId" [targetsContainer]="editorScrollContainer" (annotationCommentChange)="onAnnotationCommentChanged($event)" (itemHover)="onGutterItemHovered($event)" (itemSelect)="onGutterItemSelected($event)"></mc-flare-file-text-editor-gutter>
      </ng-container>
    </ng-container>
  </div>
</div>

<mc-collab-file-text-editor-status-bar *ngIf="fileType === CollabFileType.Review" [error]="collabSessionError" [exceedsMaxSize]="docExceedsMaxSize" [exceedsMongoDBMaxSize]="docExceedsMongoDBMaxSize" [licenseUser]="licenseUser" [offline]="offline" [readonly]="serverReadonly" [size]="docSize" (createCollabFile)="onCreateCollabFile()" (revertDocToSavedState)="onRevertDocToSavedState()"></mc-collab-file-text-editor-status-bar>
<mc-solo-file-text-editor-status-bar *ngIf="fileType === CollabFileType.Edit" [exceedsMaxSize]="docExceedsMaxSize" [size]="docSize"></mc-solo-file-text-editor-status-bar>

<div #editorMenuTrigger [matMenuTriggerFor]="editorMenu" [style.position]="'fixed'" [style.left]="editorMenuPosition.x" [style.top]="editorMenuPosition.y"></div>
<mat-menu #editorMenu="matMenu" class="mc-menu" [overlapTrigger]="false" (closed)="onEditorContextMenuClosed()">
  <ng-template matMenuContent let-menu="menu">
    <button mat-menu-item (click)="onUndoClicked()" [disabled]="menu.undo.disabled">Undo</button>
    <button mat-menu-item (click)="onRedoClicked()" [disabled]="menu.redo.disabled">Redo</button>

    <mat-divider></mat-divider>

    <button mat-menu-item (click)="onCutClicked()">Cut</button>
    <button mat-menu-item (click)="onCopyClicked()">Copy</button>
    <button mat-menu-item (click)="onPasteClicked()">Paste</button>
    <button mat-menu-item (click)="onDeleteClicked()" [disabled]="menu.delete.disabled">Delete</button>
    <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>

    <ng-container *ngIf="!menu.editTable.disabled">
      <mat-divider></mat-divider>

      <button mat-menu-item (click)="onInsertRowAboveClicked()">Insert Row Above</button>
      <button mat-menu-item (click)="onInsertRowBelowClicked()">Insert Row Below</button>
      <button mat-menu-item (click)="onInsertColumnLeftClicked()">Insert Column to the Left</button>
      <button mat-menu-item (click)="onInsertColumnRightClicked()">Insert Column to the Right</button>

      <mat-divider></mat-divider>

      <button mat-menu-item (click)="onDeleteColumnClicked()">Delete Column</button>
      <button mat-menu-item (click)="onDeleteRowClicked()">Delete Row</button>
      <button mat-menu-item (click)="onDeleteTableClicked()">Delete Table</button>
    </ng-container>

    <!-- Show the snippet commands if a snippet is selected and the snippet exists in the review package when in reviews or the project when in project files -->
    <ng-container *ngIf="!menu.editSnippet.disabled">
      <mat-divider></mat-divider>
      <button mat-menu-item [disabled]="fileType === CollabFileType.Review && !menu.editSnippet.reviewFileId" (click)="onOpenEditSnippetDialog(menu.editSnippet.snippetSrc)">Edit Snippet</button>
      <button mat-menu-item [disabled]="fileType === CollabFileType.Review && !menu.editSnippet.reviewFileId" (click)="navToFile.emit(menu.editSnippet.snippetSrc)">Open Snippet File</button>
    </ng-container>

    <!-- Show the edit proxy command if a proxy is selected -->
    <ng-container *ngIf="!menu.editProxy.disabled">
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="onOpenEditProxyDialog(menu.editProxy.editProxyCommand)">Edit Proxy</button>
    </ng-container>
  </ng-template>
</mat-menu>