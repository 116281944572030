import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mc-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  /** The color of the loader's background. */
  @Input() backgroundColor?: 'body' | 'component' = 'component';
  /** The theme color of the loader. */
  @Input() color?: string = 'accent-vivid';
  /** The diameter of the spinning circle in the loader. */
  @Input() diameter?: number = 100;

  @HostBinding('class.mc-loader') loaderClass: boolean = true;
  @HostBinding('class')
  get _backgroundColorClass(): string {
    return `mc-loader-${this.backgroundColor ?? 'component'}`;
  }
}
