import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPickerPopupContentModule } from '@portal-core/ui/filter-picker/components/filter-picker-popup-content/filter-picker-popup-content.module';
import { FilterPickerPopupComponent } from './filter-picker-popup.component';

@NgModule({
  declarations: [
    FilterPickerPopupComponent
  ],
  imports: [
    CommonModule,
    FilterPickerPopupContentModule
  ],
  exports: [
    FilterPickerPopupComponent
  ]
})
export class FilterPickerPopupModule { }
